import React from 'react'
import { theme } from '@provi/provi-components'
import { EditIcon, CopyIcon, DeleteIcon, ShareIcon } from '~/assets/svgs/v2'
import { BaseTypography } from '~/globalStyles'
import { TextContainer, ActionsContainer, Container } from './styles'
import { formatDateToDDMM } from '~/screens/courses/helpers'

const temporaryDisable = false

const RenderClassroomReleaseInformation = ({ classroomDetails, isMobile }) => {
  const { CourseClassType, endsAt, startsAt, durationTime, CourseClassDuration } = classroomDetails

  const textFonts = isMobile ? '13px' : '16px'
  const lineHeights = isMobile ? '20px' : '24px'

  if (CourseClassType?.id === 1) {
    return (
      <>
        <BaseTypography fontSize={textFonts} lineHeight={lineHeights} color={theme.colors.bluePrimary}>
          {startsAt && endsAt ? `Aberta de ${formatDateToDDMM(startsAt)} a ${formatDateToDDMM(endsAt)}` : '---'}
        </BaseTypography>
      </>
    )
  }

  if (CourseClassType?.id === 2) {
    const stringTernary =
      CourseClassDuration.id === 5
        ? 'Acesso vitalício'
        : `Conteúdo disponível por: ${durationTime} ${CourseClassDuration?.name}`

    return (
      <>
        <BaseTypography fontSize={textFonts} lineHeight={lineHeights} color={theme.colors.bluePrimary}>
          Início imediato após a compra
        </BaseTypography>

        <BaseTypography fontSize={textFonts} lineHeight={lineHeights} color={theme.colors.bluePrimary}>
          {stringTernary}
        </BaseTypography>
      </>
    )
  }

  return null
}

export const TitleAndActions = ({ isMobile, classroomDetails, actions }) => {
  return (
    <Container>
      <TextContainer>
        <BaseTypography
          fontSize={isMobile ? '19px' : '25px'}
          lineHeight={isMobile ? '28px' : '32px'}
          color={theme.colors.bluePrimary}
        >
          {classroomDetails?.content?.name || '---'}
        </BaseTypography>
        <RenderClassroomReleaseInformation classroomDetails={classroomDetails?.content} isMobile={isMobile} />
      </TextContainer>
      <ActionsContainer>
        {temporaryDisable && (
          <>
            <EditIcon {...(actions && { onClick: actions.edit })} />
          </>
        )}
        <CopyIcon color={theme.colors.bluePrimary} {...(actions && { onClick: actions.copy })} />
        <DeleteIcon {...(actions && { onClick: actions.delete })} />
        <ShareIcon color={theme.colors.bluePrimary} {...(actions && { onClick: actions.share })} />
      </ActionsContainer>
    </Container>
  )
}

import { toast } from 'react-toastify'

const typeMessageObj = {
  link: 'Link copiado com sucesso!',
  linkAndText: 'Texto copiado com sucesso!',
}

const copyString = (text, typeMessage, closeModal) => {
  navigator.clipboard
    .writeText(text)
    .then(function () {
      closeModal()
      toast(typeMessageObj[typeMessage])
    })
    .catch(function (err) {
      closeModal()
      toast('Houve um erro na hora de copiar.')
    })
}

export { copyString }

import React from 'react'
import { useParams } from 'react-router-dom'
import { useDetails } from './hooks'
import { TableV2 } from '~/components/v2/TableV2'
import { Cancellation, Chargeback, DetailsCard, DetailsMobileRows } from './components'
import { PageWrapper, Container, HeaderContainer, Headers, DetailsGrid, TableWrapper } from './styles'

export const Details = () => {
  const { id: Crid } = useParams()
  const {
    isMobile,
    drawerWidth,
    cridDetails,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    goToPage,
    getDetails,
  } = useDetails(Crid)

  return (
    <PageWrapper isMobile={isMobile} drawerWidth={drawerWidth}>
      <Container>
        <HeaderContainer>
          <Headers
            hasCursor
            onClick={() => goToPage('checkout/controle-recebimentos')}
            hasUnderline
            fontSize={isMobile ? '12px' : '16px'}
            fontWeight={isMobile ? 700 : 600}
          >
            Voltar
          </Headers>
          <Headers fontSize={'16px'} fontWeight={600}>
            Solicitação
          </Headers>
          <Headers fontSize={'24px'} fontWeight={700} isRed>
            {Crid}
          </Headers>
        </HeaderContainer>

        <DetailsGrid>
          {!!cridDetails?.chargeBack?.data?.length && <Chargeback data={cridDetails.chargeBack.data} getDetails={getDetails} />}
          {/* {!!cridDetails?.cancellation?.data?.length && (
            <Cancellation data={cridDetails.cancellation.data} getDetails={getDetails} />
          )} */}
          <DetailsCard cridDetails={cridDetails} />
        </DetailsGrid>

        {!!rows.length && (
          <TableWrapper>
            <TableV2
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
              isMobile={isMobile}
              visibleColumns={visibleColumns}
              SubComponent={DetailsMobileRows}
            />
          </TableWrapper>
        )}
      </Container>
    </PageWrapper>
  )
}

import React from 'react'
import { SelectorV3 } from '@provi/provi-components'
import { ClassroomCard } from '~/screens/courses/components'
import { BaseTypography } from '~/globalStyles'
import { SelectContainer } from './styles'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Keyboard } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import { ActionsContainerComponent } from './components/ActionsContainerComponent'
import { BlueBoxWithNoData } from './components/BlueBoxWithNoData'
import { useSwiperSectionHooks } from './hooks'

const renderSlideOrBlueBox = (courseClassesFiltered, handleSelectClassroom, isMobile) =>
  courseClassesFiltered.length ? (
    courseClassesFiltered.map((classroom, index) => (
      <SwiperSlide
        style={{
          cursor: 'pointer',
        }}
        onClick={() => handleSelectClassroom(classroom.id)}
        key={index}
      >
        <ClassroomCard key={index + 1} classRoomObj={classroom} />
      </SwiperSlide>
    ))
  ) : (
    <BlueBoxWithNoData isMobile={isMobile} isFiltered={false} />
  )

export const SwiperSection = ({ courseKey, courseClassesData = [], isMobile }) => {
  const {
    goToPage,
    courseClassesFiltered,
    optionsSelectable,
    setSelectedOption,
    swiperRef,
    handleSelectClassroom,
    setCourseClassCopy,
  } = useSwiperSectionHooks({ courseKey, courseClassesData })

  return !courseClassesData.length ? (
    <>
      <BlueBoxWithNoData isMobile={isMobile} isFiltered={true} />
      <ActionsContainerComponent
        courseKey={courseKey}
        goToPageProp={goToPage}
        isMobileProp={isMobile}
        setCourseClassCopy={setCourseClassCopy}
      />
    </>
  ) : (
    <>
      <SelectContainer>
        <BaseTypography fontSize={isMobile ? '13px' : '16px'} lineHeight={isMobile ? '20px' : '24px'} fontWeight={600}>
          {courseClassesFiltered.length} Turmas associadas:
        </BaseTypography>
        <SelectorV3
          width={isMobile ? '100%' : '350px'}
          isClearable
          options={optionsSelectable}
          onChange={(option) => {
            return !option
              ? setSelectedOption(optionsSelectable[0])
              : setSelectedOption({ label: option.label, value: option.value })
          }}
          className="classroomSelect"
          classNamePrefix="classroomSelect"
          placeholder={'Ver todas'}
          isLoading={false}
          loadingMessage={() => 'Carregando opções'}
        />
      </SelectContainer>
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <Swiper ref={swiperRef} modules={[Keyboard]} slidesPerView={isMobile ? 1.5 : 3.5} spaceBetween={16} keyboard>
          {renderSlideOrBlueBox(courseClassesFiltered, handleSelectClassroom, isMobile)}
        </Swiper>
      </div>
      <ActionsContainerComponent
        courseKey={courseKey}
        goToPageProp={goToPage}
        isMobileProp={isMobile}
        hasArrows
        swiperRefProp={swiperRef}
        setCourseClassCopy={setCourseClassCopy}
      />
    </>
  )
}

import { useEffect, useState } from 'react'
import { Big } from 'big.js'
import { showToast } from '@provi/provi-components'

import { postSimulation, getPartnerDetails } from '~/services/api'
import { formatValue } from '~/utils'

import { validateCalculator } from './validateCalculator'

export const useCalcModal = (calcConfig, setIsLoading, handleModal) => {
  const [calcData, setCalcData] = useState()

  useEffect(() => {
    const getUpFront = async (value) => {
      const { data } = await getPartnerDetails()

      const { defaultAbsoluteUpfrontValue, defaultPercentualUpfrontValue } = data

      let upfrontAmount = 0
      if (defaultAbsoluteUpfrontValue) {
        upfrontAmount = Big(defaultAbsoluteUpfrontValue).times(100)
      } else if (defaultPercentualUpfrontValue) {
        upfrontAmount = Big(value).times(Big(defaultPercentualUpfrontValue).div(100))
      }

      return Number(upfrontAmount)
    }

    const simulation = async () => {
      try {
        setIsLoading(true)
        if (!calcConfig) {
          throw new Error('Ocorreu um erro inesperado ao mostrar a calculadora, contate a Provi')
        }

        let request = {}
        const { creditRequestId, amountInCents, discountInCents, upfrontInCents, coursesIds } = calcConfig

        if (creditRequestId) {
          request.creditRequestId = creditRequestId
        } else {
          validateCalculator({ amountInCents, discountInCents, coursesIds })
          request = { ...calcConfig }

          if (!upfrontInCents && upfrontInCents !== 0) {
            const value = amountInCents - discountInCents
            request.upfrontInCents = await getUpFront(value)
          }
        }
        const { data: simulationData } = await postSimulation(request)
        const { values, options } = simulationData

        if (values && options) {
          const text = `Valor da venda: ${formatValue(values.finalTotal, true)}\n\n${
            values.upfrontAmount !== 0
              ? `Entrada*: ${formatValue(values.upfrontAmount, true)}\nParcelado: ${formatValue(
                  values.installmentAmount,
                  true,
                )}\n\n`
              : ''
          }`

          const textOptions = []
          options.map((opt, idx) => {
            return textOptions.push(
              `Opção ${idx + 1}: ${values.hasUpfrontPayment ? 'Entrada +' : ''} ${opt.installmentsToApply}x de ${formatValue(
                opt.pricePerInstallment,
                true,
              )} (${opt.monthlyInterest === 0 ? 'sem juros' : `${opt.monthlyInterest}%`})\n`,
            )
          })

          setCalcData({ options, values, text: text + textOptions.join('') })
        }
      } catch (error) {
        handleModal(false)
        showToast(error.message || 'Ocorreu um erro ao mostrar a calculadora, tente novamente ou contate a Provi')
      } finally {
        setIsLoading(false)
      }
    }

    simulation()
  }, [])

  const handleCopy = () => {
    showToast('Texto copiado')
  }

  return [handleCopy, calcData]
}

import { useState, useCallback, useMemo } from 'react'
import { isFunction } from 'lodash'
import { handleToogleActiveFilters } from './helpers/handleToogleActiveFilters'
import { useDebounce } from '~/hooks'

export const useTable = (setShowCells, setFilterStatus, setPage, showCells, handleModal) => {
  const [optionActive, setOptionActive] = useState('')
  const [clicked, setClicked] = useState('admin')
  const [selectOpen, setSelectOpen] = useState(false)
  const [filterSearch, setFilterSearch] = useState('')
  const [startDatePicker, setStartDatePicker] = useState(new Date())
  const [endDatePicker, setEndDatePicker] = useState(new Date())
  const debouncedFilterSearch = useDebounce(filterSearch, 500)

  const handleOptionTable = (option) => {
    if (optionActive && optionActive === option) {
      setOptionActive('')
    } else {
      setOptionActive(option)
    }
  }
  const handleOptionTableMobile = (option) => {
    setClicked(option)
  }

  const handleSelect = useCallback(
    (event, cell) => {
      let showBack = true
      if (event && isFunction(event.stopPropagation)) event.stopPropagation()

      const newShowCells = showCells.map((showCell) => {
        if (showCell.item === cell.item) {
          showCell.isChanged = true
          if (showCell.showSelect) {
            showBack = false
          }
          showCell.showSelect = !cell.showSelect
        } else {
          showCell.showSelect = false
        }
        return showCell
      })

      setShowCells(newShowCells)
      setSelectOpen(showBack)
      setFilterSearch('')
    },
    [showCells, selectOpen],
  )

  const closeSelects = useCallback(() => {
    const newCells =
      showCells &&
      showCells.length > 0 &&
      showCells.map((cell) => {
        cell.showSelect = false
        return cell
      })
    setSelectOpen(false)
    setShowCells(newCells)
    setFilterSearch('')
  }, [showCells, selectOpen])

  const handleClick = (item, cell) => {
    handleToogleActiveFilters(setShowCells, { item, showCells, cell })
  }

  const submitFilter = (cell) => {
    if (!showCells && showCells.length > 0) return

    const selectedValues = {}

    showCells.map((cellName) => {
      if (cellName.selectValues) {
        const values = cellName.selectValues.filter((items) => items.active).map((item) => item.id)
        if (cellName.selectValues.length !== values.length || cellName.isChanged) selectedValues[cellName.item] = values
      }
      if ((cellName.isDate && cellName.isChanged) || (cellName.item === cell.item && cell.isDate)) {
        selectedValues.hasFormatDates = true

        if (cellName.blankDatePermitted && cellName.isBlankDate) {
          selectedValues[cellName.item] = { isBlankDate: cellName.isBlankDate }
        } else {
          selectedValues[cellName.item] = { startDate: cellName.startDate, endDate: cellName.endDate }
        }
      }
      return cellName
    })

    if (selectedValues && selectedValues.origin) {
      if (selectedValues.origin.includes('SDK')) {
        selectedValues.origin = [...selectedValues.origin, 'TYPEFORM', 'TYPEFORM_ISA']
      }

      if (selectedValues.origin.includes('API_V2')) {
        selectedValues.origin = [...selectedValues.origin, 'API_V1']
      }
    }

    setFilterStatus(selectedValues)
    setPage(1)
    closeSelects(showCells)
  }

  const handleClearFilters = (option) => {
    const newCells = showCells.map((cell) => {
      if (cell.isDate && cell.item === option.item) {
        cell.startDate = null
        cell.endDate = null
        cell.isChanged = false

        setStartDatePicker(new Date())
        setEndDatePicker(new Date())

        if (cell.blankDatePermitted) cell.isBlankDate = false

        return cell
      }

      if (cell.item === option.item) {
        return {
          ...cell,
          isChanged: false,
          selectValues: cell.selectValues.map((value) => {
            return {
              ...value,
              active: false,
            }
          }),
        }
      }

      return cell
    })

    setShowCells(newCells)
  }

  const handleSelectAll = useCallback(
    (option) => {
      setFilterSearch('')
      const newCells = showCells.map((cell) => {
        if (cell.item === option.item) {
          return {
            ...cell,
            isChanged: true,
            selectValues: cell.selectValues.map((value) => {
              return {
                ...value,
                active: true,
              }
            }),
          }
        }

        return cell
      })

      setShowCells(newCells)
    },
    [showCells],
  )

  const setRangeDates = useCallback(
    (ranges, option) => {
      const start = ranges?.selection.startDate
      const end = ranges?.selection.endDate
      setStartDatePicker(start)
      setEndDatePicker(end)
      const newCells = showCells.map((cell) => {
        if (cell.item === option.item && option.isDate) {
          cell.startDate = start
          cell.endDate = end
          cell.isChanged = true
        }

        return cell
      })

      setShowCells(newCells)
    },
    [showCells, setShowCells, startDatePicker, endDatePicker],
  )

  const handleBlankDate = useCallback(
    (option) => {
      const newCells = showCells.map((cell) => {
        if (cell.item === option.item) {
          cell.startDate = new Date()
          cell.endDate = new Date()
          cell.isBlankDate = !option.isBlankDate
        }
        return cell
      })

      setShowCells(newCells)
    },
    [showCells],
  )

  const defaultTrigger = (triggers) => triggers.filter((x) => x.selected == true)
  const triggerFormat = (triggers) => {
    return triggers.map(({ id, label, isDefault }) => ({
      value: id,
      label,
      isDefault,
    }))
  }

  const handleQuestionMark = useCallback((event, cell) => {
    if (event && isFunction(event.stopPropagation)) event.stopPropagation()
    handleModal('helper', cell.item)
  }, [])

  const filteredShowCells = useMemo(() => {
    if (!debouncedFilterSearch || !filterSearch) return showCells

    return showCells.map((cell) => {
      if ('selectValues' in cell) {
        return {
          ...cell,
          selectValues: cell.selectValues.filter((item) =>
            item?.label?.toString?.().toLowerCase?.().includes?.(debouncedFilterSearch.toLowerCase()),
          ),
        }
      }
      return cell
    })
  }, [showCells, debouncedFilterSearch, filterSearch])

  return {
    clicked,
    handleOptionTableMobile,
    handleOptionTable,
    optionActive,
    handleSelect,
    handleClick,
    selectOpen,
    closeSelects,
    submitFilter,
    handleClearFilters,
    handleSelectAll,
    startDatePicker,
    endDatePicker,
    setRangeDates,
    handleBlankDate,
    handleQuestionMark,
    defaultTrigger,
    triggerFormat,
    filterSearch,
    setFilterSearch,
    filteredShowCells,
  }
}

import React, { useContext, useRef } from 'react'
import { Button, TextLink, theme } from '@provi/provi-components'
import CloseIcon from '@material-ui/icons/Close'
import 'react-tabs/style/react-tabs.css'
import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'
import { Title } from '~/components/title'
import { Text } from '~/themes/styles'
import { useProduct } from './hooks'
import { usePermissions } from '~/hooks'

import {
  Container,
  Content,
  SectionHeader,
  WrapperText,
  WrapperValue,
  SectionValue,
  WrapperButton,
  WrapperTextLink,
  Selector,
  CloseButton,
  WrapperClose,
  FlexWrapper,
} from './styles'

export const CampaignProductsV2 = () => {
  const { setIsLoading, isMobile, setSelectedOptionsCampaign, setValueCampaign, goToPage } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)
  const selectInputCourseClassRef = useRef()

  const { canCreateNewCrids } = usePermissions()

  const { handleOption, handleSubmit, courseOptions, list, setList, isValidSubmit, handleRemoveItem } = useProduct(
    setIsLoading,
    setSelectedOptionsCampaign,
    setValueCampaign,
    goToPage,
  )

  return (
    <>
      <Container>
        <Content isMobile={isMobile} drawerWidth={drawerWidth}>
          <SectionHeader>
            <WrapperText>
              <Title text="Selecione os itens que irão compor a campanha" />
            </WrapperText>
          </SectionHeader>
          {list &&
            list.map((item, index) => {
              return (
                <SectionValue key={index}>
                  <WrapperValue>
                    <Text tag="Course" align="start" size="16px" bold mBottom="24px" color={'#000B3C'}>
                      Selecione o curso
                    </Text>
                    <Selector
                      name="courses"
                      placeholder={isMobile ? 'Selecionar o curso' : 'Selecionar o curso desejado'}
                      options={courseOptions}
                      value={item.course || null}
                      onChange={(value) => {
                        handleOption(value, index, 'course')
                      }}
                      isOptionDisabled={(option) => list.some((key) => key && key.course && key.course.id === option.id)}
                    />
                  </WrapperValue>
                  <FlexWrapper>
                    <WrapperValue>
                      <Text tag="Course" align="start" size="16px" bold mBottom="24px" color={'#000B3C'}>
                        Selecione a turma que será vendida
                      </Text>
                      <Selector
                        ref={selectInputCourseClassRef}
                        name="course-class"
                        placeholder={isMobile ? 'Selecionar a turma' : 'Selecionar o turma desejada'}
                        isDisabled={item.courseClassOptions === undefined}
                        value={item.courseClass || null}
                        options={item.courseClassOptions && item.courseClassOptions.length ? item.courseClassOptions : []}
                        onChange={(value) => {
                          handleOption(value, index, 'courseClass')
                        }}
                      />
                    </WrapperValue>
                    {list.length > 1 && (
                      <CloseButton onClick={() => handleRemoveItem(index)}>
                        <WrapperClose>
                          <CloseIcon style={{ fontSize: 19, color: theme.colors.pink900 }} />
                        </WrapperClose>
                      </CloseButton>
                    )}
                  </FlexWrapper>
                </SectionValue>
              )
            })}

          <WrapperTextLink>
            <TextLink
              onClick={() => {
                setList((value) => {
                  return [...value, { isValid: false }]
                })
              }}
              text="Adicionar outro curso à venda"
            />
          </WrapperTextLink>
          {canCreateNewCrids && (
            <WrapperButton>
              <Button text="Avançar" onClick={handleSubmit} disabled={!isValidSubmit} />
            </WrapperButton>
          )}
        </Content>
      </Container>
    </>
  )
}

import React from 'react'
import { BaseTypography } from '~/globalStyles'

export const EachDetailText = ({ property, value }) => {
  return (
    <div style={{ display: 'grid', gap: '0.25rem', alignContent: 'flex-start' }}>
      <BaseTypography fontSize={'13px'} lineHeight={'20px'} fontWeight={700}>
        {property}
      </BaseTypography>
      <BaseTypography>{value || '---'}</BaseTypography>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {
  //readWirecardBalance,
  readIuguBalance,
  readTransactionsHistory,
  withdrawIuguBalance,
  // withdrawWirecardBalance,
} from '~/services/api'
import { parseCurrency } from '~/utils'
import { defaultHeaders as headers } from './values'

const { REACT_APP_API_ENV } = process.env

export const usePartnersTransactions = ({ setIsLoading }) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState('')
  const [withdrawBalance, setWithdrawBalance] = useState('')
  // const [wirecardBalance, setWirecardBalance] = useState({})
  const [iuguBalance, setIuguBalance] = useState(null)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [customPagination, setCustomPagination] = useState([])

  // const getWirecardBalance = async () => {
  //   setIsLoading(true)
  //   try {
  //     const wirecardBalanceData = await readWirecardBalance()
  //     setWirecardBalance(wirecardBalanceData)
  //     return wirecardBalanceData
  //   } catch (error) {
  //     console.warn(error)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  const getIuguBalance = async () => {
    setIsLoading(true)
    try {
      const iuguBalanceData = await readIuguBalance()
      setIuguBalance(iuguBalanceData)
      return iuguBalanceData
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  useQuery(
    'balance',
    async () => {
      // getWirecardBalance()
      getIuguBalance()
    },
    { refetchOnWindowFocus: false },
  )

  const handleClickWithdraw = async (company) => {
    setSelectedCompany(company)

    if (REACT_APP_API_ENV === 'staging') {
      setShowModal(true)
      setWithdrawBalance(Math.floor(Math.random() * 600) + 400) // random value between R$4 and R$10
      return
    }

    // if (company === 'wirecard') {
    //   await getWirecardBalance()
    //   setShowModal(true)
    //   setWithdrawBalance(wirecardBalance?.current?.amount ? Number(wirecardBalance.current.amount) : 0)
    // }

    if (company === 'iugu') {
      await getIuguBalance()
      setShowModal(true)
      setWithdrawBalance(iuguBalance?.availableBalance ? Number(iuguBalance.availableBalance) : 0)
    }
  }

  const handleWithdraw = async () => {
    setIsLoading(true)
    let statusCode

    // if (selectedCompany === 'wirecard') {
    //   const response = await withdrawWirecardBalance({ amount: withdrawBalance })
    //   statusCode = response?.status
    // }
    if (selectedCompany === 'iugu') {
      const response = await withdrawIuguBalance({ amount: withdrawBalance })
      statusCode = response?.status
    }

    setIsLoading(false)
    setShowModal(false)

    if (statusCode === 200) {
      getTransactionHistory(5)
      // getWirecardBalance()
      getIuguBalance()
    }
  }

  const createData = ({
    createdAt,
    updatedAt,
    value: valueNumber,
    origin,
    status: statusType,
    bankName,
    agency,
    account,
    requestedBy,
  }) => {
    let value = parseCurrency(Number(valueNumber))

    let status, statusColor
    switch (statusType) {
      case 'processing':
        status = 'Em processamento'
        statusColor = '#F9E27F'
        break

      case 'paid':
        status = 'Aprovado'
        statusColor = '#A8E771'

        break

      case 'error':
        status = 'Rejeitado'
        statusColor = '#FF7C7C'
        break
    }

    return { createdAt, updatedAt, value, origin, status, statusColor, bankName, agency, account, requestedBy }
  }

  const formatRows = (rowsBE) => {
    let formattedRows = []
    rowsBE.forEach((eachRowObject) => {
      formattedRows.push(createData(eachRowObject))
    })

    return formattedRows
  }

  const getTransactionHistory = async (size) => {
    setIsLoading(true)
    try {
      const transactionHistoryData = await readTransactionsHistory({ size, page })
      setCustomPagination([transactionHistoryData.pagination])
      setRows(formatRows(transactionHistoryData.rows))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const mobileHeaders = [
    { name: 'status', data: 'Status' },
    { name: 'createdAt', data: 'Solicitado em' },
    { name: 'value', data: 'Valor' },
  ]

  useEffect(() => {
    getTransactionHistory(5)
  }, [page])

  const handleNextButton = () => {
    setPage(page + 1)
  }

  const handleBackButton = () => {
    setPage(page - 1)
  }

  return {
    showModal,
    setShowModal,
    handleClickWithdraw,
    withdrawBalance,
    selectedCompany,
    setSelectedCompany,
    //wirecardBalance,
    //setWirecardBalance,
    iuguBalance,
    setIuguBalance,
    handleWithdraw,
    rows,
    headers,
    mobileHeaders,
    customPagination,
    handleBackButton,
    handleNextButton,
  }
}

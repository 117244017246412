const compare = (a, b) => {
  if (a.key > b.key) return 1
  if (a.key < b.key) return -1
  return 0
}

export const getHeadersForListUsers = ({ isMobile, accessLevel }) => {
  let initialState = [
    { key: 3, item: 'salesManagement', label: 'Criação e gerenciamento de vendas', show: true, blocked: true },
    { key: 4, item: 'createAndEditCampaing', label: 'Criar e editar curso/campanha', show: true, blocked: true },
    { key: 5, item: 'salesVisualization', label: 'Visualização completa de vendas', show: true, blocked: true },
    { key: 6, item: 'monitoringDashboard', label: 'Dashboard de Acompanhamento', show: true, blocked: true },
    { key: 7, item: 'receivesControl', label: 'Controle de recebimentos', show: true },
    { key: 8, item: 'acessManagement', label: 'Gestão de acessos da plataforma', show: true, blocked: true },
    { key: 11, item: 'editHistory', label: '', show: true, blocked: true },
    { key: 12, item: 'editColumn', label: '', show: true, blocked: true },
  ]
  if (!isMobile) {
    initialState = [
      { key: 1, item: 'userEmail', label: 'Usuários', show: true, blocked: true },
      { key: 2, item: 'accessLevel', label: 'Nível de Visualização', show: true, blocked: true },
      ...initialState,
    ]
  }
  if (accessLevel === 'superadmin') {
    initialState = [
      ...initialState,
      { key: 9, item: 'NFEsManagement', label: 'Gestão de Nota Fiscal Eletrônica', show: true, blocked: true },
      { key: 10, item: 'bankingAccount', label: 'Alteração conta bancária', show: true, blocked: true },
    ]
  }
  return initialState.sort(compare)
}

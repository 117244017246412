/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react'
import { TextLink, theme } from '@provi/provi-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Text } from '~/themes/styles'
import { BlueCheck } from '~/assets/svgs/blue-check'
import { ArrowMore } from '~/assets/svgs/arrow-more'
import { Calculator } from '~/assets/svgs/calc'

import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

import { Title } from '~/components/title'
import { Search } from '~/components/search'
import { ProductModal } from '~/components/modals/product-campaing-modal'
import { access } from './access-control'

import { CalcModal } from '~/components/modals/calc-modal'
import { CalcModalV2 } from '~/components/modals/calc-modal-v2'

import { Modal } from './modal'

import { usePermissions } from '~/hooks'

import {
  Container,
  Content,
  SectionHeader,
  WrapperText,
  WrapperSearchBar,
  WrapperTextLink,
  WrapperTabs,
  Bold,
  SectionProducts,
  // card
  Option,
  Grid,
  RedTitle,
  WrapperIcon,
  WrapperEyeIcon,
  DownloadMore,
  Bottom,
  BoxButton,
  WrapperProduct,
  CheckIcon,
  CardBottomSection,
  WrapperCalc,
} from './styles'
import { useCampaings } from './hooks'

export const ListCampaings = () => {
  const { goToPage, setIsLoading, activePage, setActivePage, isMobile } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  const [
    seeMoreActive,
    seeMoreInactive,
    handleSearchValue,
    incrementNumberOfCourses,
    activeOptions,
    inactiveOptions,
    search,
    activeOptionsShow,
    inactiveOptionsShow,
    showModal,
    handleModal,
    handleCopy,
    showProductModal,
    location,
    submitHandleEye,
    handleEye,
    modalOption,
    userAccess,
    productModalOptions,
    visibleOpt,
    setVisibleOpt,
    handleCalcClick,
    isShowCalcModal,
    handleCalcModal,
    calcConfig,
  ] = useCampaings(setIsLoading, activePage, setActivePage)

  const { canCreateNewCrids, partnerID } = usePermissions()

  return (
    <>
      {userAccess && (
        <>
          {showModal && (
            <Modal handleModal={handleModal} location={location} optionChange={modalOption} submitHandleEye={submitHandleEye} />
          )}
          {showProductModal && (
            <ProductModal handleModal={handleModal} show={showProductModal} ProductData={productModalOptions} />
          )}
          {isShowCalcModal && <CalcModalV2 handleModal={handleCalcModal} show={isShowCalcModal} calcConfig={calcConfig} />}

          <Container>
            <Content isMobile={isMobile} drawerWidth={drawerWidth}>
              <SectionHeader>
                <WrapperText>
                  <Title text="Veja mais sobre suas campanhas abaixo:" />
                </WrapperText>
                <WrapperSearchBar>
                  <Search
                    type="text"
                    placeholder="Digite o nome da campanha desejada"
                    onChange={handleSearchValue}
                    value={search}
                  />
                </WrapperSearchBar>
                {access.createCampaing[userAccess] && (
                  <WrapperTextLink>
                    <TextLink onClick={() => goToPage('campanha/selecionar-produtos')} text="Criar nova campanha" />
                  </WrapperTextLink>
                )}
              </SectionHeader>
              <WrapperTabs id="wrapper-tabs">
                <Tabs selectedIndex={visibleOpt.tabIndex} onSelect={(tabIndex) => setVisibleOpt({ tabIndex })}>
                  <TabList>
                    <Tab>Campanhas ativas</Tab>
                    <Tab>Campanhas inativas</Tab>
                  </TabList>
                  <Text size="14px" lineHeight="20px" color={theme.colors.blue900} align="center" mTop="25px">
                    Campanhas são um <Bold> &ldquo;pacote de venda&ldquo; pré definido</Bold>, que pode conter{' '}
                    <Bold> um ou mais produtos</Bold>, por um preço personalizado e que será{' '}
                    <Bold> comercializado para mais de uma pessoa.</Bold>
                  </Text>
                  <TabPanel>
                    <>
                      <SectionProducts>
                        {activeOptionsShow &&
                          activeOptionsShow.map((ao, index) => (
                            <Option key={index}>
                              <Grid first>
                                <RedTitle>{ao.name}</RedTitle>
                              </Grid>

                              {ao.courses && (
                                <Grid>
                                  <WrapperIcon>
                                    <BlueCheck />
                                  </WrapperIcon>
                                  <WrapperProduct onClick={() => handleModal('product', true, '', ao)}>
                                    <Text size="12px" lineHeight="18px" bold color={theme.colors.blue900}>
                                      {ao.courses.length} Produto{ao.courses.length > 1 ? 's' : ''}
                                    </Text>
                                  </WrapperProduct>
                                </Grid>
                              )}
                              {ao.checkout_price_in_cents && (
                                <Grid>
                                  <WrapperIcon>
                                    <BlueCheck />
                                  </WrapperIcon>
                                  <Text size="12px" lineHeight="18px" bold color={theme.colors.blue900}>
                                    Valor da venda: {ao.formattedValue}
                                  </Text>
                                </Grid>
                              )}
                              {ao.createdAt && (
                                <Grid>
                                  <WrapperIcon>
                                    <BlueCheck />
                                  </WrapperIcon>
                                  <Text size="12px" lineHeight="18px" bold color={theme.colors.blue900}>
                                    Data de criação: {ao.formattedCreateDate}
                                  </Text>
                                </Grid>
                              )}

                              {ao.formattedPaymentMethods && (
                                <Grid>
                                  <WrapperIcon>
                                    <BlueCheck />
                                  </WrapperIcon>
                                  <Text size="12px" lineHeight="18px" bold color={theme.colors.blue900}>
                                    Pagamento disponível: {ao.formattedPaymentMethods}
                                  </Text>
                                </Grid>
                              )}

                              <Bottom>
                                {canCreateNewCrids || partnerID === 729 ? (
                                  <CopyToClipboard text={ao.redirect_url} onCopy={handleCopy}>
                                    <BoxButton noLink>Copiar link</BoxButton>
                                  </CopyToClipboard>
                                ) : null}
                                <CardBottomSection>
                                  <WrapperCalc onClick={(e) => handleCalcClick(e, ao)}>
                                    <Calculator />
                                  </WrapperCalc>
                                  <WrapperEyeIcon visible onClick={() => handleEye('active', ao)}>
                                    <CheckIcon active />
                                  </WrapperEyeIcon>
                                </CardBottomSection>
                              </Bottom>
                            </Option>
                          ))}
                      </SectionProducts>

                      {activeOptionsShow && activeOptionsShow.length === 0 ? (
                        search ? (
                          <>
                            <Text
                              size="16px"
                              lineHeight="24px"
                              bold
                              color={theme.colors.blue900}
                              align="center"
                              display="block"
                            >
                              Não foi encontrada nenhuma campanha ativa para: {search}
                            </Text>
                          </>
                        ) : (
                          <>
                            <Text
                              size="16px"
                              lineHeight="24px"
                              bold
                              color={theme.colors.blue900}
                              align="center"
                              display="block"
                            >
                              Não foi encontrada nenhuma campanha ativa
                            </Text>
                          </>
                        )
                      ) : (
                        ''
                      )}

                      {seeMoreActive && activeOptions.length > 0 && (
                        <DownloadMore onClick={incrementNumberOfCourses}>
                          <Text margin="0" size="16px" lineHeight="20px" color={theme.colors.blue900} bold>
                            VER MAIS
                          </Text>
                          <ArrowMore />
                        </DownloadMore>
                      )}
                    </>
                  </TabPanel>
                  <TabPanel>
                    <>
                      <SectionProducts>
                        {inactiveOptionsShow &&
                          inactiveOptionsShow.map((io, index) => (
                            <Option key={index}>
                              <Grid first>
                                <RedTitle inactive>{io.name}</RedTitle>
                              </Grid>

                              {io.courses && (
                                <Grid>
                                  <WrapperIcon>
                                    <BlueCheck inactive />
                                  </WrapperIcon>
                                  <WrapperProduct onClick={() => handleModal('product', true, '', io)}>
                                    <Text size="12px" lineHeight="18px" bold color={theme.colors.blue900}>
                                      {io.courses.length} Produto{io.courses.length > 1 ? 's' : ''}
                                    </Text>
                                  </WrapperProduct>
                                </Grid>
                              )}
                              {io.checkout_price_in_cents && (
                                <Grid>
                                  <WrapperIcon>
                                    <BlueCheck inactive />
                                  </WrapperIcon>
                                  <Text size="12px" lineHeight="18px" bold color={theme.colors.blue900}>
                                    Valor da venda: {io.formattedValue}
                                  </Text>
                                </Grid>
                              )}
                              {io.createdAt && (
                                <Grid>
                                  <WrapperIcon>
                                    <BlueCheck inactive />
                                  </WrapperIcon>
                                  <Text size="12px" lineHeight="18px" bold color={theme.colors.blue900}>
                                    Data de criação: {io.formattedCreateDate}
                                  </Text>
                                </Grid>
                              )}
                              {io.formattedPaymentMethods && (
                                <Grid>
                                  <WrapperIcon>
                                    <BlueCheck inactive />
                                  </WrapperIcon>
                                  <Text size="12px" lineHeight="18px" bold color={theme.colors.blue900}>
                                    Pagamento disponível: {io.formattedPaymentMethods}
                                  </Text>
                                </Grid>
                              )}
                              <Bottom single>
                                <CardBottomSection>
                                  <WrapperCalc onClick={(e) => handleCalcClick(e, io)}>
                                    <Calculator isDisable />
                                  </WrapperCalc>
                                  <WrapperEyeIcon visible onClick={() => handleEye('inactive', io)}>
                                    <CheckIcon />
                                  </WrapperEyeIcon>
                                </CardBottomSection>
                              </Bottom>
                            </Option>
                          ))}
                      </SectionProducts>

                      {inactiveOptionsShow && inactiveOptionsShow.length === 0 ? (
                        search ? (
                          <>
                            <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900} align="center">
                              Não foi encontrada nenhuma campanha inativa para: {search}
                            </Text>
                          </>
                        ) : (
                          <>
                            <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900} align="center">
                              Não foi encontrada nenhuma campanha inativa
                            </Text>
                          </>
                        )
                      ) : (
                        ''
                      )}

                      {seeMoreInactive && inactiveOptions.length > 0 && (
                        <DownloadMore onClick={incrementNumberOfCourses}>
                          <Text margin="0" size="16px" lineHeight="20px" color={theme.colors.blue900} bold>
                            VER MAIS
                          </Text>
                          <ArrowMore />
                        </DownloadMore>
                      )}
                    </>
                  </TabPanel>
                </Tabs>
              </WrapperTabs>
            </Content>
          </Container>
        </>
      )}
    </>
  )
}

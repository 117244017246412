/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { TemplateContext } from '~/components/Template/context'
import { Calculator } from '~/assets/svgs/calc'
import { Copy } from '~/assets/svgs/Copy'

import { Wrapper, DropDown, IconBox } from './styles'
import { useStatusBox } from './hooks'

const statusWithoutCalculator = ['canceled', 'made_effective', 'denied']
const notPermittedProductTypes = ['ISA', 'ISAAAS', 'Boleto', 'CreditCard', 'ProviPay']

export const StatusBox = (props) => {
  const {
    initialStatus,
    handleModal,
    handleCalcModal,
    id,
    setCopyLinksData,
    redirect_url,
    coursesLength,
    productType,
    setCalcConfig,
  } = props

  const { setIsLoading } = useContext(TemplateContext)
  const [handleOption, option, handleClickCalcModal, handleCopyLinksModal, handleUpdatesModal] = useStatusBox(
    id,
    handleModal,
    handleCalcModal,
    setIsLoading,
    setCopyLinksData,
    redirect_url,
    setCalcConfig,
  )

  return (
    <Wrapper>
      <DropDown
        initialStatus={initialStatus}
        status={option}
        native
        disabled={
          initialStatus !== 'not_logged' ||
          (option && option !== 'not_logged' && (initialStatus !== 'logged' || option !== 'logged'))
        }
        value={option || initialStatus}
        onChange={handleOption}
        inputProps={{
          name: 'age',
          id: 'age-native-simple',
        }}
        onClick={() => handleUpdatesModal(initialStatus)}
      >
        {initialStatus === 'not_logged' ? (
          <>
            <option style={{ display: 'none' }} value="not_logged">
              NÃO ACESSADO
            </option>
            <option value="link_inactive">LINK INATIVADO</option>
          </>
        ) : (
          <>
            <option value={null}> NÃO IDENTIFICADO</option>
            <option value="not_logged"> LOGIN | SEM ACESSO</option>
            <option value="link_inactive"> LINK INATIVADO</option>
            <option value="abandonment"> DESISTÊNCIA</option>
            <option value="expired"> EXPIRADO</option>
            <option value="negativated"> NEGATIVADO</option>
            <option value="basic_info_incomplete"> INFOS BÁSICAS | INCOMPLETO</option>
            <option value="document_info_incomplete"> DOCUMENTOS | INCOMPLETO</option>
            <option value="guarantor_needed"> PRECISA INDICAR AVALISTA</option>
            <option value="guarantor_incomplete"> AVALISTA | INCOMPLETO</option>
            <option value="basic_info_review"> REVISÃO | INFOS BÁSICAS </option>
            <option value="document_info_review"> REVISÃO | DOCUMENTOS</option>
            <option value="guarantor_review">REVISÃO | AVALISTA DOCUMENTOS</option>
            <option value="analysis"> EM ANÁLISE</option>
            <option value="approved_by_provi"> APROVADO PROVI</option>
            <option value="approved_with_caveats_by_provi"> APROVADO COM RESSALVAS PROVI</option>
            <option value="not_approved_by_provi"> REPROVADO PROVI</option>
            <option value="approved"> APROVADO</option>
            <option value="waiting_signature"> AGUARDANDO ASSINATURA</option>
            <option value="waiting_payment"> AGUARDANDO PAGAMENTO </option>
            <option value="made_effective"> EFETIVADO</option>
            <option value="canceled"> CANCELADO</option>
            <option value="denied"> NEGADO</option>
            <option value="isa_default"> DEFAULT</option>
            <option value="abandonment_before_signed"> DESISTÊNCIA</option>
            <option value="abandonment_after_signed"> DESISTÊNCIA APÓS ASSINATURA</option>
            <option value="isa_abandonment_after_signed"> DESISTÊNCIA APÓS ASSINATURA</option>
            <option value="abandonment_after_upfront"> DESISTÊNCIA APÓS ENTRADA</option>
            <option value="abandonment_after_settled"> CANCELADO</option>
          </>
        )}
      </DropDown>

      {redirect_url && (
        <IconBox onClick={handleCopyLinksModal}>
          <Copy />
        </IconBox>
      )}

      {!statusWithoutCalculator.includes(initialStatus) &&
        !notPermittedProductTypes.includes(productType) &&
        coursesLength > 0 && (
          <IconBox onClick={handleClickCalcModal}>
            <Calculator />
          </IconBox>
        )}
    </Wrapper>
  )
}

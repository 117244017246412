import { useContext, useState, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'
import { useTable, usePagination, useExpanded, useColumnOrder, useFilters } from 'react-table'
import { getChargebacks, getChargebackFilters } from '~/services/api'
import { showToast } from '@provi/provi-components'
import { getActiveUser } from '~/utils'
import { chargebacksColumns } from './chargebacksTableConfig'
import { TableFilter } from '~/components/v2/TableV2/components'
import { parseFiltersFromUrlToArrOfObj, parseFiltersFromStateArr } from '~/screens/requests/helpers'

export const useChargebacks = () => {
  const { isMobile, setIsLoading, goToPage: goToPageFromContext } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)
  const [filterOptions, setFilterOptions] = useState({})
  const [chargeBacksData, setChargeBacksData] = useState([])
  const [pageCountState, setPageCountState] = useState(null)
  const [totalCount, setTotalCount] = useState(null)
  const history = useHistory()
  const location = useLocation()

  const getChargebacksData = async (tableStatePage, tableStateSize, tableFilters) => {
    try {
      setIsLoading(true)
      const { chargebacks, count } = await getChargebacks(tableStatePage, tableStateSize, tableFilters)
      setChargeBacksData(chargebacks)
      setTotalCount(count)
      setPageCountState(Math.ceil(count / pageSize))
    } catch (error) {
      console.warn(error)
      showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
    } finally {
      setIsLoading(false)
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    canNextPage,
    canPreviousPage,
    prepareRow,
    visibleColumns,
    setColumnOrder,
    setHiddenColumns,
    state: { pageIndex, pageSize, filters },
  } = useTable(
    {
      columns: useMemo(() => chargebacksColumns, []) || [],
      data: useMemo(() => chargeBacksData, [chargeBacksData]) || [],
      manualPagination: true,
      manualFilters: true,
      pageCount: pageCountState,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetExpanded: false,
      defaultColumn: {
        gotoPage: (path) => goToPageFromContext(path),
        isMobileProps: isMobile,
        Filter: TableFilter,
        disableFilters: true,
      },
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            filterOptions: filterOptions,
          }),
          [state, filterOptions],
        )
      },
    },
    useFilters,
    useExpanded,
    usePagination,
    useColumnOrder,
  )

  useEffect(() => {
    const getTableFilters = async () => {
      try {
        const { data } = await getChargebackFilters()
        if (!data.error) {
          const formatData = data.map((eachObject) => ({ value: eachObject.status, label: eachObject.label }))
          setFilterOptions({ status: formatData })
        }
      } catch (error) {
        console.error(error)
      }
    }
    getTableFilters()
  }, [])

  useEffect(() => {
    const activeUser = getActiveUser()
    if (filters.length > 0) {
      const finalFilterObject = parseFiltersFromStateArr(filters, activeUser)
      getChargebacksData(pageIndex, pageSize, finalFilterObject)
      const finalQueryString = new URLSearchParams(finalFilterObject)
      history.replace({ pathname: location.pathname, search: finalQueryString.toString() })
    } else {
      const initialUrlFilters = new URLSearchParams(location.search)
      const params = Object.fromEntries(initialUrlFilters)
      const filtersFromUrl = parseFiltersFromUrlToArrOfObj(params)
      const finalFiltersFromUrl = parseFiltersFromStateArr(filtersFromUrl, activeUser)
      getChargebacksData(pageIndex, pageSize, finalFiltersFromUrl)
    }
  }, [pageIndex, pageSize, filters])

  useEffect(() => {
    if (isMobile) {
      setHiddenColumns(['actions', 'requestedAt', 'total', 'CPF', 'email'])
      setColumnOrder(['status', 'CreditRequestId', 'expireAt', 'requestedAt', 'total', 'CPF', 'email', 'actions'])
    }
    if (!isMobile) {
      setHiddenColumns(['expanderIcon'])
      setColumnOrder(['CreditRequestId', 'actions', 'status', 'expireAt', 'requestedAt', 'total', 'CPF', 'email'])
    }
  }, [isMobile, setColumnOrder, setHiddenColumns])

  return {
    isMobile,
    drawerWidth,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageIndex,
    pageSize,
    gotoPage,
    pageCount,
    canNextPage,
    canPreviousPage,
    totalCount,
    setPageSize,
    prepareRow,
    visibleColumns,
  }
}

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const OptionsSection = styled.div`
  margin-top: 15px;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 115px;

  ${theme.breakpoints.down('md')} {
    flex-wrap: wrap;
    height: auto;
  }
`

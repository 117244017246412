import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useToggle } from '~/hooks'
import { uniqueId } from 'lodash'
import { getActiveUser, isEmptyObj } from '~/utils'
import { TextButton, ButtonV3, CheckBox } from '@provi/provi-components'
import { LineFilterSvg } from '~/assets/svgs/linefunnel'
import {
  FilterContainer,
  HeaderContainer,
  RelativeContainer,
  ActionsContainer,
  OptionsContainer,
  FixedPositionWrapper,
} from './styles'

export const TableFilter = ({ column: { setFilter, render, id: columnId }, state: { filterOptions } }) => {
  const { replace } = useHistory()
  const { search } = useLocation()
  const [filterVisibility, toggleFilterVisibility] = useToggle()
  const [checkedState, setCheckedState] = useState([])

  const checkForActiveFilters = useMemo(() => {
    return search.includes(columnId)
  }, [search])

  const handleChange = useCallback(
    (positionIndex) => {
      const updatedCheckedState = checkedState.map((eachBoolean, index) =>
        index === positionIndex ? !eachBoolean : eachBoolean,
      )
      setCheckedState(updatedCheckedState)
    },
    [checkedState],
  )

  const setFilterArray = useCallback(() => {
    return !isEmptyObj(filterOptions) && filterOptions[columnId].filter((_, optionIndex) => checkedState[optionIndex] === true)
  }, [filterOptions, checkedState])

  const handleClear = useCallback(() => {
    setCheckedState(new Array(!isEmptyObj(filterOptions) && filterOptions[columnId].length).fill(false))
    setFilter(null)
    replace({ search: `u=${getActiveUser()}` })
    toggleFilterVisibility()
  }, [filterOptions, setFilter, toggleFilterVisibility])

  const handleSetFilters = useCallback(() => {
    checkedState.some((boolean) => boolean === true) && setFilter(setFilterArray)
    toggleFilterVisibility()
  }, [checkedState, setFilter, setFilterArray, toggleFilterVisibility])

  useEffect(() => {
    if (!isEmptyObj(filterOptions)) setCheckedState(new Array(filterOptions[columnId].length).fill(false))
  }, [filterOptions])

  return (
    <RelativeContainer>
      <HeaderContainer>
        <span>{render('Header')}</span>
        <span onClick={toggleFilterVisibility} style={{ cursor: 'pointer' }}>
          <LineFilterSvg isActive={checkForActiveFilters} />
        </span>
      </HeaderContainer>

      {filterVisibility && (
        <FilterContainer>
          <FixedPositionWrapper>
            <OptionsContainer>
              {!isEmptyObj(filterOptions) &&
                filterOptions[columnId].map((eachElement, index) => {
                  return (
                    <CheckBox
                      key={uniqueId()}
                      htmlFor={eachElement.label}
                      name={eachElement.label}
                      value={eachElement.label}
                      text={eachElement.label}
                      checked={checkedState[index]}
                      onChange={() => handleChange(index)}
                    />
                  )
                })}
            </OptionsContainer>

            <ActionsContainer>
              <TextButton onClick={() => handleClear()} text="Limpar filtro" />
              <ButtonV3 width={'138px'} height={'40px'} onClick={() => handleSetFilters()} text="Filtrar" />
            </ActionsContainer>
          </FixedPositionWrapper>
        </FilterContainer>
      )}
    </RelativeContainer>
  )
}

import { api } from '~/services/api'

export const postAddTimeLineForm = async (formObject) => {
  return api.post('/v4/course-class-launch', formObject)
}

export const getCourse = async (courseId) => {
  return api.get(`/v4/courses/${courseId}`)
}

export const getClassroom = async (classroomId) => {
  return api.get(`/v4/course-classes/${classroomId}`)
}

export const editClassroomVisibility = async (classroomId, body) => {
  return api.put(`/v4/course-classes/${classroomId}`, body)
}

export const editCourseVisibility = async (courseId, body) => {
  return api.put(`/v4/courses/${courseId}`, body)
}

export const deleteClassroom = async (classroomId) => {
  return api.delete(`/v4/course-classes/${classroomId}`)
}

export const getCourseClassIds = async () => {
  return api.get('/v4/course-classes/info')
}

export const getCampi = async () => {
  return api.get('/v4/campus')
}

export const getProviPayVersion = async () => {
  return api.get('/v4/provipay/version')
}

export const postCourse = async (formObject) => {
  return api.post('/v4/courses', formObject)
}

export const postCourseClass = async (formObject) => {
  return api.post('/v4/course-classes', formObject)
}

export const postNewCampus = async (formObject) => {
  return api.post('/v4/campus', formObject)
}

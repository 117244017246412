const { REACT_APP_API_ENV } = process.env

export const URL_ENV_CHECKOUT = {
  staging: `https://checkout-staging.provi.com.br`,
  production: `https://checkout.provi.com.br`,
}

export const URL_ENV_PAINEL = {
  staging: `https://painel-staging.provi.com.br`,
  production: `https://painel.provi.com.br`,
}

export const matchUrlCheckout = () => {
  const URL = window.location.href
  const verifyUrlCheckout = URL.includes(URL_ENV_CHECKOUT[REACT_APP_API_ENV])

  if (verifyUrlCheckout) return true

  return false
}

export const matchUrlPainel = () => {
  const URL = window.location.href
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const token = urlParams.get('token')

  const verifyUrlPainel = URL.includes(URL_ENV_PAINEL[REACT_APP_API_ENV])

  if (verifyUrlPainel && token) return true

  return false
}

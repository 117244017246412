import React from 'react'
import { parseISO, format } from 'date-fns'
import {
  RecordedClasses,
  RemoteClasses,
  FaceToFaceIcon,
  MixedModalityIcon,
  MixedAttendanceIcon,
  LiveIcon,
} from 'assets/svgs/v2'

export const formatPaymentMethods = (string) => {
  const paymentMethodsFormatter = {
    CreditCard: 'Cartão de crédito',
    Boleto: 'Boleto à vista',
    CourseFinancing: 'Financiamento',
    fallback: '---',
  }
  return paymentMethodsFormatter[string] || paymentMethodsFormatter.fallback
}

export const findLowestValue = (arrOfValues) => {
  return Math.min(...arrOfValues)
}

export const formatDateToDDMM = (dateString) => {
  try {
    return format(parseISO(dateString), 'dd/MM')
  } catch (error) {
    return '---'
  }
}

export const formatCourseClassStrings = (string) => {
  const courseClassStringsFormatter = {
    // Modalities
    'semi presencial': 'Híbrido',
    // Attend types
    'sob demanda': 'Gravada',
    // Periods
    integral: 'Tempo integral',
  }
  return courseClassStringsFormatter[string] || string
}

export const getCorrespondingIcon = (string) => {
  const correspondingIcon = {
    // Modalities
    online: <RemoteClasses />,
    presencial: <FaceToFaceIcon />,
    'semi presencial': <MixedModalityIcon />,

    // Attend types
    misto: <MixedAttendanceIcon />,
    'ao vivo': <LiveIcon />,
    'sob demanda': <RecordedClasses />,
    fallback: <></>,
  }
  return correspondingIcon[string] || correspondingIcon.fallback
}

import React from 'react'
import { Collapse } from '@material-ui/core'
import { theme } from '@provi/provi-components'
import { useToggle } from '~/hooks'
import { BaseTypography } from '~/globalStyles'
import { CollapseContainer, TextGrid } from './styles'
import { EachDetailText } from '~/screens/courses/components/DetailsComponents/components'
import { capitalize } from 'lodash'
import { formatCourseClassStrings } from '~/screens/courses/helpers'
import { format, parseISO } from 'date-fns'
import masks from '~/themes/masks'

const RenderModality = ({ classroomDetails }) => {
  const { CourseClassModality, CourseClassAttendType, Campus } = classroomDetails
  if (CourseClassModality.id === 1) {
    return (
      <>
        {capitalize(formatCourseClassStrings(CourseClassModality?.name))}
        <br />
        {capitalize(formatCourseClassStrings(CourseClassAttendType?.name))}
      </>
    )
  }

  if (CourseClassModality.id === 2) {
    return (
      <>
        {capitalize(formatCourseClassStrings(CourseClassModality?.name))}
        <br />
        {capitalize(formatCourseClassStrings(CourseClassAttendType?.name))}
        <br />
        {`Unidade: ${Campus?.name || '---'}`}
      </>
    )
  }

  if (CourseClassModality.id === 3) {
    return (
      <>
        {capitalize(formatCourseClassStrings(CourseClassModality?.name))}
        <br />
        {`Unidade: ${Campus?.name || '---'}`}
      </>
    )
  }

  return <>---</>
}

const RenderPeriod = ({ classroomDetails }) => {
  const { CourseClassModality, CourseClassPeriod } = classroomDetails

  if (CourseClassModality.id === 2 || CourseClassModality.id === 3) {
    return <>{capitalize(formatCourseClassStrings(CourseClassPeriod?.name))}</>
  }

  return <>---</>
}

const RenderCartClosingDate = ({ classroomDetails }) => {
  const { expiresAt } = classroomDetails

  if (!expiresAt) return <>---</>

  return <>{format(parseISO(expiresAt), 'dd/MM/yyyy, HH:mm')}</>
}

export const ClassroomExpandDetails = ({ isMobile, classroomDetails }) => {
  const [dropdownState, toggleDropdownState] = useToggle()

  return (
    <>
      {isMobile ? (
        <CollapseContainer>
          <BaseTypography
            onClick={toggleDropdownState}
            fontSize={'13px'}
            lineHeight={'20px'}
            fontWeight={700}
            color={theme.colors.bluePrimary}
            cursor={'pointer'}
          >
            Mais detalhes
          </BaseTypography>

          <Collapse in={dropdownState}>
            <TextGrid>
              <EachDetailText property={'Modalidade'} value={<RenderModality classroomDetails={classroomDetails?.content} />} />
              <EachDetailText property={'Período'} value={<RenderPeriod classroomDetails={classroomDetails?.content} />} />
              <EachDetailText
                property={'Prazo de garantia'}
                value={`${classroomDetails?.content?.gracePeriodDays || '7'} dia(s)`}
              />
              <EachDetailText property={'Preço'} value={masks.currency(classroomDetails?.content?.priceInCents)} />
              <EachDetailText property={'Código interno'} value={classroomDetails?.content?.partnerSKU} />
              <EachDetailText
                property={'Fechar carrinho'}
                value={<RenderCartClosingDate classroomDetails={classroomDetails?.content} />}
              />
            </TextGrid>
          </Collapse>
        </CollapseContainer>
      ) : (
        <TextGrid>
          <EachDetailText property={'Modalidade'} value={<RenderModality classroomDetails={classroomDetails?.content} />} />
          <EachDetailText property={'Período'} value={<RenderPeriod classroomDetails={classroomDetails?.content} />} />
          <EachDetailText
            property={'Prazo de garantia'}
            value={`${classroomDetails?.content?.gracePeriodDays || '7'} dia(s)`}
          />
          <EachDetailText property={'Preço'} value={masks.currency(classroomDetails?.content?.priceInCents)} />
          <EachDetailText property={'Código interno'} value={classroomDetails?.content?.partnerSKU} />
          <EachDetailText
            property={'Fechar carrinho'}
            value={<RenderCartClosingDate classroomDetails={classroomDetails?.content} />}
          />
        </TextGrid>
      )}
    </>
  )
}

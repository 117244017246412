import React from 'react'
import { Button } from '~/components/v3'
import { CloseIcon } from '~/assets/svgs/v2'
import { MiddleSection, EachModalRow } from '../index'
import {
  StyledPortalModal,
  ModalContainer,
  FlexContainer,
  BlueHeader,
  BtnFlexContainer,
  BlackParagraph,
  Spacer,
} from './styles'

export const FinancingConditionsModal = ({
  isMobile,
  modalRef,
  closeModalFn,
  handlePrimaryBtn,
  handleSecondaryBtn,
  conditionTitle,
  simulations,
}) => {
  const { options = [], values } = simulations || {}

  return (
    <StyledPortalModal ref={modalRef} backdropColor={'rgba(212, 218, 247, 0.8)'}>
      <ModalContainer>
        <FlexContainer>
          <BlueHeader>{conditionTitle}</BlueHeader>
          <CloseIcon onClick={closeModalFn} />
        </FlexContainer>
        <MiddleSection isMobile={isMobile} values={values} />
        {!!options.length &&
          options.map((eachObj) => (
            <EachModalRow key={eachObj.id} isMobile={isMobile} eachObj={eachObj} upfrontAmount={values.upfrontAmount} />
          ))}
        <BlackParagraph>
          Cada parcela é composta pela amortização do valor parcelado, juros do período, IOF fixo e IOF diário. O IOF é o
          imposto federal cobrado sobre operações de crédito e, no caso de financiamentos, é calculado de acordo com o prazo
          previsto para o pagamento.
        </BlackParagraph>
        <Spacer />
        <BtnFlexContainer>
          <Button text={'Cancelar'} color={'white'} mobileWidth={'100%'} onClick={handleSecondaryBtn} />
          <Button text={'Copiar condições'} mobileWidth={'100%'} onClick={handlePrimaryBtn} />
        </BtnFlexContainer>
      </ModalContainer>
    </StyledPortalModal>
  )
}

import React from 'react'
import { format, parseISO } from 'date-fns'
import { parsePrice } from 'utils/parsePrice'
import { CalendarIcon, CircleX, OpenedEye, ClosedEye } from 'assets/svgs/v2'
import {
  CardContainer,
  TopContainer,
  Text,
  IconsContainer,
  CardFlexContainer,
  BottomContainer,
  TopGrid,
  BottomGrid,
  CardTag,
} from './styles'
import { IClassroomCardProps } from './@types'
import { IntegrationCheck } from './components'
import { getCorrespondingIcon, formatCourseClassStrings } from '../../helpers'

const renderDates = (startsAt: string, endsAt: string) => {
  if (!startsAt || !endsAt) return

  try {
    return `${format(parseISO(startsAt), 'dd/MM')} a ${format(parseISO(endsAt), 'dd/MM')}`
  } catch (err) {
    console.log(err)
  }
}

const renderIconsText = (string: string) => string && string.charAt(0).toUpperCase() + string.slice(1)

interface IRenderBottomGrid {
  active: boolean
  visible: boolean
}

const renderBottomGrid = ({ active, visible }: IRenderBottomGrid) => {
  if (!active && visible) {
    return (
      <>
        <CardFlexContainer>
          <CircleX onClick={() => {}} size={'20'} color={'black'} />
          <Text>Inativa</Text>
        </CardFlexContainer>
      </>
    )
  }

  if (!visible) {
    return (
      <>
        <CardFlexContainer>
          <ClosedEye onClick={() => {}} size={'20'} color={'black'} />
          <Text>Invisível</Text>
        </CardFlexContainer>
      </>
    )
  }

  if (visible) {
    return (
      <>
        <CardFlexContainer>
          <OpenedEye onClick={() => {}} size={'20'} color={'black'} />
          <Text>Visível</Text>
        </CardFlexContainer>
      </>
    )
  }

  return null
}

const objIconsRender = ({ classRoomObj }: IClassroomCardProps) => {
  return {
    Remote: () => (
      <>
        {classRoomObj?.CourseClassModality?.name && getCorrespondingIcon(classRoomObj?.CourseClassModality?.name)}
        <Text>{renderIconsText(formatCourseClassStrings(classRoomObj?.CourseClassModality?.name))}</Text>
      </>
    ),
    RecordedClasses: () => (
      <>
        {classRoomObj?.CourseClassAttendType?.name && getCorrespondingIcon(classRoomObj?.CourseClassAttendType?.name)}
        <Text>{renderIconsText(formatCourseClassStrings(classRoomObj?.CourseClassAttendType?.name))}</Text>
      </>
    ),
    Calendar: () => (
      <>
        {classRoomObj?.CourseClassPeriod?.name && <CalendarIcon onClick={() => {}} size={'20'} color={'black'} />}
        <Text>{renderIconsText(formatCourseClassStrings(classRoomObj?.CourseClassPeriod?.name))}</Text>
      </>
    ),
  }
}

export const ClassroomCard = ({ classRoomObj }: IClassroomCardProps) => {
  return (
    <CardContainer>
      {classRoomObj?.ProductType === 'ISA' && <CardTag isActive={classRoomObj?.active}>ISA</CardTag>}
      <TopContainer isActive={classRoomObj?.active}>
        <TopGrid>
          <Text isBlueTitle isActive={classRoomObj?.active}>
            {classRoomObj?.name}
          </Text>
          <Text isBlue isActive={classRoomObj?.active}>
            {renderDates(classRoomObj?.startsAt, classRoomObj?.endsAt)}
          </Text>
        </TopGrid>
        <IconsContainer>
          {classRoomObj?.CourseClassModality?.name && (
            <CardFlexContainer>{objIconsRender({ classRoomObj }).Remote()}</CardFlexContainer>
          )}
          {classRoomObj?.CourseClassAttendType?.name && (
            <CardFlexContainer>{objIconsRender({ classRoomObj }).RecordedClasses()}</CardFlexContainer>
          )}
          {classRoomObj?.CourseClassPeriod?.name && (
            <CardFlexContainer>{objIconsRender({ classRoomObj }).Calendar()}</CardFlexContainer>
          )}
        </IconsContainer>
        <Text isActive={classRoomObj?.active} isPriceNumber>
          {classRoomObj.priceInCents ? parsePrice(classRoomObj.priceInCents / 100) : '---'}
        </Text>

        {classRoomObj.hostingProvider === 'memberkit' && <IntegrationCheck text={'Integração MemberKit'} />}
      </TopContainer>
      <BottomContainer isActive={classRoomObj?.active}>
        <BottomGrid>
          {renderBottomGrid({
            active: classRoomObj?.active,
            visible: classRoomObj?.visible,
          })}
        </BottomGrid>
      </BottomContainer>
    </CardContainer>
  )
}

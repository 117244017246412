import React from 'react'
import { Text } from '~/themes/styles'
import { Section, Underline } from './styles'
import { InstallmentValueHelper, InlineBold } from './components'

const StatusHelper = () => {
  return (
    <Section>
      <Text bold mBottom="10px" size="18px">
        Definição dos status:
      </Text>
      <Text mBottom="5px">
        - <Underline>Pendente</Underline>: fatura do mês vigente está em aberto, mas ainda não venceu (pagamento em dia).
      </Text>
      <Text mBottom="5px">
        - <Underline>Inadimplente</Underline>: fatura(s) não foi(foram) paga(s) e a data de vencimento já passou (atraso no
        pagamento).
      </Text>
      <Text mBottom="5px">
        - <Underline>Pago</Underline>: fatura(s) paga(s). O pagamento está em dia até o momento vigente.
      </Text>
    </Section>
  )
}

export const useHelperModal = (helperModalData) => {
  const helperModalOptions = {
    status: <StatusHelper />,
    total_amount_receivable: <Text>Valor sujeito a alteração mediante cancelamento.</Text>,
    total_amount_received: <Text>Valor transferido da Provi para a instituição.</Text>,
    tedDate: <Text>Data do primeiro pagamento realizado pela Provi.</Text>,
    installment_value: <InstallmentValueHelper />,
    expectedCompensationDate: (
      <Text>
        A previsão do saldo é calculada em cima da data de <InlineBold>pagamento do aluno</InlineBold>. O saldo fica disponível
        em até <InlineBold>1 dia útil</InlineBold> após pagamento do aluno.
      </Text>
    ),
    compensationDate: (
      <Text>
        Data em que foi <InlineBold>solicitado </InlineBold> a transferência dos valores para a conta bancária.
      </Text>
    ),
  }
  const data = helperModalOptions[helperModalData]

  return { data }
}

const initialMobile = [
  { item: 'id', label: 'ID', show: true, blocked: true },
  { item: 'status', label: 'STATUS', show: true, blocked: true },
  { item: 'hostingStatus', label: 'Acesso à hospedagem', show: true, blocked: true },
  { item: 'cpf', label: 'CPF', show: true, blocked: true },
  { item: 'productType', label: 'Método de pagamento', show: true },
  { item: 'name', label: 'Nome', show: true, blocked: true },
  { item: 'email', label: 'Email', show: true },
  { item: 'phone', label: 'Telefone', show: true },
  { item: 'campaignName', label: 'Nome da campanha', show: true },
  { item: 'products', label: 'Produtos', show: true },
  { item: 'madeEffectiveDate', label: 'Data de efetivação', show: true, blocked: true },
  { item: 'installments', label: 'Parcelas', show: true },
  { item: 'installments_paid', label: 'Parcelas pagas', show: true },
  { item: 'installments_delayed', label: 'Parcelas atrasadas', show: true },
  { item: 'delayed_days', label: 'Atraso em dias', show: true },
  { item: 'released', label: 'Curso liberado', show: true },
  { item: 'nextPayment', label: 'Próximo pagamento', show: true, blocked: true },
  { item: 'installment_value', label: 'Valor da parcela', show: true, blocked: true },
  { item: 'value', label: 'Valor de venda', show: true, blocked: true },
  { item: 'commission_provi', label: 'Comissão Provi', show: true, blocked: true },
  { item: 'commission_partner', label: 'Comissão', show: true, blocked: true },
  { item: 'total_amount_receivable', label: 'Valor total a receber', show: true, blocked: true },
  { item: 'total_amount_received', label: 'Valor total recebido', show: true, blocked: true },
  { item: 'tedDate', label: 'Pagamento a vista Provi', show: true, blocked: true },
  { item: 'agreementDate', label: 'Data de acordo', show: true, blocked: false },
  { item: 'expectedTransferDate', label: 'Data prevista repasse ', show: true, blocked: false },
]

const initial = [
  { item: 'id', label: 'ID', show: true, blocked: true },
  {
    item: 'status',
    label: 'STATUS',
    show: true,
    blocked: true,
    showSelect: false,
    isFilter: true,
    isHelper: true,
  },
  {
    item: 'hostingStatus',
    label: 'Acesso à hospedagem',
    show: true,
    blocked: true,
  },
  {
    item: 'productType',
    label: 'Método de pagamento',
    show: true,
    isFilter: true,
    showSelect: false,
  },
  { item: 'cpf', label: 'CPF', show: true, blocked: true },
  { item: 'name', label: 'Nome', show: true, blocked: true },
  { item: 'email', label: 'Email', show: true },
  { item: 'phone', label: 'Telefone', show: true },
  { item: 'campaignName', label: 'Nome da campanha', show: true, isFilter: true, showSelect: false },
  {
    item: 'products',
    label: 'Produtos',
    show: true,
    isFilter: true,
    showSelect: false,
  },
  {
    item: 'madeEffectiveDate',
    label: 'Data de efetivação',
    show: true,
    blocked: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
  },
  { item: 'installments', label: 'Parcelas', show: true },
  { item: 'installments_paid', label: 'Parcelas pagas', show: true },
  { item: 'installments_delayed', label: 'Parcelas atrasadas', show: true, isFilter: true },
  { item: 'delayed_days', label: 'Atraso em dias', show: true, isFilter: true, blocked: false },
  {
    item: 'released',
    label: 'Curso liberado',
    show: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
  },
  {
    item: 'nextPayment',
    label: 'Próximo pagamento',
    show: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
  },
  {
    item: 'agreementDate',
    label: 'Data de acordo',
    show: true,
    blocked: false,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
  },
  { item: 'installment_value', label: 'Valor da parcela', show: true, isHelper: true },
  { item: 'value', label: 'Valor de venda', show: true, blocked: true },
  { item: 'commission_provi', label: 'Comissão Provi', show: true, blocked: true },
  { item: 'commission_partner', label: 'Comissão', show: true },
  { item: 'total_amount_receivable', label: 'Valor total a receber', show: true, blocked: true, isHelper: true },
  { item: 'total_amount_received', label: 'Valor total recebido', show: true, blocked: true, isHelper: true },
  {
    item: 'expectedTransferDate',
    label: 'Data prevista repasse',
    show: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
  },
  {
    item: 'tedDate',
    label: 'Data da Transferência',
    show: true,
    isHelper: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
  },
]

/**
 * @description get initial header in table
 * @name getHeaderTableValues
 * @param {{ isMobile: boolean }} params
 * @return {typeof initial}
 */

export const getHeaderTableValues = ({ isMobile }) => {
  if (isMobile) {
    return initialMobile
  }

  return initial
}

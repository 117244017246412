import { useContext, useEffect, useState, useRef } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { getStorage } from 'utils'
import { getCoursesListService, updateCourseService } from 'services/ms-checkout/v4'

export const useListCourses = () => {
  const partnerId = getStorage({ key: 'partner-id' })
  const { isMobile, goToPage, setIsLoading, isLoading } = useContext(TemplateContext)
  const [coursesList, setCoursesList] = useState([])
  const [shareLink, setShareLink] = useState()
  const [filteredCourses, setFilteredCourses] = useState([])
  const shareLinkModalRef = useRef()

  async function getCoursesList() {
    const resultList = await getCoursesListService({ partnerId, setIsLoading })

    setCoursesList(resultList.content)
    setFilteredCourses(resultList.content)
    setShareLink(resultList.metadata && resultList.metadata.redirect_url)
  }

  async function updateCourse({ id, body }) {
    await updateCourseService({ id, partnerId, body, setIsLoading })
    await getCoursesList()
  }

  async function handleSearchCourseByName(e) {
    const { value } = e.target

    const coursesFiltered = coursesList.filter((course) => course.name.toLowerCase().search(value.toLowerCase()) !== -1)

    setFilteredCourses(coursesFiltered)
  }

  const openLinkModal = async () => {
    shareLinkModalRef.current.openModalFn()
  }

  const closeLinkModal = () => {
    shareLinkModalRef.current.closeModalFn()
  }

  useEffect(() => {
    getCoursesList()
  }, [partnerId])

  return {
    isMobile,
    goToPage,
    coursesList,
    getCoursesList,
    updateCourse,
    handleSearchCourseByName,
    filteredCourses,
    isLoading,
    shareLink,
    shareLinkModalRef,
    openLinkModal,
    closeLinkModal,
  }
}

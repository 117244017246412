import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding: 1.3rem;
  width: 100%;
  min-height: 300px;
  display: grid;
  gap: 1rem;

  ${theme.breakpoints.down('md')} {
    padding: 1rem;
  }
`
export const StyledHeader = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: ${theme.colors.bluePrimary};

  ${theme.breakpoints.down('md')} {
    font-size: 18px;
  }
`

export const StyledParagraph = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
  }
`

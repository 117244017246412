import React from 'react'
import { ButtonV3 } from '@provi/provi-components'
import { Container, StyledHeader, StyledParagraph } from './styles'

export const CommunicationCard = ({ content }) => {
  const { title, text, cta, cta_link } = content.data?.partners_cards[0]
  return (
    <>
      <Container>
        <StyledHeader>{title}</StyledHeader>
        <StyledParagraph>{text}</StyledParagraph>
        <ButtonV3
          mobileWidth={'100%'}
          text={cta}
          icon
          buttonProps={{
            href: cta_link,
            target: '_blank',
            rel: 'noopener noreferrer',
          }}
        />
      </Container>
    </>
  )
}

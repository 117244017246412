/* eslint-disable react/prop-types */
import React from 'react'

import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Input } from '~/components/v2/Input'
import { Button } from '~/components/v2/Button'
import { TextLink } from '~/components/v2/TextLink'
import { theme, showToast } from '@provi/provi-components'
import Sally from '~/assets/images/Saly-1.png'
import { UserIcon } from '~/assets/svgs/user-icon'
import { KeyIcon } from '~/assets/svgs/key-icon'
import { history } from '~/navigation/history'
import { TextErrorInput } from '~/components/text-error-input'
import { ProviLogo } from '~/assets/svgs/v2'
import { authToken } from '~/services/api'
import { asyncLocalStorage, handleLocalStorage, getStorage, removeStorage } from '~/utils'

import {
  FormContainer,
  Title,
  StyledLabel,
  Form,
  ContainerLogin,
  Section,
  ImageContainer,
  SallyImage,
  SvgWrapper,
} from './styles'

const FormWrapper = (props) => {
  const { values, errors, handleChange, touched, handleSubmit, handleBlur } = props

  return (
    <>
      <ContainerLogin>
        <SallyImage src={Sally} />
        <ImageContainer>
          <SvgWrapper>
            <ProviLogo logoColor={'white'} textColor={'white'} />
          </SvgWrapper>
        </ImageContainer>
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <Title>Login</Title>
            <Section>
              <StyledLabel>Email</StyledLabel>
              <Input
                error={errors.email && touched.email}
                autoFocus
                id="email"
                placeholder="Digite o seu email"
                icon={<UserIcon />}
                value={values.email}
                type="text"
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
              />
              {errors.email && touched.email && <TextErrorInput>{errors.email}</TextErrorInput>}
            </Section>

            <StyledLabel>Senha</StyledLabel>
            <Input
              error={errors.password && touched.password}
              placeholder="Digite a sua senha"
              id="password"
              type="password"
              onChange={handleChange('password')}
              onBlur={handleBlur('password')}
              icon={<KeyIcon />}
            />
            {errors.password && touched.password && <TextErrorInput>{errors.password}</TextErrorInput>}
            <Section>
              <TextLink color={theme.colors.blue900} text="Esqueci a senha" onClick={() => history.push('/esqueci-senha')} />
            </Section>

            <Button
              icon
              id="button"
              margin="0 auto"
              backgroundDisabled={theme.colors.blue800}
              text="Avançar"
              disabled={!(values.email && values.password) || errors.email || errors.password}
              type="submit"
              onClick={handleSubmit}
            />
          </Form>
        </FormContainer>
      </ContainerLogin>
    </>
  )
}

export const FormFormik = withFormik({
  mapPropsToValues: () => {
    const email = getStorage({ key: 'email', last: true })

    return { email: email || '' }
  },
  validationSchema: () => {
    const schema = {
      email: Yup.string().trim().email('Digite um email válido').required('Preencha o campo Email'),
      password: Yup.string().required('Preencha o campo Senha'),
    }
    return Yup.object().shape(schema)
  },
  handleSubmit: async (values, { props }) => {
    props.setIsLoading(true)
    const { email, password } = values

    try {
      const response = await authToken(email && email.trim(), password)
      props.setIsLoading(false)
      if (response.status === 401) {
        if (response.data.error.message === 'Your email address is not registered') {
          showToast('Email não registrado')
        } else {
          showToast('Senha incorreta, tente novamente')
        }
        return false
      }

      const { mustChangePassword } = response.data

      if (mustChangePassword) {
        await asyncLocalStorage.setItem('email_ref', email)
        return history.push('/redefinir')
      }

      const { token = '', basicInfoComplete = true, partner = {}, id = '' } = response.data

      const { user } = await handleLocalStorage({
        token,
        email,
        partnerName: partner.name,
        partnerSlug: partner.slug,
        partnerId: partner.id,
        userId: id,
      })

      if (!basicInfoComplete) {
        await asyncLocalStorage.setItem('email_ref', email)
        return props.goToPage('atualizar-informacoes-basicas', user)
      }

      props.goToPage('home', user)
    } catch (error) {
      showToast('Houve um erro ao autenticar o usuário')
      await removeStorage()
      props.setIsLoading(false)
    }
  },
})(FormWrapper)

/* eslint-disable react/prop-types */
// import React from 'react'

// import * as Yup from 'yup'
// import { withFormik } from 'formik'
// import { Input } from '~/components/v2/Input'
// import { Button } from '~/components/v2/Button'
// import { TextLink } from '~/components/v2/TextLink'
// import { theme, showToast } from '@provi/provi-components'
// import Sally from '~/assets/images/Saly-1.png'
// import { UserIcon } from '~/assets/svgs/user-icon'
// import { KeyIcon } from '~/assets/svgs/key-icon'
// import { history } from '~/navigation/history'
// import { TextErrorInput } from '~/components/text-error-input'
// import { LogoProviWhite } from '~/assets/svgs/logo-provi-white'
// import { authToken } from '~/services/api'
// import { asyncLocalStorage, handleLocalStorage, getStorage, removeStorage } from '~/utils'

// import { FormContainer, Title, StyledLabel, Form, ContainerLogin, Section, ImageContainer, SallyImage } from './styles'

// const FormWrapper = (props) => {
//   const { values, errors, handleChange, touched, handleSubmit, handleBlur } = props

//   return (
//     <>
//       <ContainerLogin>
//         <SallyImage src={Sally} />
//         <ImageContainer>
//           <LogoProviWhite />
//         </ImageContainer>
//         <FormContainer>
//           <Form onSubmit={handleSubmit}>
//             <Title>Login</Title>
//             <Section>
//               <StyledLabel>Email</StyledLabel>
//               <Input
//                 error={errors.email && touched.email}
//                 autoFocus
//                 id="email"
//                 placeholder="Digite o seu email"
//                 icon={<UserIcon />}
//                 value={values.email}
//                 type="text"
//                 onChange={handleChange('email')}
//                 onBlur={handleBlur('email')}
//               />
//               {errors.email && touched.email && <TextErrorInput>{errors.email}</TextErrorInput>}
//             </Section>

//             <StyledLabel>Senha</StyledLabel>
//             <Input
//               error={errors.password && touched.password}
//               placeholder="Digite a sua senha"
//               id="password"
//               type="password"
//               onChange={handleChange('password')}
//               onBlur={handleBlur('password')}
//               icon={<KeyIcon />}
//             />
//             {errors.password && touched.password && <TextErrorInput>{errors.password}</TextErrorInput>}
//             <Section>
//               <TextLink color={theme.colors.blue900} text="Esqueci a senha" onClick={() => history.push('/esqueci-senha')} />
//             </Section>

//             <Button
//               icon
//               id="button"
//               margin="0 auto"
//               backgroundDisabled={theme.colors.blue800}
//               text="Avançar"
//               disabled={!(values.email && values.password) || errors.email || errors.password}
//               type="submit"
//               onClick={handleSubmit}
//             />
//           </Form>
//         </FormContainer>
//       </ContainerLogin>
//     </>
//   )
// }

// export const FormFormik = withFormik({
//   mapPropsToValues: () => {
//     const email = getStorage({ key: 'email', last: true })

//     return { email: email || '' }
//   },
//   validationSchema: () => {
//     const schema = {
//       email: Yup.string().trim().email('Digite um email válido').required('Preencha o campo Email'),
//       password: Yup.string().required('Preencha o campo Senha'),
//     }
//     return Yup.object().shape(schema)
//   },
//   handleSubmit: async (values, { props }) => {
//     props.setIsLoading(true)
//     const { email, password } = values

//     try {
//       const response = await authToken(email && email.trim(), password)
//       props.setIsLoading(false)
//       if (response.status === 401) {
//         if (response.data.error.message === 'Your email address is not registered') {
//           showToast('Email não registrado')
//         } else {
//           showToast('Senha incorreta, tente novamente')
//         }
//         return false
//       }

//       const { mustChangePassword } = response.data

//       if (mustChangePassword) {
//         await asyncLocalStorage.setItem('email_ref', email)
//         return history.push('/redefinir')
//       }

//       const { token = '', basicInfoComplete = true, partner = {}, id = '' } = response.data

//       const { user } = await handleLocalStorage({
//         token,
//         email,
//         partnerName: partner.name,
//         partnerSlug: partner.slug,
//         partnerId: partner.id,
//         userId: id,
//       })

//       if (!basicInfoComplete) {
//         await asyncLocalStorage.setItem('email_ref', email)
//         return history.push('/atualizar-informacoes-basicas')
//       }

//       history.replace(`/home?u=${user}`)
//       props.setCurrentUser(user)
//     } catch (error) {
//       showToast('Houve um erro ao autenticar o usuário')
//       await removeStorage()
//       props.setIsLoading(false)
//     } finally {
//       props.setIsLoading(false)
//     }
//   },
// })(FormWrapper)

import React from 'react'
import { BaseTypography } from '~/globalStyles'
import { TextGrids } from '../../styles'
import { theme } from '@provi/provi-components'
import { MiddleSection } from '../index'

export const EachCondition = ({ isMobile, conditionTitle, openFinancingModal, simulations }) => {
  return (
    <TextGrids gap={'0.5rem'}>
      <BaseTypography fontWeight={600} color={theme.colors.bluePrimary}>
        {conditionTitle}
      </BaseTypography>
      <MiddleSection isMobile={isMobile} values={simulations?.values} />
      <BaseTypography
        fontSize={'13px'}
        lineHeight={'20px'}
        fontWeight={600}
        color={theme.colors.bluePrimary}
        onClick={openFinancingModal}
        cursor={'pointer'}
      >
        Ver condições
      </BaseTypography>
    </TextGrids>
  )
}

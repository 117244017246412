import React from 'react'
import { useParams } from 'react-router-dom'
import { useAddClassroomHook } from './hooks'
import { Form, Container } from './styles'
import { ButtonV3 as Button } from '@provi/provi-components'
import { CoursesBreadCrumbs } from '../../../'
import { ClassroomNameInput } from './components/ClassroomNameInput'
import { ReleaseSubjectInput } from './components/ReleaseSubjectInput'
import { ClassTypeInput } from './components/ClassTypeInput'
import { ClassroomPriceInput } from './components/ClassroomPriceInput'
import { MakeInactiveInput } from './components/MakeInactiveInput'
import { PartnerSkuInput } from './components/PartnerSkuInput'
import { GracePeriodDaysInput } from './components/GracePeriodDaysInput'
import { AddCampusModal } from './components/AddCampusModal'
import { VisibilityInput } from './components/VisibilityInput'

function AddClassroom() {
  const { courseId } = useParams<{ courseId: string }>()

  const {
    formik,
    hasErrors,
    handleChangeClassPeriod,
    handleChangeClassType,
    handleChangeRelease,
    deadlineSelectOptions,
    isMobile,
    campi,
    handleMaskInputChange,
    goToPage,
    setCourseClassCopy,
    addCampusModalRef,
    openAddCampusModal,
    closeAddCampusModal,
    postAndGetCampus,
  } = useAddClassroomHook({ courseId })

  return (
    <>
      <CoursesBreadCrumbs breadCrumbsArr={[<span key={'Nova Turma'}>Nova Turma</span>]} />

      <Container>
        <Form hasErrors={hasErrors(formik.errors)} onSubmit={(el: any) => formik.handleSubmit(el)}>
          <ClassroomNameInput {...formik} />
          <ReleaseSubjectInput
            isMobile={isMobile}
            formik={formik}
            handleChangeRelease={handleChangeRelease}
            deadlineSelectOptions={deadlineSelectOptions}
          />

          <ClassroomPriceInput isMobile={isMobile} formik={formik} handleMaskInputChange={handleMaskInputChange} />

          <ClassTypeInput
            formik={formik}
            handleChangeClassType={handleChangeClassType}
            handleChangeClassPeriod={handleChangeClassPeriod}
            isMobile={isMobile}
            campi={campi}
            openAddCampusModal={openAddCampusModal}
          />

          <VisibilityInput isMobile={isMobile} formik={formik} />
          <MakeInactiveInput isMobile={isMobile} formik={formik} />
          <GracePeriodDaysInput isMobile={isMobile} formik={formik} />
          <PartnerSkuInput isMobile={isMobile} formik={formik} />

          <footer className="footer__button--actions">
            <button
              className="footer__button--cancel"
              type="button"
              onClick={() => {
                setCourseClassCopy(null)
                goToPage(`cursos/${courseId}`)
              }}
            >
              Cancelar
            </button>
            <Button
              disabled={!formik.isValid}
              text="Salvar"
              icon
              buttonProps={{
                type: 'submit',
              }}
            />
          </footer>
        </Form>
      </Container>
      <AddCampusModal
        modalRef={addCampusModalRef}
        handleSecondaryBtn={closeAddCampusModal}
        closeModalFn={closeAddCampusModal}
        handlePrimaryBtn={postAndGetCampus}
      />
    </>
  )
}

export { AddClassroom }

import React, { useState, useEffect } from 'react'

import { api } from '~/services/api'

import { CampaignProducts as OldCampaignProducts } from './old'

import { CampaignProductsV2 } from './v2'

export const CampaignProducts = () => {
  const [useV3, setUseV3] = useState(false)

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await api.get('/v4/provipay/version')

        setUseV3(response.data.content.useV3)
      } catch (error) {
        setUseV3(false)
      }
    }

    checkVersion()
  }, [])

  return <>{useV3 ? <CampaignProductsV2 /> : <OldCampaignProducts />}</>
}

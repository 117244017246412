/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { theme, ButtonV2, TextLink } from '@provi/provi-components'

import { TemplateContext } from '~/components/Template/context'
import { Modal as ModalComponent } from '~/components/Modal'

import { Text } from '~/themes/styles'
import { Section, Row } from './styles'
import { useCheckCsvHooks } from './hooks'
import { isEmpty } from 'lodash-es'

/**
 * @typedef {object} ICheckCSVProps
 * @property {(modal: string) => void} handleModal
 * @property {object} csvData
 * @property {boolean} show
 * @property {boolean} handleV2
 * @property {object} filterStatus
 * @property {any[]} selectedByUserCourses
 */
/** @type {React.FC<ICheckCSVProps>} */
export const CheckCsvModal = (props) => {
  const { handleModal, show, csvData, selectedByUserCourses, exportUserCourses = false, handleV2, filterStatus } = props
  const { setIsLoading } = useContext(TemplateContext)
  const [email, handleConfirm, handleExport] = useCheckCsvHooks(csvData, setIsLoading, handleModal, selectedByUserCourses)

  return (
    <>
      {show && csvData && (
        <ModalComponent
          isWithoutHeight
          width="630px"
          radius={5}
          onDismiss={() => handleModal('csv')}
          footer={
            <Row>
              {!isEmpty(filterStatus) ? (
                <ButtonV2 marginVertical={0} text="Tabela filtrada" onClick={() => handleConfirm(handleV2, filterStatus)} />
              ) : (
                <ButtonV2 marginVertical={0} text="Tabela completa" onClick={() => handleConfirm(handleV2)} />
              )}
              {exportUserCourses && (
                <>
                  {selectedByUserCourses && selectedByUserCourses.length === 1 ? (
                    <TextLink
                      color={theme.colors.pink900}
                      text="Tabela de liberação de curso"
                      onClick={() => handleExport(handleV2)}
                    />
                  ) : (
                    <Text mTop="25px" size="16px" lineHeight="24px" align="left" color={theme.colors.pink900}>
                      Você precisa selecionar exatamente UM produto para poder exportar a tabela de liberação de curso
                    </Text>
                  )}
                </>
              )}
            </Row>
          }
        >
          <Section>
            <Text size="20px" lineHeight="30px" bold align="left" color={theme.colors.blue900}>
              Confirma o envio dessa tabela nos formatos .xlsx e .csv {email && 'para o email'}{' '}
              {email && (
                <Text size="20px" lineHeight="30px" bold align="left" color={theme.colors.pink900} display="inline-block">
                  {email}?
                </Text>
              )}
            </Text>
            <Text mTop="25px" size="16px" lineHeight="24px" align="left" color={theme.colors.blue900}>
              O email pode demorar até 10 minutos para aparecer na sua caixa de entrada. Qualquer problema, entre em contato com
              a Provi.
            </Text>
          </Section>
        </ModalComponent>
      )}
    </>
  )
}

import React from 'react'
import { ChargebackIcon, ChargebackNotificationIcon } from '~/assets/svgs/chargeback-icon'
import { FinancialIcon } from '~/assets/svgs/financial-icon'
import { ReceiptIcon } from '~/assets/svgs/receipt-icon'
import { DashboardIcon } from '~/assets/svgs/dashboard-icon'
import { AccessIcon } from '~/assets/svgs/access-icon'
import { LogoutIcon } from '~/assets/svgs/logout-icon'
import { DrawerQuestionMarkIcon } from '~/assets/svgs/drawer-question-mark'
import { ProfileIcon } from '~/assets/svgs/profile-icon'
import { IntegrationsIcon, DeveloperIcon } from '~/assets/svgs/v2'

const navigateToExternalLink = (link) => window.open(link)

export const buildTabs = ({ goToPage, handleLogoutModal, accessData }) => {
  const juniorTabs = [
    {
      mainTab: 'sales',
      title: 'Vendas',
      Icon: ReceiptIcon,
      position: 2,
      internalTabs: [
        {
          title: 'Cursos',
          active: false,
          route: '/cursos',
          minAccessLevel: 'junior',
          action: () => goToPage('cursos'),
        },
        {
          title: 'Venda exclusiva',
          active: false,
          route: '/checkout/selecionar-produtos',
          minAccessLevel: 'junior',
          action: () => goToPage('checkout/selecionar-produtos'),
        },
        {
          title: 'Campanhas',
          active: false,
          route: '/campanha/listar-campanhas',
          minAccessLevel: 'junior',
          action: () => goToPage('campanha/listar-campanhas'),
        },
        {
          title: 'Gerenciar vendas',
          active: false,
          route: '/checkout/listar-checkouts',
          minAccessLevel: 'junior',
          action: () => goToPage('checkout/listar-checkouts'),
        },
      ],
    },
    {
      mainTab: 'faq',
      position: 12,
      title: 'FAQ',
      Icon: DrawerQuestionMarkIcon,
      internalTabs: [],
      minAccessLevel: 'junior',
      action: () => navigateToExternalLink('https://parceiros.provi.com.br'),
    },
  ]

  const plenoTabs = [...juniorTabs]

  const seniorTabs = [
    ...plenoTabs,
    {
      mainTab: 'dashboard',
      position: 6,
      title: 'Dashboard',
      Icon: DashboardIcon,
      divide: true,
      minAccessLevel: 'senior',
      internalTabs: [
        {
          title: 'Geral',
          active: false,
          route: '/dashboard/geral',
          action: () => goToPage('dashboard/geral'),
        },
        // {
        //   title: 'Fluxo financeiro',
        //   active: false,
        //   route: '/dashboard/fluxo-financeiro',
        //   action: () => goToPage('dashboard/fluxo-financeiro'),
        // },
        {
          title: 'Funil de vendas',
          active: false,
          route: '/dashboard/funil-de-vendas',
          action: () => goToPage('dashboard/funil-de-vendas'),
        },
        {
          title: 'Carteira',
          active: false,
          show: accessData && accessData.enabledMaas,
          route: '/dashboard/carteira',
          action: () => goToPage('dashboard/carteira'),
        },
      ],
    },
  ]

  const adminTabs = [
    ...seniorTabs,
    {
      mainTab: 'financial',
      position: 3,
      title: 'Financeiro',
      Icon: FinancialIcon,
      internalTabs: [
        {
          title: 'Recebimentos',
          active: false,
          route: '/checkout/controle-recebimentos',
          action: () => goToPage('checkout/controle-recebimentos'),
        },
        {
          title: 'Movimentações',
          active: false,
          show: accessData && accessData.enabledMaas,
          route: '/movimentacao-financeira',
          action: () => goToPage('movimentacao-financeira'),
        },
      ],
    },
    {
      mainTab: 'access',
      title: 'Acesso',
      position: 10,
      Icon: AccessIcon,
      internalTabs: [],
      divide: true,
      minAccessLevel: 'admin',
      action: () => goToPage('usuario/listar-usuarios'),
    },
    {
      mainTab: 'profile',
      title: 'Perfil',
      position: 11,
      Icon: () => <ProfileIcon notification={accessData.pendingPartnerProfileInformation} />,
      internalTabs: [],
      divide: false,
      minAccessLevel: 'admin',
      action: () => goToPage('perfil/dados-gerais'),
    },
    {
      mainTab: 'developer',
      title: 'Desenvolvimento',
      position: 9,
      Icon: DeveloperIcon,
      internalTabs: [],
      divide: false,
      minAccessLevel: 'admin',
      action: () => goToPage('desenvolvimento'),
    },
  ]

  accessData?.enabledProviPay &&
    adminTabs.push({
      mainTab: 'requests',
      title: 'Solicitações',
      position: 5,
      Icon: accessData?.pendingChargebacks ? ChargebackNotificationIcon : ChargebackIcon,
      internalTabs: [
        {
          jsx: () =>
            accessData?.pendingChargebacks ? (
              <>
                <span>Chargebacks </span>
                <span style={{ color: '#8b8b8b', fontWeight: 500, fontFamily: 'Montserrat, sans-serif' }}>
                  ({accessData?.pendingChargebacks})
                </span>
              </>
            ) : (
              <span>Chargebacks</span>
            ),
          active: false,
          route: '/solicitacoes/chargebacks',
          action: () => goToPage('solicitacoes/chargebacks'),
          minAccessLevel: 'admin',
        },
        {
          title: 'Cancelamentos',
          active: false,
          show: false,
          route: '/solicitacoes/cancelamentos',
          action: () => goToPage('solicitacoes/cancelamentos'),
          minAccessLevel: 'admin',
        },
      ],
    })

  adminTabs.push({
    mainTab: 'integrations',
    title: 'Integrações',
    position: 8,
    Icon: IntegrationsIcon,
    internalTabs: [],
    minAccessLevel: 'admin',
    action: () => goToPage('integracoes'),
  })

  const superadminTabs = [...adminTabs]

  accessData?.accessLevel === 'superadmin' &&
    superadminTabs.forEach(
      (value) =>
        value.mainTab === 'financial' &&
        value.internalTabs.push({
          title: 'Saques',
          active: false,
          route: '/transferencias',
          action: () => goToPage('transferencias'),
        }),
    )

  const fiscalTabs = [
    {
      mainTab: 'dashboard',
      position: 6,
      title: 'Dashboard',
      Icon: DashboardIcon,
      internalTabs: [],
      divide: true,
      minAccessLevel: 'senior',
      action: () => goToPage('dashboard/geral'),
    },
  ]

  const logoutTab = {
    mainTab: 'logout',
    title: 'Sair',
    position: 12,
    Icon: LogoutIcon,
    internalTabs: [],
    divide: true,
    action: () => handleLogoutModal(true),
    titleColor: 'red',
  }

  const possibleAccessTabs = {
    junior: juniorTabs,
    pleno: plenoTabs,
    senior: seniorTabs,
    admin: adminTabs,
    superadmin: superadminTabs,
    fiscal: fiscalTabs,
  }
  /* Decides which tabs to render based on the user's access level
   */
  const tab = possibleAccessTabs.hasOwnProperty(accessData?.accessLevel) ? possibleAccessTabs[accessData?.accessLevel] : [] //eslint-disable-line

  return [...tab, logoutTab].sort((a, b) => a.position - b.position)
}

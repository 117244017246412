import React, { useState } from 'react'
import { Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { Container, TransactionTitle, TransactionPrice, Column, QuestionIcon, InvisibleButton } from './styles'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}))

export const TransactionCard = ({ available, title, amount, children }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  return available ? (
    <Container available={available}>
      <TransactionTitle>{title}</TransactionTitle>
      <TransactionPrice>
        {amount}
        {children}
      </TransactionPrice>
    </Container>
  ) : (
    <Container available={available}>
      <Column>
        <TransactionTitle>{title}</TransactionTitle>
        <TransactionPrice>
          {amount}
          {children}
        </TransactionPrice>
      </Column>
      <Column>
        <InvisibleButton onClick={handleClick}>
          <QuestionIcon />
        </InvisibleButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <Typography className={classes.typography}>É o saldo que ainda está em processamento</Typography>
        </Popover>
      </Column>
    </Container>
  )
}

import React from 'react'
import { TemplateContext } from '~/components/Template/context'
import { postSimulation, getPartnerDetails } from '~/services/api'
import { Big } from 'big.js'
import { formatValue } from '~/utils'

export const useFinancingConditions = ({ classroomDetails }) => {
  const { isMobile } = React.useContext(TemplateContext)
  const [simulations, setSimulations] = React.useState(null)
  const [isLoadingSimulations, setIsLoadingSimulations] = React.useState(false)

  const financingModalRef = React.useRef()

  const openFinancingModal = () => {
    financingModalRef.current.openModalFn()
  }

  const closeFinancingModal = () => {
    financingModalRef.current.closeModalFn()
  }

  const getFinancingData = async () => {
    try {
      setIsLoadingSimulations(true)

      const partnerDefaultsResponse = await getPartnerDetails()

      if (partnerDefaultsResponse.status > 199 && partnerDefaultsResponse.status < 300) {
        const { defaultAbsoluteUpfrontValue, defaultPercentualUpfrontValue } = partnerDefaultsResponse.data

        let upfrontAmount = 0
        if (defaultAbsoluteUpfrontValue) {
          upfrontAmount = Big(defaultAbsoluteUpfrontValue).times(100)
        } else if (defaultPercentualUpfrontValue) {
          upfrontAmount = Big(classroomDetails.content.priceInCents).times(Big(defaultPercentualUpfrontValue).div(100))
        }

        await getSimulations(Number(upfrontAmount))
      }
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoadingSimulations(false)
    }
  }

  const getSimulations = async (calculatedUpFrontAmmount) => {
    try {
      const simulationsResponse = await postSimulation({
        amountInCents: classroomDetails.content.priceInCents,
        coursesIds: [classroomDetails.content.Course.id],
        upfrontInCents: calculatedUpFrontAmmount,
      })

      if (simulationsResponse.status > 199 && simulationsResponse.status < 300) {
        setSimulations(simulationsResponse.data)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  React.useEffect(() => {
    if (classroomDetails) {
      getFinancingData()
    }
  }, [classroomDetails])

  const simulationsCopyText = React.useMemo(() => {
    return `Valor da venda: ${formatValue(simulations?.values?.finalTotal, true)}
Entrada: ${formatValue(simulations?.values?.upfrontAmount, true)}
Parcelado: ${formatValue(simulations?.values?.installmentAmount, true)}

${
  !!simulations?.options?.length &&
  simulations.options
    .map(
      (eachObj, index) =>
        `Opção ${index + 1}: Entrada + ${eachObj.installmentsToApply}x de ${formatValue(eachObj.pricePerInstallment, true)} ${
          eachObj.monthlyInterest ? `(${eachObj.monthlyInterest}% a.m.)` : '(sem juros)'
        }\n`,
    )
    .join('')
}`
  }, [simulations])

  return {
    isMobile,
    financingModalRef,
    openFinancingModal,
    closeFinancingModal,
    simulations,
    isLoadingSimulations,
    simulationsCopyText,
  }
}

const initialMobile = [
  { item: 'id', label: 'ID', show: true },
  { item: 'cpf', label: 'CPF', show: true },
  { item: 'name', label: 'Nome', show: true },
  { item: 'email', label: 'E-mail', show: true },
  { item: 'phone', label: 'Telefone', show: true },
  { item: 'products', label: 'Produto', show: true },
  { item: 'installmentType', label: 'Tipo da parcela', show: true },
  { item: 'paidWith', label: 'Pago com', show: true },
  { item: 'installmentValue', label: 'Valor da parcela', show: true },
  { item: 'userPaidValue', label: 'Valor Pago Aluno', show: true },
  { item: 'proviComission', label: 'Comissão da Provi', show: true },
  { item: 'proviPercentComission', label: 'Comissão', show: true },
  { item: 'receivedValue', label: 'Valor recebido', show: true },
  { item: 'madeEffectiveDate', label: 'Data de efetivação', show: true },
  { item: 'dueDate', label: 'Vencimento', show: true },
  { item: 'userPaymentDate', label: 'Data pagamento aluno', show: true },
  { item: 'expectedCompensationDate', label: 'Saldo disponível em', show: true },
  { item: 'compensationDate', label: 'Saldo sacado em', show: true },
]

const initial = [
  { item: 'id', label: 'ID', show: true },
  { item: 'cpf', label: 'CPF', show: true },
  { item: 'name', label: 'Nome', show: true },
  { item: 'email', label: 'E-mail', show: true },
  { item: 'phone', label: 'Telefone', show: true },
  { item: 'products', label: 'Produto', show: true, isFilter: true },
  { item: 'installmentType', label: 'Tipo da parcela', show: true },
  { item: 'paidWith', label: 'Pago com', show: true, isFilter: true },
  { item: 'installmentValue', label: 'Valor da(s) parcela(s)', show: true },
  { item: 'userPaidValue', label: 'Valor Pago Aluno', show: true },
  { item: 'proviComission', label: 'Comissão da Provi', show: true },
  { item: 'proviPercentComission', label: 'Comissão', show: true },
  { item: 'receivedValue', label: 'Valor recebido', show: true },
  { item: 'madeEffectiveDate', label: 'Data de efetivação', show: true },
  { item: 'dueDate', label: 'Vencimento', show: true },
  { item: 'userPaymentDate', label: 'Data pagamento aluno', show: true },
  {
    item: 'expectedCompensationDate',
    label: 'Previsão saldo disponível',
    show: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
    isHelper: true,
  },
  {
    item: 'compensationDate',
    label: 'Saque realizado em',
    show: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
    isHelper: true,
  },
]

/**
 * @description get initial header in table
 * @name getHeaderTableValues
 * @param {{ isMobile: boolean }} params
 * @return {typeof initial}
 */

export const getHeaderTableValues = ({ isMobile }) => {
  if (isMobile) {
    return initialMobile
  }

  return initial
}

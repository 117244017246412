export default {
  unmask: (string) => {
    if (!string) return ''
    return string.replace(/\D/g, '')
  },
  cpf: (string) => {
    if (!string) return ''
    return string
      .replace(/\D/g, '') // Replace any non-number character for nothing
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  },
  currency: (string) => {
    let stringValue = string
    if (typeof string !== 'string') {
      stringValue = String(Math.round(Number(string)))
    }
    return stringValue.replace(/\D/g, '') !== ''
      ? (parseInt(stringValue.replace(/\D/g, ''), 10) / 100).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        })
      : 'R$ 0,00'
  },
}

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div``

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 35px;

  border-radius: 5px;
  border: 1px solid ${({ color }) => color || theme.colors.grey600};
  background-color: ${({ color }) => color || theme.colors.grey600};
`

import React from 'react'
import { Link } from 'react-router-dom'
import { Tags, ActionButton } from '~/components/v2/TableV2/components'
import { getActiveUser, parseThenFormatDate } from '~/utils'
import {
  masks,
  formatCancellationStatus,
  formatCancellationActionBtn,
  handleGoToDetailsPage,
  formatCancellationWarranty,
  fallbackString,
  handleMobileToggle,
  StyledMobileSpan,
} from '~/screens/requests/helpers'

export const cancellationsColumns = [
  {
    Header: 'Status escola',
    accessor: 'schoolStatus',
    Cell: ({ value, defaultColumn, row }) => (
      <ActionButton
        text={formatCancellationActionBtn(value).btnText}
        disabled={formatCancellationActionBtn(value).disableBool}
        onClick={() => handleGoToDetailsPage(defaultColumn, row.original, true)}
      />
    ),
    disableFilters: false,
  },
  {
    Header: 'Prazo para resposta',
    accessor: 'responseDeadline',
    Cell: ({ value, row, defaultColumn }) => (
      <StyledMobileSpan onClick={() => handleMobileToggle(row, defaultColumn)}>
        {parseThenFormatDate(value) || fallbackString}
      </StyledMobileSpan>
    ),
  },
  {
    Header: 'Status da solicitação',
    accessor: 'status',
    Cell: ({ value, row, defaultColumn }) => {
      const { status, statusColor, textColor } = formatCancellationStatus(value)
      return (
        <span onClick={() => handleMobileToggle(row, defaultColumn)}>
          <Tags value={status} color={statusColor} textColor={textColor} />
        </span>
      )
    },
    disableFilters: false,
  },
  {
    Header: 'Prazo de garantia',
    accessor: 'warrantyDeadlineStatus',
    Cell: ({ value }) => formatCancellationWarranty(value),
    disableFilters: false,
  },
  {
    Header: 'ID',
    accessor: 'CreditRequestId',
    Cell: ({ value }) => (
      <Link
        to={`/detalhes/${value}?u=${getActiveUser()}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ fontWeight: 'bold', cursor: 'pointer', color: '#414141' }}
      >
        {value || fallbackString}
      </Link>
    ),
  },
  {
    Header: 'CPF',
    accessor: 'cpf',
    Cell: ({ value }) => masks.cpf(value) || fallbackString,
  },
  {
    Header: 'Motivo',
    accessor: 'comment',
    Cell: ({ value }) => value || fallbackString,
  },
  {
    Header: 'Data da solicitação',
    accessor: 'requestDate',
    Cell: ({ value }) => parseThenFormatDate(value) || fallbackString,
  },
]

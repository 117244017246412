import React, { useContext, useEffect } from 'react'

import { useAddCourse } from './hooks'
import { InputFieldV3 as Input, SelectorV3 as Select, ButtonV3 as Button } from '@provi/provi-components'
import { TemplateContext } from '~/components/Template/context'
import { LenghtSpan } from './components'
import { InputGroup, Footer, TextInputError } from './styles'
import { TextSwitch, CoursesBreadCrumbs } from '..'
import { AttentionIcon } from '~/assets/svgs/v2'

export const AddCourse = () => {
  const { isMobile, setIsLoading } = useContext(TemplateContext)

  useEffect(() => {
    setCourseArea()
  }, [])

  const {
    formik,
    hasErrors,
    courseAreaOptions,
    courseSpecialtyOptions,
    setCourseArea,
    handleCourseArea,
    handleMicroArea,
    handleMaskInputChange,
    handleResetForm,
  } = useAddCourse({ setIsLoading })

  return (
    <>
      <CoursesBreadCrumbs breadCrumbsArr={[<span key={'Novo Curso'}>Novo Curso</span>]} />
      <InputGroup>
        <Input
          width="100%"
          label="Nome"
          placeholder="Digite um nome para o seu curso"
          name="courseName"
          maxLength="68"
          value={formik.values.courseName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <LenghtSpan length={formik.values.courseName.length} lengthLimit={68} />
        {formik.errors.courseName && formik.touched.courseName && <TextInputError>{formik.errors.courseName}</TextInputError>}
      </InputGroup>
      <InputGroup hasMany isMobile={isMobile}>
        <Select
          className="groupSelect"
          classNamePrefix="groupSelect"
          options={courseAreaOptions}
          label="Área"
          placeholder="Selecione uma área"
          value={formik.values.courseArea}
          onChange={handleCourseArea}
        />
        <Select
          className="groupSelect"
          classNamePrefix="groupSelect"
          options={courseSpecialtyOptions}
          label="Especialidade"
          placeholder="Selecione uma especialidade"
          value={formik.values.courseSpecialty}
          onChange={handleMicroArea}
        />
      </InputGroup>
      <InputGroup
        hasErrors={
          (formik.errors.courseWorkload && formik.touched.courseWorkload) ||
          (formik.touched.courseValue && formik.errors.courseValue)
        }
        hasMany
        isMobile={isMobile}
        className={`input__group-initial ${
          !isMobile && formik.touched.courseValue && formik.errors.courseValue && `input__error-value`
        } ${!isMobile && formik.touched.courseWorkload && formik.values.courseWorkload && `input__error-workload`}`}
      >
        <div
          className={`input__with-error input__with--subMessage ${
            !isMobile && formik.touched.courseValue && formik.errors.courseValue && `input__with-error-value`
          } ${!isMobile && formik.errors.courseWorkload && formik.touched.courseWorkload && `input__with-error-workload`}`}
        >
          <label htmlFor="courseValue">Preço padrão do curso</label>
          <span>Esse valor pode ser alterado de acordo com a turma.</span>
          <Input
            onBlur={() => formik.setFieldTouched('courseValue', true)}
            value={formik.values.courseValueFormatted}
            placeholder="R$"
            name="courseValue"
            onChange={handleMaskInputChange}
          />
          {formik.errors.courseValue && formik.touched.courseValue && (
            <TextInputError>{formik.errors.courseValue}</TextInputError>
          )}
        </div>
        <div
          className={`input__with-error ${
            !isMobile && formik.errors.courseWorkload && formik.touched.courseWorkload && `input__with-error-workload`
          }`}
        >
          <Input
            mask="9999999999999"
            label="Carga horária"
            placeholder="Carga horária total do curso em horas"
            value={formik.values.courseWorkload}
            name="courseWorkload"
            onBlur={() => formik.setFieldTouched('courseWorkload', true)}
            onChange={formik.handleChange}
          />
          {formik.errors.courseWorkload && formik.touched.courseWorkload && (
            <TextInputError>{formik.errors.courseWorkload}</TextInputError>
          )}
        </div>
      </InputGroup>
      <InputGroup isMobile={isMobile} className="input__with--disclaimer">
        <div>
          <label htmlFor="">Processo seletivo</label>
          <span>Há uma etapa de seleção para admissão de alunos no curso?</span>
        </div>
        <TextSwitch
          leftText="Sim"
          rightText="Não"
          switchState={formik.values.hasSelectionProcess}
          toggleSwitchState={() => formik.setFieldValue('hasSelectionProcess', !formik.values.hasSelectionProcess)}
        />
      </InputGroup>
      <InputGroup isMobile={isMobile} className="input__with--disclaimer">
        <div>
          <label htmlFor="">Publicar no site Provi</label>
          <span>
            O curso ficará visível quando o marketplace da Provi estiver disponível. Curso e turmas serão listados no site e no
            link geral de vendas.
          </span>
        </div>
        <TextSwitch
          leftText="Sim"
          rightText="Não"
          switchState={formik.values.courseDisclaimer}
          toggleSwitchState={() => formik.setFieldValue('courseDisclaimer', !formik.values.courseDisclaimer)}
        />
      </InputGroup>
      <InputGroup isTextArea>
        <label forHtml="courseDescription">Descrição</label>

        <div className={`input__with--icon ${formik.errors.courseDescription && formik.touched.courseDescription && `error`}`}>
          <textarea
            onBlur={() => formik.setFieldTouched('courseDescription', true)}
            value={formik.values.courseDescription}
            placeholder="Adicione uma descrição para o seu curso. Ela é pública e será exibida no link de vendas."
            name="courseDescription"
            onChange={formik.handleChange}
            maxLength="120"
          ></textarea>
          {formik.errors.courseDescription && formik.touched.courseDescription && <AttentionIcon />}
        </div>
        <LenghtSpan length={formik.values.courseDescription.length} lengthLimit={120} />
        {formik.errors.courseDescription && formik.touched.courseDescription && (
          <TextInputError>{formik.errors.courseDescription}</TextInputError>
        )}
      </InputGroup>

      <Footer isMobile={isMobile} className="buttons__action">
        <Button className="footer__button--cancel" mobileWidth="100%" onClick={handleResetForm} text="Cancelar" />
        <Button icon mobileWidth="100%" disabled={hasErrors} onClick={formik.handleSubmit} text="Salvar" />
      </Footer>
    </>
  )
}

import React from 'react'
import { theme } from '@provi/provi-components'
import { Text } from '~/themes/styles'
import { Section, Underline } from '../styles'

export const InstallmentValueHelper = () => {
  return (
    <Section>
      <Text mBottom="5px">
        Os valores em{' '}
        <Underline color={theme.colors.pink900} underlineColor={theme.colors.pink900}>
          vermelho
        </Underline>{' '}
        correspondem à parcela(s) não paga(s) e em atraso.
      </Text>
      <Text mBottom="5px">
        Os valores em{' '}
        <Underline color={'#FC7900'} underlineColor={'#FC7900'}>
          laranja
        </Underline>{' '}
        correspondem à parcela(s) não paga(s), em atraso e alunos que possuem mais de um pagamento no mês vigente.
      </Text>
      <Text mBottom="5px">
        Os valores <Underline>sublinhados</Underline> indicam que o aluno possui mais de um pagamento no mês vigente.
      </Text>
    </Section>
  )
}

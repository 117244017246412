import { api } from 'services/api'
import {
  IDeleteCourseInterfaceParams,
  IGetCourseInterfaceParams,
  IGetCourseResponse,
  IGetCoursesListResponse,
  IGetInterfaceParams,
  IUpdateCourseInterfaceParams,
} from './@types'
import { toast } from 'react-toastify'

export async function getCoursesListService({ partnerId, setIsLoading }: IGetInterfaceParams) {
  setIsLoading(true)

  try {
    const result = await api.get<IGetCoursesListResponse>(`/v4/courses?PartnerId=${partnerId}&listAll=true`)

    return result.data
  } catch (error: any) {
    toast(error)
  } finally {
    setIsLoading(false)
  }
}

export async function getCourseService({ id, partnerId, setIsLoading }: IGetCourseInterfaceParams) {
  setIsLoading(true)

  try {
    const result = await api.get<IGetCourseResponse>(`/v4/courses/${id}?PartnerId=${partnerId}`)

    return { ...result.data }
  } catch (error: any) {
    toast(error.response.data.error.message || error.response.message || 'Houve um erro, por favor tente mais tarde!')
  } finally {
    setIsLoading(false)
  }
}

export async function updateCourseService({ id, partnerId, body, setIsLoading }: IUpdateCourseInterfaceParams) {
  setIsLoading(true)
  try {
    await api.put(`/v4/courses/${id}?PartnerId=${partnerId}`, body)
    toast('Mudança feita!')
    return true
  } catch (error: any) {
    toast(error.response.data.error.message || error.response.message || 'Houve um erro, por favor tente mais tarde!')
  } finally {
    setIsLoading(false)
  }
}

export async function deleteCourseService({ id, partnerId, setIsLoading, goToPage }: IDeleteCourseInterfaceParams) {
  setIsLoading(true)
  try {
    await api.delete(`/v4/courses/${id}?PartnerId=${partnerId}`)
    toast('Curso deletado!')
    goToPage('cursos')
    return true
  } catch (error: any) {
    toast(error.response.data.error.message || error.response.message || 'Houve um erro, por favor tente mais tarde!')
  } finally {
    setIsLoading(false)
  }
}

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'

import { getPartnerPreferences, postUpdates, getCheckoutFiltersV4, getCheckoutsV4, getAccess } from '~/services/api'
import { PopBallon } from '~/assets/svgs/popballon'
import { formatValue, formatThousand, getTranslatedStatus, getTranslatedCampaigns, unMask, parseThenFormatDate } from '~/utils'

import { formatCoursesText } from '~/components/table/helpers/formatCoursesText'

import { Origin } from '~/components/table/origin'
import { StatusBox } from '~/components/table/status-box'
import { ProductLink } from '~/components/table/product-link'

import { WrapperText, WrapperFitWidth } from './styles'

import { logger } from '~/helpers/logger'
import { getTranslattedProductType } from '~/helpers/getTranslattedProductType'
import { getTranslattedOrigin, getOriginalOriginOptions } from '~/helpers/getTranslattedOrigin'
import { getTranslattedCreator } from '~/helpers/getTranslattedCreator'

import { selectValueWithUrlFilter } from '~/helpers/tables/selectValueWithUrlFilter'

import { getQueryFilters } from '~/helpers/tables/getQueryFilters'
import { setUrlQueryFilters } from '~/helpers/tables/setQueryFilters'

import { isKeyFilterChange } from '~/helpers/tables/isKeyFilterChange'
import { checkIsBlankDateChecked } from '~/helpers/tables/checkIsBlankDateChecked'

import { getHeaderTableValues } from './helpers/getHeaderTableValues'
import { formatInitialFiltersWithUrl } from '~/helpers/tables/formatInitialFiltersWithUrl'

const defaultStatuses = ['paid', 'late', 'pending', 'made_effective']

export const useCheckoutList = (setIsLoading, isMobile) => {
  const [filterStatus, setFilterStatus] = useState()
  const [filterModal, setFilterModal] = useState(false)
  const [calcModal, setCalcModal] = useState(false)
  const [productsModal, setProductsModal] = useState(false)
  const [lastUpdateModal, setLastUpdateModal] = useState(false)
  const [csvModal, setCsvModal] = useState(false)
  const [UpdatesModal, setUpdatesModal] = useState(false)
  const [csvModalData, setCsvModalData] = useState()
  const [updatesModalData, setUpdatesModalData] = useState({})

  const [copylinkModal, setCopylinkModal] = useState(false)
  const [copyLinksData, setCopyLinksData] = useState({})

  const [calcConfig, setCalcConfig] = useState({})

  const [ProductData, setProductData] = useState({})
  const [lastUpdateData, setLastUpdateData] = useState({})
  const [selectedByUserCourses, setSelectedByUserCourses] = useState([]) //courses user selected to export

  const INITIAL_STATE = getHeaderTableValues({ isMobile })
  const [showCells, setShowCells] = useState(INITIAL_STATE)

  const [page, setPage] = useState(null)
  const [status, setStatus] = useState(null)
  const [search, setSearch] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [rows, setRows] = useState([])
  const [itemsPerPage, setItemsPerPage] = useState({ id: 1, label: '50', value: '50' })
  const [searchOptions, setSearchOptions] = useState({ id: 1, label: 'CPF', value: 'cpf' })
  const [pageIndicator, setPageIndicator] = useState('')
  const [activeBox, setActiveBox] = useState('cpf')
  const [totalItens, setTotalItens] = useState(null)
  const [totalPages, setTotalPages] = useState(null)
  const [warningMessage, setWarningMessage] = useState('')
  const [preferences, setPreferences] = useState(null)

  const [isDownloadable, setIsDownloadable] = useState(false)

  const handleCalcModal = () => {
    handleModal('calc')
  }

  const handleModal = (option, data, initialState) => {
    switch (option) {
      case 'filters':
        setFilterModal(!filterModal)
        break
      case 'calc':
        setCalcModal(!calcModal)
        break
      case 'product':
        setProductsModal(!productsModal)
        break
      case 'lastUpdate':
        setLastUpdateModal(!lastUpdateModal)
        break
      case 'copyLink':
        setCopylinkModal(!copylinkModal)
        break
      case 'csv':
        if (data) setCsvModalData(data)
        setCsvModal(!csvModal)
        break
      case 'updates':
        if (data) setUpdatesModalData({ ...data, resumeStatus: initialState })
        setUpdatesModal(!UpdatesModal)
        break

      default:
        break
    }
  }

  const handleSearch = async (e) => {
    if (e.target.value === '') {
      setSearchKey('')
    }

    if (['cpf', 'crid'].includes(activeBox)) {
      setSearch(unMask(e.target.value))
    } else {
      setSearch(e.target.value)
    }
  }

  const handleSearchKey = (e) => {
    if (e.keyCode === 13) setSearchKey(search)
  }

  const handleActiveBox = (value) => {
    setSearch('')
    setSearchKey('')
    setActiveBox(value)
  }

  const handlePageIndicator = () => {
    let indicator
    if (page === 1) {
      indicator = `1 - ${rows.length < itemsPerPage.value ? formatThousand(rows.length) : formatThousand(itemsPerPage.value)}`
    } else if (rows.length == itemsPerPage.value) {
      indicator = `${formatThousand(itemsPerPage.value * (page - 1) + 1)} - ${formatThousand(itemsPerPage.value * page)}`
    } else {
      indicator = `${formatThousand(itemsPerPage.value * (page - 1) + 1)} - ${formatThousand(
        itemsPerPage.value * page - (itemsPerPage.value - rows.length),
      )}`
    }
    setPageIndicator(indicator)
  }

  const handlePlaceholder = () => {
    let message
    switch (activeBox) {
      case 'cpf':
        message = 'Digite o CPF'
        break
      case 'name':
        message = 'Digite o nome'
        break
      case 'crid':
        message = 'Digite o id'
        break
      case 'email':
        message = 'Digite o email'
        break
      default:
        message = 'Pesquise aqui'
    }
    return message
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const queryToken = urlParams.get('page')
    const queryFilters = getQueryFilters(urlParams)
    const keysFilters = Object.keys(queryFilters)

    if (queryToken) {
      setPage(Number(queryToken))
    } else {
      setPage(1)
    }

    if (queryFilters) {
      setFilterStatus(queryFilters)
    }

    const getPreferences = async () => {
      try {
        const {
          data: {
            data: { accessLevel, partnerId: PartnerId },
          },
        } = await getAccess()

        setIsDownloadable(PartnerId === 594 ? !['junior', 'pleno', 'senior'].includes(accessLevel) : true)

        const response = await getPartnerPreferences('sales')
        const filters = await getCheckoutFiltersV4()

        if (filters && filters.data && filters.data.content && filters.data.content.status) {
          const { status: statusData, origin, courses, productType, creator, campaign: campaignName } = filters.data.content

          const cellsStatusFilter = statusData.map((item) => {
            return {
              label: getTranslatedStatus(item),
              id: item,
              active: true,
            }
          })

          const translattedOrigins = origin.map((item) => ({ origin: item, translatted: getTranslattedOrigin(item) }))
          const uniqueOrigins =
            translattedOrigins &&
            translattedOrigins.map((item) => item.translatted).filter((value, index, self) => self.indexOf(value) === index)

          const cellsOriginFilter = uniqueOrigins.map((item) => {
            return {
              label: item,
              id: getOriginalOriginOptions(item),
              active: true,
            }
          })

          const cellsCoursesFilter = courses.map((item) => {
            return {
              label: item.id ? `${item.id}-${item.name}` : item.name,
              id: item.id,
              active: true,
            }
          })

          const cellsProductTypeFilter = productType.map((item) => {
            return {
              label: getTranslattedProductType(item, true),
              id: item,
              active: true,
            }
          })

          const cellsCreatorFilter = creator.map((item) => {
            return {
              label: getTranslattedCreator(item),
              id: item,
              active: true,
            }
          })

          const cellsCampaignFilter = campaignName.map((item) => {
            return {
              label: getTranslatedCampaigns(item),
              id: item,
              active: true,
            }
          })

          const newShowCells = showCells.map((cell) => {
            if (isKeyFilterChange(keysFilters, cell.item)) {
              cell.isChanged = true
            }

            if (cell.item === 'status') {
              return formatInitialFiltersWithUrl({
                cell,
                origin: cellsStatusFilter,
                queryFilters: queryFilters.status,
              })
            }
            if (cell.item === 'origin') {
              return formatInitialFiltersWithUrl({
                cell,
                origin: cellsOriginFilter,
                queryFilters: queryFilters.origin,
              })
            }
            if (cell.item === 'products') {
              return formatInitialFiltersWithUrl({
                cell,
                origin: cellsCoursesFilter,
                queryFilters: queryFilters.products,
              })
            }

            if (cell.item === 'productType') {
              return formatInitialFiltersWithUrl({
                cell,
                origin: cellsProductTypeFilter,
                queryFilters: queryFilters.productType,
              })
            }

            if (cell.item === 'seller') {
              return formatInitialFiltersWithUrl({
                cell,
                origin: cellsCreatorFilter,
                queryFilters: queryFilters.seller,
              })
            }
            if (cell.item === 'campaignName') {
              return formatInitialFiltersWithUrl({
                cell,
                origin: cellsCampaignFilter,
                queryFilters: queryFilters.campaignName,
              })
            }

            if (cell.item === 'madeEffectiveDate') {
              return formatInitialFiltersWithUrl({
                cell,
                isDate: true,
                isBlankDate: true,
                queryFilters: {
                  startDate: queryFilters.startEffectiveDate,
                  endDate: queryFilters.endEffectiveDate,
                },
              })
            }

            return cell
          })
          setShowCells(newShowCells)
        }

        const { preferences: preferencesResponse } = response.data

        setPreferences({ ...preferencesResponse })
      } catch (error) {
        // showToast('Ocorreu um erro ao trazer suas preferencias')
        logger({ error })
      }
    }

    getPreferences()
  }, [])

  useEffect(() => {
    if (rows.length > 0) handlePageIndicator()
  }, [page, itemsPerPage, rows])

  const getDataCheckouts = async (statusFiltered) => {
    try {
      setIsLoading(true)
      setWarningMessage('')

      const response = await getCheckoutsV4({
        page: page,
        quantity: itemsPerPage.value,
        search: search,
        key: activeBox,
        filters: statusFiltered,
      })

      try {
        setTotalItens(response.data.paging.totalItems)
        setTotalPages(response.data.paging.totalPages)
      } catch (error) {
        //
      }

      if (response) {
        if (!response.data.content.length > 0) {
          setWarningMessage('Nenhuma solicitação encontrada')
        }

        const ids = response.data.content.map((data) => data.id)

        let updatesData
        if (ids && ids.length > 0) {
          const { data: responseData } = await postUpdates(ids)
          updatesData = responseData
        }

        const rowsResponse = response.data.content.map((data) => {
          const {
            CPF,
            email,
            phone,
            expirationDate,
            courses,
            resumeStatus,
            id,
            creator,
            redirectUrl,
            createdAt,
            fullName,
            installmentsToApply,
            updatedAt,
            origin,
            madeEffectiveDate,
            ProductType,
            lastUpdateHasComments,
            nextPaymentDate,
            campaignName,
            totalValue,
          } = data

          const updates = updatesData && updatesData.length && updatesData.filter((update) => update.CreditRequestId === id)

          return {
            status: (
              <StatusBox
                redirect_url={redirectUrl}
                id={id}
                price={totalValue}
                initialStatus={resumeStatus}
                handleModal={handleModal}
                handleCalcModal={handleCalcModal}
                setCalcConfig={setCalcConfig}
                setCopyLinksData={setCopyLinksData}
                coursesLength={courses.length}
                productType={ProductType}
              />
            ),
            cpf: CPF || '---',
            name: fullName ? fullName : '---',
            email: email || '---',
            phone: phone || '---',
            value: formatValue(totalValue) || '--- ',
            campaignName: campaignName || '--- ',
            createDate: createdAt ? parseThenFormatDate(createdAt) : '---',
            expirationDate: expirationDate ? parseThenFormatDate(expirationDate) : '---',
            lastUpdate:
              updatedAt && updates.length > 0 ? (
                <ProductLink
                  target="lastUpdate"
                  lastUpdate={updates}
                  setLastUpdateData={setLastUpdateData}
                  handleModal={handleModal}
                >
                  {parseThenFormatDate(updatedAt)} {lastUpdateHasComments ? <PopBallon /> : ''}
                </ProductLink>
              ) : (
                '---'
              ),
            seller: creator || '---',
            installments: installmentsToApply || '---',
            products:
              courses.length > 0 ? (
                <ProductLink target="product" handleModal={handleModal} courses={courses} setProductData={setProductData}>
                  {courses.length > 1 ? (
                    `${courses.length} produtos`
                  ) : (
                    <>
                      <WrapperText>{formatCoursesText(courses[0])}</WrapperText>
                    </>
                  )}
                </ProductLink>
              ) : (
                '---'
              ),
            id: id || '---',
            statusCondition: resumeStatus,
            origin: <Origin originLink={origin} id={id} />,
            madeEffectiveDate: (
              <WrapperFitWidth>{madeEffectiveDate ? parseThenFormatDate(madeEffectiveDate) : '---'}</WrapperFitWidth>
            ),
            nextPayment: nextPaymentDate ? parseThenFormatDate(nextPaymentDate) : '---',
            productType: getTranslattedProductType(ProductType),
          }
        })
        if (search && rowsResponse < 10) {
          setPage(1)
        }

        handlePageIndicator()
        setRows([...rowsResponse])
        setIsLoading(false)
      }
    } catch (error) {
      setWarningMessage('Ops, ocorreu um erro. Atualize essa página e entre em contato com a Provi se o erro permanecer.')
      console.log('deu erro no handleSearch no checkout list', error)
    }
  }

  useEffect(() => {
    if (page) {
      getDataCheckouts(filterStatus)
    }
  }, [searchKey, page, itemsPerPage, filterStatus])

  useEffect(() => {
    setUrlQueryFilters(showCells, page, filterStatus, setSelectedByUserCourses)
  }, [searchKey, page, itemsPerPage, filterStatus])

  return [
    filterModal,
    handleModal,
    lastUpdateModal,
    lastUpdateData,
    productsModal,
    showCells,
    setShowCells,
    calcModal,
    ProductData,
    setProductData,
    handleSearch,
    search,
    setRows,
    rows,
    pageIndicator,
    itemsPerPage,
    page,
    setPage,
    handleSearchKey,
    activeBox,
    handleActiveBox,
    handlePlaceholder,
    totalItens,
    totalPages,
    setItemsPerPage,
    setPageIndicator,
    warningMessage,
    preferences,
    copylinkModal,
    copyLinksData,
    csvModal,
    csvModalData,
    getDataCheckouts,
    setFilterStatus,
    UpdatesModal,
    updatesModalData,
    selectedByUserCourses,
    calcConfig,
    handleCalcModal,
    isDownloadable,
    filterStatus,
    searchOptions,
    setSearchOptions,
  ]
}

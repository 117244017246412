export const getQueryFilters = (urlParams) => {
  const queryFilters = {}

  const queryTokenStatus = urlParams.get('status')
  if (queryTokenStatus) queryFilters.status = queryTokenStatus.split(',')

  const queryTokenOrigin = urlParams.get('origin')
  if (queryTokenOrigin) queryFilters.origin = queryTokenOrigin.split(',')

  const queryTokenProducts = urlParams.get('products')
  if (queryTokenProducts) queryFilters.products = queryTokenProducts.split(',')

  const queryTokenProductType = urlParams.get('productType')
  if (queryTokenProductType) queryFilters.productType = queryTokenProductType.split(',')

  const queryTokenPaidWith = urlParams.get('paidWith')
  if (queryTokenPaidWith) queryFilters.paidWith = queryTokenPaidWith.split(',')

  const queryTokenDelayedDays = urlParams.get('delayed_days')
  if (queryTokenDelayedDays) queryFilters.delayed_days = queryTokenDelayedDays.split(',')

  const queryTokenSeller = urlParams.get('seller')
  if (queryTokenSeller) queryFilters.seller = queryTokenSeller.split(',')

  const queryTokenDelayedInstallments = urlParams.get('installments_delayed')
  if (queryTokenDelayedInstallments) queryFilters.installments_delayed = queryTokenDelayedInstallments.split(',')

  // create date
  const queryTokenCreateDateStart = urlParams.get('createDate_start')
  if (queryTokenCreateDateStart) queryFilters.startDate = queryTokenCreateDateStart

  const queryTokenCreateDateEnd = urlParams.get('createDate_end')
  if (queryTokenCreateDateEnd) queryFilters.endDate = queryTokenCreateDateEnd

  // made effective date
  const queryTokenEffectiveDateStart = urlParams.get('madeEffectiveDate_start')
  if (queryTokenEffectiveDateStart) queryFilters.startEffectiveDate = queryTokenEffectiveDateStart

  const queryTokenEffectiveDateEnd = urlParams.get('madeEffectiveDate_end')
  if (queryTokenEffectiveDateEnd) queryFilters.endEffectiveDate = queryTokenEffectiveDateEnd

  // date Prev ted
  const queryTokenDatePrevTedStart = urlParams.get('datePrevToted_start')
  if (queryTokenDatePrevTedStart) queryFilters.startExpectedPaymentDate = queryTokenDatePrevTedStart

  const queryTokenDatePrevTedEnd = urlParams.get('datePrevToted_end')
  if (queryTokenDatePrevTedEnd) queryFilters.endExpectedPaymentDate = queryTokenDatePrevTedEnd

  // expected compensation date
  const queryTokenStartExpectedCompensationDate = urlParams.get('expectedCompensationDate_start')
  if (queryTokenStartExpectedCompensationDate)
    queryFilters.startExpectedCompensationDate = queryTokenStartExpectedCompensationDate

  const queryTokenEndExpectedCompensationDate = urlParams.get('expectedCompensationDate_end')
  if (queryTokenEndExpectedCompensationDate) queryFilters.endExpectedCompensationDate = queryTokenEndExpectedCompensationDate

  // compensation date

  const queryTokenStartCompensationDate = urlParams.get('compensationDate_start')
  if (queryTokenStartCompensationDate) queryFilters.startCompensationDate = queryTokenStartCompensationDate

  const queryTokenEndCompensationDate = urlParams.get('compensationDate_end')
  if (queryTokenEndCompensationDate) queryFilters.endCompensationDate = queryTokenEndCompensationDate

  // ted date
  const queryTokenTedDateStart = urlParams.get('tedDate_start')
  if (queryTokenTedDateStart) queryFilters.startTedDate = queryTokenTedDateStart

  const queryTokenTedDateEnd = urlParams.get('tedDate_end')
  if (queryTokenTedDateEnd) queryFilters.endTedDate = queryTokenTedDateEnd

  // released date
  const queryTokenReleaserStart = urlParams.get('released_start')
  if (queryTokenReleaserStart) queryFilters.startReleasedDate = queryTokenReleaserStart

  const queryTokenReleaserEnd = urlParams.get('released_end')
  if (queryTokenReleaserEnd) queryFilters.endReleasedDate = queryTokenReleaserEnd

  // next Payment date
  const queryTokenNextPaymentStart = urlParams.get('nextPayment_start')
  if (queryTokenNextPaymentStart) queryFilters.startNextPaymentDate = queryTokenNextPaymentStart

  const queryTokenNextPaymentEnd = urlParams.get('nextPayment_end')
  if (queryTokenNextPaymentEnd) queryFilters.endNextPaymentDate = queryTokenNextPaymentEnd

  // agreement date
  const queryTokenAgreementDateStart = urlParams.get('agreementDate_start')
  if (queryTokenAgreementDateStart) queryFilters.startAgreementDate = queryTokenAgreementDateStart

  const queryTokenAgreementDateEnd = urlParams.get('agreementDate_end')
  if (queryTokenAgreementDateEnd) queryFilters.endAgreementDate = queryTokenAgreementDateEnd

  // campaigns
  const queryTokenCampaign = urlParams.get('campaignName')
  if (queryTokenCampaign) queryFilters.queryTokenCampaign = queryTokenCampaign.split(',')

  return queryFilters
}

import React from 'react'
import { useParams } from 'react-router-dom'
import { theme } from '@provi/provi-components'
import { TextSwitch, CoursesBreadCrumbs, CoursesModal } from '~/screens/courses/components'
import { InformationContainer } from '~/screens/courses/components/DetailsComponents/components'
import {
  TitleAndActions,
  ClassroomExpandDetails,
  FinancingConditions,
  IntegrationsBox,
} from '~/screens/courses/components/DetailsComponents/components/ClassroomDetails/components'
import { OpenedEye, ClosedEye } from '~/assets/svgs/v2'
import { useClassroomDetails } from './hooks'
import { BaseTypography } from '~/globalStyles'
import { ClassroomDetailsGrid, SpaceBetweenContainer, FlexColumns } from './styles'
import {
  makeClassroomInvisibleModal,
  makeClassroomVisibleModal,
  deleteClassroomModal,
  shareLinkModal,
  memberkitReminderModal,
} from '~/screens/courses/constants'
import { copyString } from '~/helpers/copyAndPaste.js'

export const ClassroomDetails = () => {
  const { classroomId, courseId } = useParams()
  const {
    isMobile,
    goToPage,
    classroomDetails,
    extraBreadCrumbs,
    openModals,
    closeModals,
    editVisibilityModalRef,
    toggleVisibility,
    deleteModalRef,
    deleteSingleClassroom,
    shareLinkModalRef,
    makeContextCopy,
    memberkitReminderModalRef,
  } = useClassroomDetails({
    classroomId,
    courseId,
  })

  return (
    <>
      {classroomDetails && (
        <>
          <CoursesBreadCrumbs breadCrumbsArr={extraBreadCrumbs} />
          <ClassroomDetailsGrid>
            {!isMobile && (
              <BaseTypography textTransform={'uppercase'} color={theme.colors.bluePrimary} fontWeight={700}>
                {classroomDetails?.content?.Course?.name || '---'}
              </BaseTypography>
            )}
            <SpaceBetweenContainer>
              <TitleAndActions
                classroomDetails={classroomDetails}
                isMobile={isMobile}
                actions={{
                  edit: () => alert('editar'),
                  copy: () => makeContextCopy(),
                  share: () => openModals('share'),
                  delete: () => openModals('delete'),
                }}
              />
              <TextSwitch
                LeftIcon={OpenedEye}
                leftText={'Visível'}
                RightIcon={ClosedEye}
                rightText={'Invisível'}
                switchState={classroomDetails?.content?.visible}
                toggleSwitchState={() => openModals('editVisibility')}
              />
            </SpaceBetweenContainer>
            <SpaceBetweenContainer>
              <FlexColumns>
                <ClassroomExpandDetails isMobile={isMobile} classroomDetails={classroomDetails} />
                <FinancingConditions classroomDetails={classroomDetails} />
              </FlexColumns>

              <FlexColumns>
                <InformationContainer
                  leftProps={{ number: classroomDetails?.metadata?.sales?.waiting_signature, text: 'Aguardando assinatura' }}
                  rightProps={{ number: classroomDetails?.metadata?.sales?.waiting_payment, text: 'Aguardando pagamento' }}
                  buttonProps={{ text: 'Gerenciar vendas', onClick: () => goToPage('checkout/listar-checkouts') }}
                />
                <InformationContainer
                  leftProps={{
                    number: classroomDetails?.metadata?.receipts?.made_effective,
                    text: 'Alunos efetivados',
                    isBlue: true,
                  }}
                  rightProps={{ number: classroomDetails?.metadata?.receipts?.canceled, text: 'Alunos cancelados' }}
                  buttonProps={{ text: 'Controlar recebimentos', onClick: () => goToPage('checkout/controle-recebimentos') }}
                />
                <IntegrationsBox integrationStr={classroomDetails?.content?.hostingProvider} goToPage={goToPage} />
              </FlexColumns>
            </SpaceBetweenContainer>
          </ClassroomDetailsGrid>
          <CoursesModal
            modalRef={editVisibilityModalRef}
            closeModalFn={() => closeModals('editVisibility')}
            modalObject={classroomDetails?.content?.visible ? makeClassroomInvisibleModal : makeClassroomVisibleModal}
            handlePrimaryBtn={() => toggleVisibility(classroomDetails?.content?.visible)}
            handleSecondaryBtn={() => closeModals('editVisibility')}
          />
          <CoursesModal
            modalRef={deleteModalRef}
            closeModalFn={() => closeModals('delete')}
            modalObject={deleteClassroomModal}
            handlePrimaryBtn={() => deleteSingleClassroom()}
            handleSecondaryBtn={() => closeModals('delete')}
          />
          <CoursesModal
            modalRef={shareLinkModalRef}
            closeModalFn={() => closeModals('share')}
            modalObject={shareLinkModal}
            handlePrimaryBtn={() =>
              copyString(classroomDetails?.metadata?.redirect_url?.link, 'link', () => closeModals('share'))
            }
            handleSecondaryBtn={() =>
              copyString(shareLinkModal.onlyStrings(classroomDetails?.metadata?.redirect_url), 'linkAndText', () =>
                closeModals('share'),
              )
            }
            paragraphVariables={classroomDetails?.metadata?.redirect_url}
            headerText={'Compartilhe o link de vendas para essa turma'}
          />
          <CoursesModal
            modalRef={memberkitReminderModalRef}
            closeModalFn={() => closeModals('memberkitReminder')}
            modalObject={memberkitReminderModal}
            handlePrimaryBtn={() => goToPage('integracoes')}
            handleSecondaryBtn={() => closeModals('memberkitReminder')}
            paragraphVariables={classroomDetails?.content?.name}
          />
        </>
      )}
    </>
  )
}

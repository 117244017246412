import { useContext, useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { useLocation } from 'react-router-dom'
import { isEqual } from 'lodash'
import { getAccess, getPartnerIsEnabledOnProviPay } from '~/services/api'
import { getStorage } from '~/utils'
import { PERMISSIONS, invalidMonitoringPartnerIds } from '~/constants' // eslint-disable-line
import { logger } from '~/helpers/logger'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

/**
 * @name usePrevious The usePrevious is a hook that gives to you the previous value for a variable
 * @param {any} value The variable that you want to watch
 * @return {any | undefined} It returns the
 **/

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

/**
 * Description of the function
 * @callback UpdateFN
 * @param {object} prevDeps previous dependencies for the deps that you passed as an object
 * @param {object} actualDeps the actual value for the dependencies
 **/

/**
 * The useUpdate is a hook that runs when the dependencies update
 * @param {UpdateFN} fn A function that runs when the component updates
 * @param {object} deps A object with all the deps that you need to watch
 * @return {void}
 **/

export const useUpdate = (fn, deps = {}) => {
  const prev = usePrevious(deps)
  useEffect(() => {
    if (prev !== undefined && !isEqual(prev, deps)) fn(prev, deps)
  }, [deps, prev])
}

/**
 * @callback MountFN
 * @return {void | Promise<void>}
 */

/**
 * The useMount hook is a function that runs when the component mounts
 * @param {MountFN} fn The function that will be executed when the componentUpdates
 **/

export const useMount = (fn) => {
  useEffect(() => {
    fn()
  }, [])
}

export const usePermissions = () => {
  const { setIsLoading, goToPage, setActivePage } = useContext(TemplateContext)
  const [userAccess, setUserAccess] = useState('')
  const [partnerID, setPartnerID] = useState(0)
  const [active, setActive] = useState(false)
  const [courseName, setCourseName] = useState('')
  const [canCreateNewCrids, setCanCreateNewCrids] = useState(false)
  const [isEnabledMaas, setIsEnabledMaas] = useState(false)
  const [isPartnerOnProviPay, setIsPartnerOnProviPay] = useState(false)
  const isValidMonitoringPartner = useMemo(() => !invalidMonitoringPartnerIds.includes(partnerID), [partnerID])

  useEffect(() => {
    const getData = async () => {
      setIsLoading && setIsLoading(true)
      try {
        const access = await getAccess()
        setActive(access?.data?.data?.active || false)
        setCanCreateNewCrids(access?.data?.data?.canCreateNewCrids || false)
        setUserAccess(access?.data?.data?.accessLevel)
        setPartnerID(access?.data?.data?.partnerId || 0)
        setIsEnabledMaas(access?.data?.data?.enabledMaas)

        const { isPartnerEnabledOnProviPay, canEmitNFSe } = await getPartnerIsEnabledOnProviPay()
        const isEnabled = isPartnerEnabledOnProviPay && canEmitNFSe
        setIsPartnerOnProviPay(isEnabled)
      } catch (error) {
        logger({ error, screen: 'permissions hook' })
      } finally {
        setIsLoading && setIsLoading(false)
      }
    }

    const getCourseName = async () => {
      const PartnerName = getStorage({ key: 'partner-name' })
      if (PartnerName) {
        setCourseName(PartnerName)
      } else {
        setCourseName('Escola')
      }
    }
    getCourseName()

    getData()
  }, [])

  const handlePage = (path, page) => {
    if (page) setActivePage(page)
    goToPage(path)
  }

  const canUseCreateSales = active && PERMISSIONS.seeCreateSales[userAccess]
  const canUseCampaigns = active && PERMISSIONS.seeCampaigns[userAccess]
  const canUseSalesManagement = PERMISSIONS.seeSalesManagement[userAccess]
  const canUseMonitoringDashboard = isValidMonitoringPartner && PERMISSIONS.seeMonitoringDash[userAccess]
  const canUseFinancialControl = PERMISSIONS.seeFinantialControl[userAccess]
  const canUseUserControl = PERMISSIONS.seeUserControl[userAccess]
  const canUseCourseClass = PERMISSIONS.seeCourseClass[userAccess]
  const canUseFinancialMovement = isEnabledMaas && PERMISSIONS.seeFinancialMovement[userAccess]
  const canAccessNFS = isPartnerOnProviPay && PERMISSIONS.seeNFS[userAccess]

  const inactiveAndCantCreate = !active && !canCreateNewCrids
  const activeAndCantCreate = active && !canCreateNewCrids

  return {
    canAccessNFS,
    canUseCreateSales,
    canUseCampaigns,
    canUseSalesManagement,
    canUseMonitoringDashboard,
    canUseFinancialControl,
    canUseFinancialMovement,
    canUseUserControl,
    canUseCourseClass,
    userAccess,
    handlePage,
    active,
    canCreateNewCrids,
    inactiveAndCantCreate,
    activeAndCantCreate,
    partnerID,
    courseName,
  }
}

export const useScreenMeasure = () => {
  const [dimensions, setDimensions] = useState([window.innerWidth, window.innerHeight])

  useEffect(() => {
    window.addEventListener('resize', () => setDimensions([window.innerWidth, window.innerHeight]))
    return () => {
      window.removeEventListener('resize', () => setDimensions([window.innerWidth, window.innerHeight]))
    }
  }, [])

  return [dimensions[0] <= 350, dimensions[0] <= 800, dimensions[0] <= 1065, dimensions[0] <= 1400]
}

/**
 * @name useQuery
 * @description Makes it possible and safe to retrieve data from the url's query parameters
 * @return {string | null} Either a string containing the query parameter or null in case there is none
 **/
export const useQuery = () => new URLSearchParams(useLocation().search)

export const useToggle = (initialToggleState = false) => {
  const [toggleState, setToggleState] = useState(initialToggleState)

  const toggleFn = useCallback(() => setToggleState((previousState) => !previousState), [])

  return [toggleState, toggleFn]
}

export const useHover = () => {
  const [value, setValue] = useState(false)
  const ref = useRef(null)
  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)
  useEffect(() => {
    const node = ref.current
    if (node) {
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseout', handleMouseOut)
      return () => {
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [ref.current])
  return [ref, value]
}

export const useLayoutContext = () => {
  const { isMobile } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  return {
    isMobile,
    drawerWidth,
  }
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])
  return debouncedValue
}

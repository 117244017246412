export const controlPermissions = {
  superadmin: {
    salesManagement: true,
    createAndEditCampaing: true,
    salesVisualization: true,
    monitoringDashboard: true,
    receivesControl: true,
    acessManagement: true,
    NFEsManagement: true,
    bankingAccount: true,
  },

  admin: {
    salesManagement: true,
    createAndEditCampaing: true,
    salesVisualization: true,
    monitoringDashboard: true,
    receivesControl: true,
    acessManagement: true,
    NFEsManagement: false,
    bankingAccount: false,
  },

  senior: {
    salesManagement: true,
    createAndEditCampaing: true,
    salesVisualization: true,
    monitoringDashboard: true,
    receivesControl: false,
    acessManagement: false,
    NFEsManagement: false,
    bankingAccount: false,
  },

  pleno: {
    salesManagement: true,
    createAndEditCampaing: true,
    salesVisualization: false,
    monitoringDashboard: false,
    receivesControl: false,
    acessManagement: false,
    NFEsManagement: false,
    bankingAccount: false,
  },
  junior: {
    salesManagement: true,
    createAndEditCampaing: false,
    salesVisualization: false,
    monitoringDashboard: false,
    receivesControl: false,
    acessManagement: false,
    NFEsManagement: false,
    bankingAccount: false,
  },
  fiscal: {
    salesManagement: false,
    createAndEditCampaing: false,
    salesVisualization: false,
    monitoringDashboard: true,
    receivesControl: false,
    acessManagement: false,
    NFEsManagement: true,
    bankingAccount: false,
  },
}

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import { CheckBox, Active, WrapperOrigin, BoxButton } from './styles'
import { capitalize } from '../../../utils/index'
import { addNewUser, getAccess } from '~/services/api'
import { controlPermissions } from '../helpers/controlPermissions'

export const useAddUser = (setIsLoading, isMobile, activePage, setActivePage) => {
  const [userAccessState, setUserAccessState] = useState('')
  const [showCells, setShowCells] = useState([])
  const [rows, setRows] = useState([])
  const [warningMessage, setWarningMessage] = useState('')
  const [link, setLink] = useState(false)
  const [clicked, setClicked] = useState('')
  const [data, setData] = useState({ login: '', password: '' })
  const [value, setValue] = useState('')

  const handleValue = async (v) => {
    setValue(v)
  }

  const handleClicked = async (v) => {
    setClicked(v)
  }

  const checkAccess = async () => {
    try {
      const { data: requestData } = await getAccess()
      const { accessLevel } = requestData.data
      setUserAccessState(accessLevel)
      setClicked(accessLevel)
      let initialState = [
        { item: 'salesManagement', label: 'Criação e gerenciamento de vendas', show: true, blocked: true },
        { item: 'createAndEditCampaing', label: 'Criar e editar curso/campanha', show: true, blocked: true },
        { item: 'salesVisualization', label: 'Visualização completa de vendas', show: true, blocked: true },
        { item: 'monitoringDashboard', label: 'Dashboard de Acompanhamento', show: true, blocked: true },
        { item: 'receivesControl', label: 'Controle de recebimentos', show: true },
        { item: 'acessManagement', label: 'Gestão de acessos da plataforma', show: true, blocked: true },
      ]
      if (!isMobile) {
        initialState = [{ item: 'accessLevel', label: 'Nível de visualização', show: true, blocked: true }, ...initialState]
      }
      if (accessLevel === 'superadmin') {
        initialState = [
          ...initialState,
          { item: 'NFEsManagement', label: 'Gestão de Nota Fiscal Eletrônica', show: true, blocked: true },
          { item: 'bankingAccount', label: 'Alteração conta bancária', show: true, blocked: true },
        ]
      }
      setShowCells(initialState)
    } catch (error) {}
  }

  const generateTable = () => {
    setIsLoading(true)
    if (activePage !== 'user-control') setActivePage('user-control')
    const getData = async () => {
      const accessForSuperAdmin = ['superadmin', 'admin', 'senior', 'pleno', 'junior', 'fiscal']
      const accessForBelowSuperAdmin = ['admin', 'senior', 'pleno', 'junior']
      const tableRenderByAccess = userAccessState === 'superadmin' ? accessForSuperAdmin : accessForBelowSuperAdmin
      const rowsResponse = tableRenderByAccess.map((accessLevel) => {
        const salesManagement = controlPermissions[accessLevel].salesManagement
        const createAndEditCampaing = controlPermissions[accessLevel].createAndEditCampaing
        const salesVisualization = controlPermissions[accessLevel].salesVisualization
        const monitoringDashboard = controlPermissions[accessLevel].monitoringDashboard
        const acessManagement = controlPermissions[accessLevel].acessManagement
        const receivesControl = controlPermissions[accessLevel].receivesControl
        const NFEsManagement = controlPermissions[accessLevel].NFEsManagement
        const bankingAccount = controlPermissions[accessLevel].bankingAccount
        return {
          accessLevelString: accessLevel,
          accessLevel: (
            <BoxButton
              active={clicked === accessLevel}
              onClick={async () => {
                await handleClicked(accessLevel)
              }}
            >
              {capitalize(accessLevel)}
            </BoxButton>
          ),
          salesManagement: (
            <WrapperOrigin>
              <CheckBox>{salesManagement && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
          createAndEditCampaing: (
            <WrapperOrigin>
              <CheckBox>{createAndEditCampaing && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
          salesVisualization: (
            <WrapperOrigin>
              <CheckBox>{salesVisualization && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
          monitoringDashboard: (
            <WrapperOrigin>
              <CheckBox>{monitoringDashboard && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
          acessManagement: (
            <WrapperOrigin>
              <CheckBox>{acessManagement && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
          receivesControl: (
            <WrapperOrigin>
              <CheckBox>{receivesControl && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
          NFEsManagement: (
            <WrapperOrigin>
              <CheckBox>{NFEsManagement && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
          bankingAccount: (
            <WrapperOrigin>
              <CheckBox>{bankingAccount && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
        }
      })
      setIsLoading(false)
      setRows([...rowsResponse])
    }
    getData()
  }

  useEffect(() => {
    checkAccess()
  }, [])

  useEffect(() => {
    generateTable()
  }, [clicked])

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const response = await addNewUser(value && value.trim(), clicked)
      const { email, error } = response.data
      if (error) {
        showToast(error.message || `Erro na criação de um novo usuário`)
      } else {
        setData({ login: email })
        setLink(true)
      }
      setIsLoading(false)
    } catch (error) {
      showToast('Houve um erro na hora de cadastrar o usuário')
      setIsLoading(false)
    }
  }

  return [value, handleValue, handleSubmit, link, showCells, setRows, rows, warningMessage, data, clicked]
}

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

import { BaseGrid } from '~/globalStyles'

export const ListCoursesGrid = styled(BaseGrid)`
  gap: 1.5rem;

  ${theme.breakpoints.down('md')} {
    gap: 1rem;
  }

  .searchBar {
    margin-left: auto;

    ${theme.breakpoints.down('md')} {
      margin-left: revert;
      margin-bottom: 0.5rem;
    }
  }

  .addBtn {
    margin: 0 auto;
  }
`

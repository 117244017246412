import { useEffect, useState } from 'react'
import moment from 'moment'
import { Big } from 'big.js'
import { showToast } from '@provi/provi-components'

import { logger } from '~/helpers/logger'
import { postCheckoutsV4, getPartnerDetails, postCreditRule } from '~/services/api'
import { ERRORS } from '~/constants'
import { isValidCpf } from '~/utils/validators'

import {
  getStorage,
  formatInput,
  handlePercent,
  handleValue,
  handleDay,
  handleDate,
  removeDotsCpf,
  formatStrValue,
  formatValue,
  CurrencyMask,
  unMask,
  roundValue,
  removeItem,
  getActiveUser,
  getAbsoluteEntranceByPercent,
  getBiggerOptionThenAbsoluteAndPercentValue,
} from '~/utils'

export const useCheckout = (
  setIsLoading,
  setCurrent,
  isIE,
  isEdge,
  engineName,
  setSelectedOptions,
  setValue,
  activePage,
  setActivePage,
) => {
  const [products, setProducts] = useState([])
  const [productsValue, setProductsValue] = useState(0)
  const [checked, setChecked] = useState(false)
  const [buttonActive, setButtonActive] = useState(null)
  const [link, setLink] = useState(false)
  const [linkUrl, setLinkUrl] = useState()
  const [cpf, setCpf] = useState('')
  const [sellerId, setSellerId] = useState()
  const [obj, setObj] = useState({ value: null, percent: null })
  const [date, setDate] = useState({ days: null, date: null })

  const [cpfError, setCpfError] = useState(false)
  const [errorPercent, setErrorPercent] = useState(false)
  const [errorMaxValue, setErrorMaxValue] = useState(false)
  const [entranceError, setEntranceError] = useState('')
  const [dateError, setdateError] = useState(false)
  const [dateErrorMessage] = useState('Data de expiração inválida')

  const [entranceValue, setEntranceValue] = useState(null)
  const [modal, setModal] = useState(false)
  const [calcConfig, setCalcConfig] = useState()

  const [defaultUpFront, setDefaultAbsoluteUpFrontValue] = useState(null)
  const [defaultPercentualUpfront, setDefaultPercentualUpfrontValue] = useState(null)
  const [defaultRunningDays, setDefaultRunningDays] = useState(null)
  const [hasCheckCpf, setHasCheckCpf] = useState(false)

  const [showCpfModal, setShowCpfModal] = useState(false)
  const [showSellerInfoModal, setShowSellerInfoModal] = useState(false)

  const [requests, setRequests] = useState({
    limitRequestsDays: '',
    limitRequestsMonth: '',
    remainingRequestsDay: '',
    remainingRequestsMonth: '',
    totalRequestsDays: '',
    totalRequestsMonth: '',
  })
  const [creditRule, setCreditRule] = useState(null)
  const [preApprovedDisable, setPreApprovedDisable] = useState(false)
  const [seeInstallmentDisable, setSeeInstallmentDisable] = useState(false)

  const getSelectedCourses = async () => getStorage({ key: 'selectedOptions' })

  const getInitialData = async () => {
    const response = getStorage({ key: 'selectedOptions' })
    const responseValue = getStorage({ key: 'selectedOptionsValue' })
    const responseEmail = getStorage({ key: 'email' })

    if (responseEmail) {
      setSellerId(responseEmail)
    }

    try {
      const res = await getPartnerDetails()

      const {
        defaultDaysToUpfrontPayment,
        defaultAbsoluteUpfrontValue,
        defaultPercentualUpfrontValue,

        limitRequestsDays,
        limitRequestsMonth,
        remainingRequestsDay,
        remainingRequestsMonth,

        totalRequestsDays,
        totalRequestsMonth,

        hasRequestLimits,
      } = res.data

      setRequests({
        ...requests,
        totalRequestsDays,
        totalRequestsMonth,
        limitRequestsDays,
        limitRequestsMonth,
        remainingRequestsDay,
        remainingRequestsMonth,
      })
      setHasCheckCpf(hasRequestLimits)

      const selectedCourses = await getSelectedCourses()
      const courseAbsoluteEntrance = getBiggerCourseAbsoluteEntrance(selectedCourses)
      const coursePercentEntrance = getBiggerCoursePercentEntrance(selectedCourses)

      if (defaultDaysToUpfrontPayment) {
        setChecked(true)
        setDefaultRunningDays(defaultDaysToUpfrontPayment > 5 ? 5 : defaultDaysToUpfrontPayment)
        setButtonActive(defaultDaysToUpfrontPayment > 5 ? 5 : defaultDaysToUpfrontPayment)
      }

      if (courseAbsoluteEntrance || coursePercentEntrance) {
        const percentTotalValue = (responseValue / 100) * coursePercentEntrance

        if (courseAbsoluteEntrance && courseAbsoluteEntrance > percentTotalValue) {
          const value = Big(courseAbsoluteEntrance)
          setEntranceValue(CurrencyMask(value))
          setDefaultAbsoluteUpFrontValue(courseAbsoluteEntrance ? courseAbsoluteEntrance / 100 : null)
        } else if (coursePercentEntrance) {
          setEntranceValue(CurrencyMask(Math.ceil(responseValue / 100) * coursePercentEntrance))
          setDefaultPercentualUpfrontValue(coursePercentEntrance || null)
        }
      } else {
        if (defaultAbsoluteUpfrontValue) {
          const value = Big(defaultAbsoluteUpfrontValue).times(100)
          setEntranceValue(CurrencyMask(value))
        } else if (defaultPercentualUpfrontValue) {
          setEntranceValue(CurrencyMask(Math.ceil(responseValue / 100) * defaultPercentualUpfrontValue))
        }

        setDefaultAbsoluteUpFrontValue(defaultAbsoluteUpfrontValue)
        setDefaultPercentualUpfrontValue(defaultPercentualUpfrontValue)
      }
    } catch (error) {
      console.log('error =>', error)
    }

    setProductsValue(responseValue)
    setProducts(response)

    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    setCurrent(95, 2)

    if (activePage !== 'sell') setActivePage('sell')
    window.scrollTo(0, 0)

    getInitialData()
  }, [])

  useEffect(() => {
    const checkIfPreApprovedError =
      cpfError || errorPercent || errorMaxValue || entranceError || !cpf || (cpf && cpf.length > 0 && cpf.length < 14)
    setPreApprovedDisable(checkIfPreApprovedError)

    const checkIfInstallmentError = errorPercent || errorMaxValue || entranceError
    setSeeInstallmentDisable(checkIfInstallmentError)
  }, [cpfError, errorPercent, errorMaxValue, entranceError, cpf])

  const handleButtonDay = (option) => {
    /** REF
     0 -> vc só mostra 1
     1 -> vc só mostra 1
     2 -> vc só mostra 1,2
     3 -> vc só mostra 1,2,3
     4-> vc só mostra 1,2,3,4
     5 -> vc mostra 1,2,3,4,5
     6+ -> vc mostra 1,2,3,4,5
     */

    if (option <= defaultRunningDays || (option === 1 && defaultRunningDays === 0)) {
      setButtonActive(option)
    }
  }

  const handleValueChange = (_event) => {
    const unMaskedValue = unMask(_event.target.value)
    const _value = unMaskedValue

    if (!_value || Number(_value) === 0) {
      setObj({ value: '', percent: '' })
      setErrorMaxValue(false)
    } else {
      const valueInInput = formatInput(_value)

      const errorMax = Number(valueInInput) > Number(productsValue)
      setErrorMaxValue(errorMax)

      return handleValue({ setObj, valueInInput, productsValue })
    }
  }

  const handlePercentChange = (_event) => {
    let floatValue = _event.target.value
    floatValue = floatValue && floatValue.replace('%', '')

    if (!floatValue) {
      setObj({ value: '', percent: '' })
    } else {
      setErrorPercent(floatValue >= 100)

      return handlePercent({ setObj, percent: floatValue, productsValue })
    }
  }

  const handleDaysChange = (e) => {
    const now = moment()
    const days = e.target.value

    if (!days) {
      setDate({ days: '', date: '' })
    } else {
      return handleDay({ setDate, days, now, setdateError })
    }
  }

  const handleDateChange = (e) => {
    if (e.target.value.length === 10) {
      return handleDate({ setDate, date: e.target.value, setdateError })
    }

    if (!e.target.value) {
      setDate({ days: '', date: '' })
    } else {
      setDate({ days: 0, date: e.target.value })
    }
  }

  const getBiggerCourseAbsoluteEntrance = (courses) => {
    if (!courses || !courses.length) return null

    return (
      courses
        .map((course) => course.absoluteUpfrontValue)
        .reduce((accumulator, current) => (current > accumulator ? current : accumulator), null) * 100
    )
  }

  const getBiggerCoursePercentEntrance = (courses) => {
    if (!courses || !courses.length) return null

    return courses
      .map((course) => course.percentualUpfrontValue)
      .reduce((accumulator, current) => (current > accumulator ? current : accumulator), null)
  }

  const getBiggestCourseDefaultEntrance = (selectedCourses, courseValue) => {
    const courseAbsoluteEntrance = getBiggerCourseAbsoluteEntrance(selectedCourses)
    const coursePercentEntrance = getBiggerCoursePercentEntrance(selectedCourses)

    const entranceWithPercent = getAbsoluteEntranceByPercent(coursePercentEntrance, courseValue)
    const biggestDefaultCourseOptions = getBiggerOptionThenAbsoluteAndPercentValue(entranceWithPercent, courseAbsoluteEntrance)

    return biggestDefaultCourseOptions
  }

  const handleSubmitCheckout = async () => {
    let selectedCourses = []
    let selectedCoursesIds = []
    try {
      selectedCourses = await getSelectedCourses()
      selectedCoursesIds = selectedCourses.map((selectedOption) => ({
        id: selectedOption.id,
        courseClassId: selectedOption.courseClassId,
      }))
    } catch (error) {
      showToast(ERRORS.default)
    }

    if (!cpf || cpf.length < 14) setCpfError(true)

    try {
      const courseValue = Number(formatStrValue(unMask(obj.value)) || productsValue)
      const biggestDefaultCourseOptions = getBiggestCourseDefaultEntrance(selectedCourses, courseValue)

      const objPost = {
        cpf: removeDotsCpf(cpf),
        checkout_price_in_cents: courseValue,
        courses: selectedCoursesIds,
        consultant_id: sellerId,
      }

      if (date.days) {
        objPost.running_days_to_expire = Number(date.days)
      }

      if (checked) {
        objPost.running_days_to_first_installment = buttonActive
      }

      if (entranceValue && biggestDefaultCourseOptions) {
        objPost.upfront_amount_in_cents =
          Number(unMask(entranceValue)) > biggestDefaultCourseOptions
            ? Number(unMask(entranceValue))
            : biggestDefaultCourseOptions
      } else if (entranceValue) {
        objPost.upfront_amount_in_cents = Number(unMask(entranceValue))
      } else if (biggestDefaultCourseOptions) {
        objPost.upfront_amount_in_cents = biggestDefaultCourseOptions
      }

      if (!cpf || errorPercent || errorMaxValue || dateError || entranceError) {
        showToast('Ops, ocorreu algum erro 😔')
      } else {
        setIsLoading(true)
        const response = await postCheckoutsV4(objPost)

        const { content, error } = response.data

        if (error) {
          showToast(error.message ? error.message : 'Houve um erro na criação de link')
        } else {
          setLinkUrl(content.redirect_url)
          setLink(true)

          await removeItem({ key: 'selectedOptions', user: getActiveUser() })
          await removeItem({ key: 'selectedOptionsValue', user: getActiveUser() })
          setSelectedOptions([])
          setValue(0)
        }
      }
    } catch (error) {
      setIsLoading(false)
      showToast('Houve um erro na criação de link, tente novamente ou contate a Provi')

      logger({
        error,
        level: 'error',
        screen: 'checkout',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleFocus = (event) => event.target.select()

  const handleCpf = (e, blur = false) => {
    if (!e) return
    setCpfError(false)

    setCpf(e.target.value)
    if (e.target.value.length === 14 || blur) {
      const isValid = isValidCpf(e.target.value)
      setCpfError(!isValid)
    }
  }

  const handleSellerId = (e) => {
    if (!e) return

    setSellerId(e.target.value)
  }

  const handleEntranceChange = (e) => {
    const _value = e.target.value
    const unMaskedValue = _value && Number(unMask(_value))
    const formattedObjValue = unMask(obj.value)
    const fv = unMaskedValue

    if (!fv && fv !== 0) {
      return
    }

    setEntranceError(null)
    if ((formattedObjValue && fv > formattedObjValue) || (productsValue && fv > productsValue)) {
      setEntranceError(`O valor de entrada deve ser menor que o valor final da venda`)
    } else if (defaultUpFront && fv / 100 < Number(defaultUpFront)) {
      if (fv !== 0) setEntranceError(`O valor de entrada deve ser pelo menos ${formatValue(defaultUpFront, true)}`)
    } else if ((fv / formatStrValue(formatStrValue(formattedObjValue) || productsValue)) * 100 < defaultPercentualUpfront) {
      setEntranceError(`O valor de entrada deve ser pelo menos ${defaultPercentualUpfront}% do valor final da venda `)
    } else if (fv === (formatStrValue(formattedObjValue) || productsValue)) {
      setEntranceError(`O valor da entrada não pode ser o mesmo que o valor final da venda`)
    }

    setEntranceValue(CurrencyMask(fv))
  }

  const handleCalcModal = (option) => {
    handleModal(option, 'calc')
  }

  const handleModal = (option, location) => {
    if (location === 'calc') {
      setModal(option)
    } else if (location === 'checkCpf') {
      if (preApprovedDisable) {
        if (cpf && cpf.length > 0 && cpf.length < 14) {
          showToast('Informe o CPF para continuar')
          setCpfError(true)
          return
        }
        return
      }

      if (!option) {
        setCreditRule(null)
      }
      setShowCpfModal(option)
    } else if (location === 'seller') {
      setShowSellerInfoModal(option)
    }
  }

  const clickCalcModal = async () => {
    if (seeInstallmentDisable) {
      return
    }

    try {
      setIsLoading(true)
      const ids = products.map((product) => product.id)

      const discountInCents =
        obj.value && productsValue ? roundValue(Number(productsValue) - formatStrValue(unMask(obj.value))) : 0
      const unmaskedEntrance = roundValue(unMask(entranceValue))
      const selectedCourses = await getSelectedCourses()

      const biggestDefaultCourseOptions = getBiggestCourseDefaultEntrance(selectedCourses, productsValue - discountInCents)
      const upfrontInCents = unmaskedEntrance > biggestDefaultCourseOptions ? unmaskedEntrance : biggestDefaultCourseOptions

      setCalcConfig({
        amountInCents: Number(productsValue),
        coursesIds: ids,
        discountInCents,
        upfrontInCents: Number(upfrontInCents),
      })

      handleCalcModal(true)
    } catch (error) {
      showToast(error.message || 'Não foi possível calcular o parcelamento, por favor entre em contato com a equipe da Provi')
    }

    setIsLoading(false)
  }

  const showToastCopy = () => {
    showToast('Link copiado')
  }

  const handleCheckCpfModal = async (remainingRequestsDay, remainingRequestsMonth) => {
    setIsLoading(true)

    if (remainingRequestsDay <= 0) {
      showToast('Limite de dia excedido')
      handleModal(false, 'checkCPF')
    } else if (remainingRequestsMonth <= 0) {
      showToast('Limite do mês excedido')
      handleModal(false, 'checkCPF')
    } else {
      try {
        const ids = products.map((product) => product.id)

        const data = {
          price: formatStrValue(unMask(obj.value)) / 100 || Number(productsValue) / 100,
          CPF: removeDotsCpf(cpf),
          coursesIds: ids,
        }

        const response = await postCreditRule(data)
        const { credit_rule_tier } = response.data.response

        setRequests({
          ...requests,
          remainingRequestsDay: Number(remainingRequestsDay) - 1,
          remainingRequestsMonth: Number(remainingRequestsMonth) - 1,
        })
        setCreditRule(credit_rule_tier)
      } catch (error) {
        showToast('Houve um erro na consulta de crédito, tente novamente ou contate a Provi')
      }
    }

    setIsLoading(false)
    // retornar um feedback
  }

  const handleEntranceBlur = (isPercent = false) => {
    const unMaskedValue = obj.value && unMask(obj.value)
    if (defaultPercentualUpfront) {
      if (!isPercent) {
        setEntranceValue(CurrencyMask(Math.ceil((unMaskedValue / 100) * defaultPercentualUpfront)))
      } else {
        setEntranceValue(CurrencyMask(formatStrValue(Math.ceil((unMaskedValue / 100) * defaultPercentualUpfront))))
      }
      setEntranceError(null)
    }
  }

  return [
    checked,
    handleButtonDay,
    buttonActive,
    handleValueChange,
    handlePercentChange,
    handleDaysChange,
    handleDateChange,
    link,
    showToastCopy,
    products,
    productsValue,
    obj,
    date,
    handleFocus,
    handleSubmitCheckout,
    cpf,
    handleCpf,
    sellerId,
    handleSellerId,
    cpfError,
    dateError,
    dateErrorMessage,
    linkUrl,
    errorPercent,
    errorMaxValue,
    handleEntranceChange,
    entranceValue,
    clickCalcModal,
    modal,
    handleModal,
    entranceError,
    defaultUpFront,
    defaultPercentualUpfront,
    defaultRunningDays,
    showCpfModal,
    requests,
    handleCheckCpfModal,
    creditRule,
    showSellerInfoModal,
    handleEntranceBlur,
    hasCheckCpf,
    preApprovedDisable,
    seeInstallmentDisable,
    calcConfig,
    handleCalcModal,
  ]
}

import { useContext, useMemo } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'
import { getHomepageIntro, getCommunicationCard } from '~/services/api'
import { useQuery } from 'react-query'
import { getStorage, formatValue } from '~/utils'
import { PERMISSIONS, invalidMonitoringPartnerIds } from '~/constants'
import { toast } from 'react-toastify'

export const useHome = () => {
  const { isMobile, setIsLoading, goToPage } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)
  const partnerName = getStorage({ key: 'partner-name' })
  const partnerId = getStorage({ key: 'partner-id' })
  const userAccess = localStorage.getItem('access-level')

  const defaultStaletime = 300000

  const { data: partnerIntroduction, isFetching: isFetchingIntro } = useQuery(`${partnerName}-Introduction`, getHomepageIntro, {
    staleTime: defaultStaletime,
    onError: () => {
      toast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
    },
  })

  const { data: CommunicationCardData, isFetching: isFetchingCommunicationCard } = useQuery(
    `${partnerName}-Communication-Card`,
    getCommunicationCard,
    {
      staleTime: defaultStaletime,
      onError: () => {
        toast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
      },
    },
  )

  const isValidMonitoringPartner = useMemo(() => !invalidMonitoringPartnerIds.includes(partnerId), [partnerId])
  const canUseMonitoringDashboard = isValidMonitoringPartner && PERMISSIONS.seeMonitoringDash[userAccess]

  return {
    drawerWidth,
    isFetchingIntro,
    partnerIntroduction,
    isMobile,
    setIsLoading,
    goToPage,
    canUseMonitoringDashboard,
    formatValue,
    CommunicationCardData,
    isFetchingCommunicationCard,
  }
}

import React from 'react'
import { TemplateContext } from '../../../../../../components/Template/context'
import { CoursesContext } from '../../../../context'
import { useFormik, FormikProps } from 'formik'
import { schemaValidation } from './schema'
import { getCourse, getCampi, postCourseClass, postNewCampus } from '../../../../requests'
import { toast } from 'react-toastify'
import masks from '../../../../../../themes/masks'

export interface IInitialValues {
  classroomName: string
  beginDate?: string
  endDate?: string
  deadlineSolicitationNumber?: number
  deadlineSolicitationSelect?: string
  deadlineSolicitation?: number
  lifeTimeSpan: boolean
  internCode?: string
  campusId?: number
  priceInCentsFormatted?: string
  priceInCents: number
  expiresAt?: string
  classPeriodId?: number
  classTimeId?: number
  attendTypeId?: number
  modalityId?: number
  classTypeId?: number
  visible: boolean
}

export interface IAddCampusObj {
  name: string
  zipcode: string
  street: string
  state: string
  city: string
  district: string
  number?: string
  complement?: string
}

interface IModals {
  openModalFn: () => void
  closeModalFn: () => void
}

function useAddClassroomHook({ courseId }: any) {
  const { isMobile, goToPage, setIsLoading, accessData }: any = React.useContext(TemplateContext)
  const { courseClassCopy, setCourseClassCopy, setShowModalAfterPost } = React.useContext(CoursesContext)
  const [courseData, setCourseData]: any = React.useState(null)
  const [campi, setCampi]: any = React.useState([])
  const addCampusModalRef: React.RefObject<IModals | null> = React.useRef(null)

  const initialValues: IInitialValues = {
    classroomName: '',
    beginDate: undefined,
    endDate: undefined,
    deadlineSolicitationNumber: undefined,
    deadlineSolicitationSelect: undefined,
    deadlineSolicitation: undefined,
    lifeTimeSpan: false,
    internCode: undefined,
    campusId: undefined,
    priceInCentsFormatted: undefined,
    priceInCents: 0,
    expiresAt: undefined,
    classTypeId: undefined,
    classPeriodId: undefined,
    classTimeId: undefined,
    attendTypeId: undefined,
    modalityId: undefined,
    visible: true,
  }

  const formik: FormikProps<IInitialValues> = useFormik<IInitialValues>({
    initialValues: { ...initialValues, ...courseClassCopy },
    onSubmit: async (values) => {
      const dependantVariables: any = {}

      if (values.classTypeId === 1) {
        dependantVariables.CourseClassTypeId = 1
        dependantVariables.startsAt = values.beginDate
        dependantVariables.endsAt = values.endDate
      }

      if (values.classTypeId === 2) {
        dependantVariables.CourseClassTypeId = 2
        dependantVariables.durationTime = values.deadlineSolicitationNumber
        dependantVariables.CourseClassDurationId = values.deadlineSolicitationSelect
      }

      if (values.classTypeId === 2 && values.lifeTimeSpan) {
        dependantVariables.CourseClassTypeId = 2
        dependantVariables.CourseClassDurationId = 5
      }

      if (values.modalityId === 1) {
        dependantVariables.CourseClassModalityId = 1
        dependantVariables.CourseClassAttendTypeId = values.attendTypeId
      }

      if (values.modalityId === 2) {
        dependantVariables.CourseClassModalityId = 2
        dependantVariables.CourseClassAttendTypeId = values.attendTypeId
        dependantVariables.CampusId = values.campusId
        dependantVariables.CourseClassPeriodId = values.classPeriodId
        dependantVariables.CourseClassTimeId = values.classTimeId
      }

      if (values.modalityId === 3) {
        dependantVariables.CourseClassModalityId = 3
        dependantVariables.CourseClassAttendTypeId = 1
        dependantVariables.CampusId = values.campusId
        dependantVariables.CourseClassPeriodId = values.classPeriodId
        dependantVariables.CourseClassTimeId = values.classTimeId
      }

      const nonDependantVariables = {
        name: values.classroomName,
        CourseId: courseId,
        visible: values.visible,
      }

      const optionalVariables = {
        priceInCents: values.priceInCents,
        expiresAt: values.expiresAt && new Date(values.expiresAt),
        gracePeriodDays: values.deadlineSolicitation || 7,
        ...(values.internCode && { partnerSKU: values.internCode }),
      }

      const finalPostObject = { ...nonDependantVariables, ...optionalVariables, ...dependantVariables }

      createCourseClass(finalPostObject)
    },
    validationSchema: schemaValidation,
    validateOnChange: true,
    validateOnBlur: true,
    initialTouched: courseClassCopy,
    validate: (values) => {
      let errors: any = {}

      if (!courseData || !accessData) return errors

      if (values.priceInCents > parseInt(masks.unmask(courseData.price))) {
        errors.priceInCents = 'O valor da turma não pode ser maior do que o valor do curso'
      }

      if (values.priceInCents > 6000000 && accessData.hasProductValueLimit) {
        errors.priceInCents = 'O valor da turma não pode exceder R$ 60.000,00'
      }

      return errors
    },
  })

  const createCourseClass = async (formObj: any) => {
    try {
      setIsLoading(true)
      const response = await postCourseClass(formObj)
      if (response.status > 199 && response.status < 300) {
        toast('Turma cadastrada com sucesso!')
        const partnerHasMemberkitIntegrations = accessData?.externalIntegrations.length
        partnerHasMemberkitIntegrations && setShowModalAfterPost(true)
        setTimeout(() => {
          goToPage(`cursos/${courseId}/${response.data.content.id}`)
        }, 1500)
      }
    } catch (error: any) {
      toast(
        error.response?.data?.error?.message ||
          error.response?.data?.errors?.[0]?.message ||
          'Algo de errado aconteceu criando a sua turma 😔 Por favor, tente novamente.',
      )
    } finally {
      setIsLoading(false)
    }
  }

  const hasErrors = (formikErrors: object) => {
    return Object.keys(formikErrors).length > 0
  }

  const handleChangeRelease = (caseString: string) => {
    switch (caseString) {
      case 'releaseWithDate':
        formik.setFieldValue('classTypeId', 1)
        break
      case 'releaseAfterPurchase':
        formik.setFieldValue('classTypeId', 2)
        break
      default:
        break
    }
  }

  const handleChangeClassPeriod = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    switch (value) {
      case 'halfDay':
        formik.setFieldValue('classPeriodId', 1)
        break
      case 'fullDay':
        formik.setFieldValue('classPeriodId', 2)

        break
      default:
        break
    }
  }

  const handleChangeClassType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    switch (value) {
      case 'classTypeOnline':
        formik.setFieldValue('modalityId', 1)
        break

      case 'classTypeMixed':
        formik.setFieldValue('modalityId', 2)
        break
      case 'classTypeFaceToFace':
        formik.setFieldValue('modalityId', 3)
        break
      default:
        break
    }
  }

  const handleMaskInputChange = (e: any) => {
    const { value } = e.target
    formik.setFieldValue('priceInCentsFormatted', masks.currency(value))
    formik.setFieldValue('priceInCents', parseInt(masks.unmask(value)))
  }

  const deadlineSelectOptions = [
    {
      label: 'Ano(s)',
      value: 4,
    },
    {
      label: 'Mês/Meses',
      value: 3,
    },
    {
      label: 'Semana(s)',
      value: 2,
    },
    {
      label: 'Dia(s)',
      value: 1,
    },
  ]

  const getCourseData = async (courseIdParam: any) => {
    try {
      if (courseClassCopy) return

      setIsLoading(true)

      const response = await getCourse(courseIdParam)

      if (response.status > 199 && response.status < 300) {
        setCourseData(response.data.content)
        formik.setFieldValue('priceInCentsFormatted', masks.currency(response.data.content.price))
        formik.setFieldValue('priceInCents', parseInt(masks.unmask(response.data.content.price)))
        formik.setFieldValue('deadlineSolicitation', response.data.content.gracePeriodDays)
        formik.setFieldValue('visible', response.data.content.active)
      }
    } catch (error) {
      toast('Algo de errado aconteceu buscando suas informações 😔 Por favor, tente novamente.')
    } finally {
      setIsLoading(false)
    }
  }

  const getCampusData = async () => {
    try {
      const response = await getCampi()

      if (response.status > 199 && response.status < 300) {
        const formatResponse = response.data.rows.map((item: any) => ({ value: item.id, label: item.name }))
        setCampi(formatResponse)
      }
    } catch (error) {
      toast('Algo de errado aconteceu buscando suas unidades 😔 Por favor, tente novamente.')
    }
  }

  const postAndGetCampus = async (newCampusObj: IAddCampusObj) => {
    try {
      const formatPostObj = {
        ...newCampusObj,
        zipcode: newCampusObj?.zipcode.replace(/\D/g, ''),
        number: newCampusObj?.number || undefined,
        complement: newCampusObj?.complement || undefined,
      }

      const postResponse = await postNewCampus(formatPostObj)

      if (postResponse.status > 199 && postResponse.status < 300) {
        const {
          data: {
            content: { id, name },
          },
        } = postResponse

        setCampi((previousArr: any) => [...previousArr, { value: id, label: name }])
        formik.setFieldValue('campusId', id)

        closeAddCampusModal()
        toast('Unidade cadastrada com sucesso! 😀')
      }
    } catch (error: any) {
      toast(
        error.response?.data?.error?.message ||
          error.response?.data?.errors?.[0]?.message ||
          'Algo de errado aconteceu cadastrando a sua unidade 😔 Por favor, tente novamente.',
      )
    }
  }

  const openAddCampusModal = () => {
    if (addCampusModalRef.current) {
      addCampusModalRef.current.openModalFn()
    }
  }

  const closeAddCampusModal = () => {
    if (addCampusModalRef.current) {
      addCampusModalRef.current.closeModalFn()
    }
  }

  React.useEffect(() => {
    getCourseData(courseId)
    getCampusData()
  }, [])

  React.useEffect(() => {
    if (courseClassCopy) {
      formik.validateForm()
    }
  }, [])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return {
    formik,
    hasErrors,
    handleChangeClassPeriod,
    handleChangeClassType,
    handleChangeRelease,
    deadlineSelectOptions,
    isMobile,
    campi,
    handleMaskInputChange,
    goToPage,
    setCourseClassCopy,
    addCampusModalRef,
    openAddCampusModal,
    closeAddCampusModal,
    postAndGetCampus,
  }
}

export { useAddClassroomHook }

import { formatDatePickerStr } from '~/helpers/tables/formatDatePickerStr'

export const formatDatesObj = (filtersObj) => {
  // create date
  if (filtersObj && filtersObj.createDate && !filtersObj.startDate) {
    filtersObj.startDate = formatDatePickerStr(filtersObj.createDate.startDate)
  }

  if (filtersObj && filtersObj.createDate && !filtersObj.endDate) {
    filtersObj.endDate = formatDatePickerStr(filtersObj.createDate.endDate)
  }

  if (filtersObj && filtersObj.createDate && filtersObj.startDate && filtersObj.endDate) delete filtersObj.createDate

  // made effective date
  if (filtersObj && filtersObj.madeEffectiveDate && !filtersObj.startEffectiveDate) {
    filtersObj.startEffectiveDate = formatDatePickerStr(filtersObj.madeEffectiveDate.startDate)
  }

  if (filtersObj && filtersObj.madeEffectiveDate && !filtersObj.endEffectiveDate) {
    filtersObj.endEffectiveDate = formatDatePickerStr(filtersObj.madeEffectiveDate.endDate)
  }

  if (filtersObj && filtersObj.madeEffectiveDate && filtersObj.madeEffectiveDate.isBlankDate) {
    filtersObj.startEffectiveDate = 'CLEAR'
    filtersObj.endEffectiveDate = 'CLEAR'
  }

  if (filtersObj && filtersObj.madeEffectiveDate && filtersObj.startEffectiveDate && filtersObj.endEffectiveDate)
    delete filtersObj.madeEffectiveDate

  // datePrevToted expectedPaymentDate
  if (filtersObj && filtersObj.datePrevToted && !filtersObj.startExpectedPaymentDate) {
    filtersObj.startExpectedPaymentDate = formatDatePickerStr(filtersObj.datePrevToted.startDate)
  }

  if (filtersObj && filtersObj.datePrevToted && !filtersObj.endExpectedPaymentDate) {
    filtersObj.endExpectedPaymentDate = formatDatePickerStr(filtersObj.datePrevToted.endDate)
  }

  if (filtersObj && filtersObj.datePrevToted && filtersObj.datePrevToted.isBlankDate) {
    filtersObj.startExpectedPaymentDate = 'CLEAR'
    filtersObj.endExpectedPaymentDate = 'CLEAR'
  }

  if (filtersObj && filtersObj.datePrevToted && filtersObj.startExpectedPaymentDate && filtersObj.endExpectedPaymentDate)
    delete filtersObj.datePrevToted

  // expectedCompensationDate
  if (filtersObj && filtersObj.expectedCompensationDate && !filtersObj.startExpectedCompensationDate) {
    filtersObj.startExpectedCompensationDate = formatDatePickerStr(filtersObj.expectedCompensationDate.startDate)
  }

  if (filtersObj && filtersObj.expectedCompensationDate && !filtersObj.endExpectedCompensationDate) {
    filtersObj.endExpectedCompensationDate = formatDatePickerStr(filtersObj.expectedCompensationDate.endDate)
  }

  if (filtersObj && filtersObj.expectedCompensationDate && filtersObj.expectedCompensationDate.isBlankDate) {
    filtersObj.startExpectedCompensationDate = 'CLEAR'
    filtersObj.endExpectedCompensationDate = 'CLEAR'
  }

  if (
    filtersObj &&
    filtersObj.expectedCompensationDate &&
    filtersObj.startExpectedCompensationDate &&
    filtersObj.endExpectedCompensationDate
  )
    delete filtersObj.expectedCompensationDate

  // CompensationDate
  if (filtersObj && filtersObj.compensationDate && !filtersObj.startCompensationDate) {
    filtersObj.startCompensationDate = formatDatePickerStr(filtersObj.compensationDate.startDate)
  }

  if (filtersObj && filtersObj.compensationDate && !filtersObj.endCompensationDate) {
    filtersObj.endCompensationDate = formatDatePickerStr(filtersObj.compensationDate.endDate)
  }

  if (filtersObj && filtersObj.compensationDate && filtersObj.compensationDate.isBlankDate) {
    filtersObj.startCompensationDate = 'CLEAR'
    filtersObj.endCompensationDate = 'CLEAR'
  }

  if (filtersObj && filtersObj.compensationDate && filtersObj.startCompensationDate && filtersObj.endCompensationDate)
    delete filtersObj.compensationDate

  // tedDate
  if (filtersObj && filtersObj.tedDate && !filtersObj.startTedDate) {
    filtersObj.startTedDate = formatDatePickerStr(filtersObj.tedDate.startDate)
  }

  if (filtersObj && filtersObj.tedDate && !filtersObj.endTedDate) {
    filtersObj.endTedDate = formatDatePickerStr(filtersObj.tedDate.endDate)
  }

  if (filtersObj && filtersObj.tedDate && filtersObj.tedDate.isBlankDate) {
    filtersObj.startTedDate = 'CLEAR'
    filtersObj.endTedDate = 'CLEAR'
  }

  if (filtersObj && filtersObj.tedDate && filtersObj.startTedDate && filtersObj.endTedDate) delete filtersObj.tedDate

  // released  releasedDate
  if (filtersObj && filtersObj.released && !filtersObj.startReleasedDate) {
    filtersObj.startReleasedDate = formatDatePickerStr(filtersObj.released.startDate)
  }

  if (filtersObj && filtersObj.released && !filtersObj.endReleasedDate) {
    filtersObj.endReleasedDate = formatDatePickerStr(filtersObj.released.endDate)
  }

  if (filtersObj && filtersObj.released && filtersObj.released.isBlankDate) {
    filtersObj.startReleasedDate = 'CLEAR'
    filtersObj.endReleasedDate = 'CLEAR'
  }

  if (filtersObj && filtersObj.released && filtersObj.startReleasedDate && filtersObj.endReleasedDate)
    delete filtersObj.released

  // nextPayment  nextPaymentDate
  if (filtersObj && filtersObj.nextPayment && !filtersObj.startNextPaymentDate) {
    filtersObj.startNextPaymentDate = formatDatePickerStr(filtersObj.nextPayment.startDate)
  }

  if (filtersObj && filtersObj.nextPayment && !filtersObj.endNextPaymentDate) {
    filtersObj.endNextPaymentDate = formatDatePickerStr(filtersObj.nextPayment.endDate)
  }

  if (filtersObj && filtersObj.nextPayment && filtersObj.nextPayment.isBlankDate) {
    filtersObj.startNextPaymentDate = 'CLEAR'
    filtersObj.endNextPaymentDate = 'CLEAR'
  }

  if (filtersObj && filtersObj.nextPayment && filtersObj.startNextPaymentDate && filtersObj.endReleasedDate)
    delete filtersObj.nextPayment

  // aggrement date
  if (filtersObj && filtersObj.agreementDate && !filtersObj.startAgreementDate) {
    filtersObj.startAgreementDate = formatDatePickerStr(filtersObj.agreementDate.startDate)
  }

  if (filtersObj && filtersObj.agreementDate && !filtersObj.endAgreementDate) {
    filtersObj.endAgreementDate = formatDatePickerStr(filtersObj.agreementDate.endDate)
  }

  if (filtersObj && filtersObj.agreementDate && filtersObj.agreementDate.isBlankDate) {
    filtersObj.startAgreementDate = 'CLEAR'
    filtersObj.endAgreementDate = 'CLEAR'
  }

  if (filtersObj && filtersObj.agreementDate && filtersObj.startAgreementDate && filtersObj.endAgreementDate)
    delete filtersObj.agreementDate

  // expected compensation dates
  if (filtersObj && filtersObj.expectedTransferDate && !filtersObj.startExpectedTransferDate) {
    filtersObj.startExpectedTransferDate = formatDatePickerStr(filtersObj.expectedTransferDate.startDate)
  }

  if (filtersObj && filtersObj.expectedTransferDate && !filtersObj.endExpectedTransferDate) {
    filtersObj.endExpectedTransferDate = formatDatePickerStr(filtersObj.expectedTransferDate.endDate)
  }

  if (filtersObj && filtersObj.expectedTransferDate && filtersObj.expectedTransferDate.isBlankDate) {
    filtersObj.startExpectedTransferDate = 'CLEAR'
    filtersObj.endExpectedTransferDate = 'CLEAR'
  }

  if (
    filtersObj &&
    filtersObj.expectedTransferDate &&
    filtersObj.startExpectedTransferDate &&
    filtersObj.endExpectedTransferDate
  )
    delete filtersObj.expectedTransferDate

  return filtersObj
}

/**
 * @typedef {object} IOption
 * @property {string} name
 * @property {string} value
 * @property {boolean} blocked
 * @property {boolean} show
 */
/** @type {{ [x: string]: IOption[] }} */
export const filterTypes = {
  sales: [
    {
      name: 'origin',
      value: 'Origem',
      blocked: false,
    },
    {
      name: 'id',
      value: 'ID',
      blocked: true,
    },
    {
      name: 'status',
      value: 'Status',
      blocked: true,
    },
    {
      name: 'cpf',
      value: 'CPF',
      blocked: true,
    },
    {
      name: 'name',
      value: 'Nome',
      blocked: true,
    },
    {
      name: 'email',
      value: 'Email',
      blocked: false,
    },
    {
      name: 'phone',
      value: 'Telefone',
      blocked: false,
    },
    {
      name: 'campaignName',
      value: 'Nome da campanha',
      blocked: true,
      show: true,
    },
    {
      name: 'value',
      value: 'Valor da venda',
      blocked: false,
    },
    {
      name: 'installments',
      value: 'Parcelas',
      blocked: false,
    },
    {
      name: 'seller',
      value: 'Vendedor',
      blocked: false,
    },
    {
      name: 'products',
      value: 'Produtos',
      blocked: false,
    },
    {
      name: 'createDate',
      value: 'Data de criação',
      blocked: false,
    },
    {
      name: 'expirationDate',
      value: 'Data de expiração',
      blocked: false,
    },
    {
      name: 'lastUpdate',
      value: 'Ultima atualização',
      blocked: true,
    },
    {
      name: 'madeEffectiveDate',
      value: 'Data de efetivação',
      blocked: true,
    },
    {
      name: 'nextPayment',
      value: 'Data vencimento 1° boleto',
      blocked: false,
    },
  ],
  finantial: [
    {
      name: 'origin',
      value: 'Origem',
      blocked: false,
    },
    {
      name: 'productType',
      value: 'Tipo de produto',
      blocked: false,
    },
    {
      name: 'id',
      value: 'ID',
      blocked: true,
      show: true,
    },
    {
      name: 'status',
      value: 'Status',
      blocked: true,
      show: true,
    },
    {
      name: 'cpf',
      value: 'CPF',
      blocked: true,
      show: true,
    },
    {
      name: 'name',
      value: 'Nome',
      blocked: true,
      show: true,
    },
    {
      name: 'email',
      value: 'Email',
      blocked: false,
      show: false,
    },
    {
      name: 'phone',
      value: 'Telefone',
      blocked: false,
      show: false,
    },
    {
      name: 'products',
      value: 'Produtos',
      blocked: false,
      show: true,
    },
    {
      name: 'installments',
      value: 'Parcelas',
      blocked: false,
      show: true,
    },
    {
      name: 'creator',
      value: 'Vendedor',
      blocked: false,
      show: true,
    },
    {
      name: 'signedDate',
      value: 'Data de Assinatura',
      blocked: false,
      show: true,
    },
    {
      name: 'value',
      value: 'Valor da venda',
      blocked: true,
      show: true,
    },
    {
      name: 'commission',
      value: 'Comissão Provi',
      blocked: true,
      show: true,
    },
    {
      name: 'tedValue',
      value: 'Valor da Transferência',
      blocked: true,
      show: true,
    },
    {
      name: 'madeEffectiveDate',
      value: 'Data de efetivação',
      blocked: true,
    },
    {
      name: 'datePrevToted',
      value: 'Data prevista da Transferência',
      blocked: false,
      show: true,
    },
    {
      name: 'tedDate',
      value: 'Data da Transferência',
      blocked: true,
      show: true,
    },
    {
      name: 'released',
      value: 'Curso liberado',
      blocked: false,
      show: false,
    },
    {
      name: 'nextPayment',
      value: 'Próximo pagamento',
      blocked: true,
    },
    {
      name: 'address',
      value: 'Endereço',
      blocked: false,
    },
  ],
  receipts: [
    {
      name: 'id',
      value: 'ID',
      blocked: true,
      show: true,
    },
    {
      name: 'status',
      value: 'Status',
      blocked: true,
      show: true,
    },
    {
      name: 'hostingStatus',
      value: 'Acesso à hospedagem',
      blocked: false,
      show: true,
    },
    {
      name: 'cpf',
      value: 'CPF',
      blocked: true,
      show: true,
    },
    {
      name: 'productType',
      value: 'Método de pagamento',
      blocked: false,
      show: false,
    },
    {
      name: 'name',
      value: 'Nome',
      blocked: true,
      show: true,
    },
    {
      name: 'email',
      value: 'Email',
      blocked: false,
      show: false,
    },
    {
      name: 'phone',
      value: 'Telefone',
      blocked: false,
      show: false,
    },
    {
      name: 'products',
      value: 'Produtos',
      blocked: false,
      show: true,
    },
    {
      name: 'campaignName',
      value: 'Nome da campanha',
      blocked: false,
      show: true,
    },
    {
      name: 'madeEffectiveDate',
      value: 'Data de efetivação',
      blocked: true,
    },
    {
      name: 'installments',
      value: 'Parcelas',
      blocked: false,
      show: false,
    },
    {
      name: 'installments_paid',
      value: 'Parcelas pagas',
      blocked: false,
      show: false,
    },
    {
      name: 'installments_delayed',
      value: 'Parcelas atrasadas',
      blocked: false,
      show: false,
    },
    {
      name: 'delayed_days',
      value: 'Atraso em dias',
      blocked: false,
      show: false,
    },
    {
      name: 'released',
      value: 'Curso liberado',
      blocked: false,
      show: false,
    },
    {
      name: 'nextPayment',
      value: 'Próximo pagamento',
      blocked: true,
      show: true,
    },
    {
      name: 'installment_value',
      value: 'Valor da parcela',
      blocked: false,
    },
    {
      name: 'value',
      value: 'Valor da venda',
      blocked: true,
      show: true,
    },
    {
      name: 'commission_provi',
      value: 'Comissão Provi',
      blocked: true,
      show: true,
    },
    {
      name: 'commission_partner',
      value: 'Comissão',
      blocked: true,
      show: true,
    },
    {
      name: 'total_amount_receivable',
      value: 'Valor total a receber',
      blocked: true,
      show: true,
    },
    {
      name: 'total_amount_received',
      value: 'Valor total recebido',
      blocked: true,
      show: true,
    },

    {
      name: 'tedDate',
      value: 'Pagamento a vista provi',
      blocked: true,
      show: true,
    },
    {
      name: 'agreementDate',
      value: 'Data de acordo',
      blocked: true,
      show: true,
    },
  ],
  'financial-movement': [
    {
      name: 'id',
      value: 'ID',
      blocked: true,
      show: true,
    },
    {
      name: 'cpf',
      value: 'CPF',
      blocked: true,
      show: true,
    },
    {
      name: 'name',
      value: 'Nome',
      blocked: true,
      show: true,
    },
    {
      name: 'email',
      value: 'E-mail',
      blocked: false,
      show: true,
    },
    {
      name: 'phone',
      value: 'Telefone',
      blocked: false,
      show: true,
    },
    {
      name: 'products',
      value: 'Produtos',
      blocked: false,
      show: true,
    },
    {
      name: 'proviComission',
      value: 'Comissão da Provi',
      blocked: false,
      show: true,
    },
    {
      name: 'installmentType',
      value: 'Tipo da parcela',
      blocked: false,
      show: true,
    },
    {
      name: 'paidWith',
      value: 'Pago com',
      blocked: false,
      show: true,
    },
    {
      name: 'installmentValue',
      value: 'Valor da parcela',
      blocked: false,
      show: true,
    },
    {
      name: 'userPaidValue',
      value: 'Valor Pago Aluno',
      blocked: false,
      show: true,
    },
    {
      name: 'proviPercentComission',
      value: 'Comissão',
      blocked: false,
      show: true,
    },
    {
      name: 'receivedValue',
      value: 'Valor recebido',
      blocked: false,
      show: true,
    },
    {
      name: 'dueDate',
      value: 'Vencimento',
      blocked: false,
      show: true,
    },
    {
      name: 'userPaymentDate',
      value: 'Data pagamento aluno',
      blocked: false,
      show: true,
    },
    {
      name: 'expectedCompensationDate',
      value: 'Saldo disponível em',
      blocked: false,
      show: true,
    },
    {
      name: 'compensationDate',
      value: 'Saldo sacado em',
      blocked: true,
      show: true,
    },
  ],
}

/** @type {(refer: string, preferences: object) => any[]>} */
export const getInitialoptions = (refer, preferences) => {
  return filterTypes[refer].map(({ name, blocked }) => {
    return { item: name, show: preferences[name] === undefined ? true : preferences[name], isBlocked: blocked }
  })
}

import React, { useContext } from 'react'

import { isIE, isEdge, engineName } from 'react-device-detect'
import { Button, TextLink, theme, TextErrorInput } from '@provi/provi-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Input, InputMasks } from '~/components/Input'
import { ButtonBack } from '~/components/ButtonBack'
import { SubHeader } from '~/components/SubHeader'
import { TemplateContext } from '~/components/Template/context'
import { useCheckout } from './hooks'
import { formatValue, capitalize } from '~/utils'
import { Text } from '~/themes/styles'

import { Calculator } from '~/assets/svgs/calc'
import { CalcModalV2 } from '~/components/modals/calc-modal-v2'

import { InputIcon } from '~/assets/svgs/inputIcon'
import { SellerInfoModal } from '../seller-modal'

import { usePermissions } from '~/hooks'
import { PaymentMethodSection } from '../PaymentMethodSection'

import {
  Container,
  Card,
  //
  Product,
  ProductTitle,
  ProductText,
  CardValueTitle,
  CardValue,
  //
  Content,
  SectionOption,
  OptionTitle,
  WrapperInputs,
  WrapperIcon,
  //
  WrapperGetLink,
  WarningAnimation,
  Warning,
  WrapperCardLink,
  WarningBold,
  Br,
  WrapperButton,
  WrapperTextLink,
  WrapperGetLinkTextLink,
  ErrorMessage,
  AnchorElementToSmallScreens,
  //
  BackgroundBlock,
  PreviewTextWrapper,
  WrapperWarning,
  WrapperOutline,
  ButtonOutline,
} from '../styles'

export const CreateCampaignV2 = () => {
  const {
    setIsLoading,
    setCurrent,
    handleLogoutModal,
    isMobile,
    setSelectedOptionsCampaign,
    setValueCampaign,
    goToPage,
    activePage,
    setActivePage,
  } = useContext(TemplateContext)
  const [
    handleValueChange,
    handlePercentChange,
    link,
    showToastCopy,
    products,
    productsValue,
    obj,
    handleFocus,
    handleSubmitCheckout,
    sellerId,
    handleSellerId,
    linkUrl,
    errorPercent,
    errorMaxValue,
    campaignName,
    handleCampaignName,
    slugPreview,
    handleModal,
    showSellerInfoModal,
    campaignNameError,
    handleCalcModal,
    handleCalcClick,
    isShowCalcModal,
    calcConfig,
    paymentMethods,
    setPaymentMethods,
    defaultPaymentMethods,
    handleDates,
    dates,
    endDateErrorMessage,
    startDateErrorMessage,
  ] = useCheckout(
    setIsLoading,
    setCurrent,
    isIE,
    isEdge,
    engineName,
    setSelectedOptionsCampaign,
    setValueCampaign,
    activePage,
    setActivePage,
  )

  const { canCreateNewCrids, partnerID } = usePermissions()

  return (
    <>
      {isShowCalcModal && <CalcModalV2 handleModal={handleCalcModal} show={isShowCalcModal} calcConfig={calcConfig} />}
      {!isMobile ? (
        <ButtonBack onClick={() => goToPage('campanha/selecionar-produtos')} />
      ) : (
        <SubHeader
          isMargin="0 0 30px 0"
          isShowButtonHelpModal={false}
          functionBackButton={() => goToPage('campanha/selecionar-produtos')}
        />
      )}
      {showSellerInfoModal && <SellerInfoModal handleModal={handleModal} />}
      <Container>
        {!isMobile && <BackgroundBlock />}
        <Content>
          <WrapperWarning>
            <Text align="center" mBottom="40px" color={theme.colors.blue600} bold>
              Você está criando uma campanha!
            </Text>
          </WrapperWarning>
          <Card>
            {products &&
              products?.map((product, index) => (
                <Product key={index}>
                  <ProductTitle>Produto {index + 1}:</ProductTitle>
                  <ProductText>
                    Curso: {capitalize(product.name)} - Turma:{' '}
                    {product.courseClass && product.courseClass.name ? capitalize(product.courseClass.name) : ''},{' '}
                    {product.courseClass && product.courseClass.priceInCents
                      ? formatValue(product.courseClass.priceInCents)
                      : ''}
                  </ProductText>
                </Product>
              ))}
            <CardValueTitle>Valor total:</CardValueTitle>
            <CardValue>{formatValue(productsValue)}</CardValue>
          </Card>
          <SectionOption>
            <OptionTitle>Digite o nome da sua campanha*</OptionTitle>
            <WrapperInputs wrap>
              <Input
                id="campaignName"
                width="100%"
                placeholder="Ex: Black Friday 50 OFF"
                value={campaignName}
                onChange={handleCampaignName}
              />
              {campaignNameError && <TextErrorInput text="Por favor, não utilize caracteres especiais ou acentos" />}
              {slugPreview && (
                <>
                  <PreviewTextWrapper>
                    <Text size="14px" display="inline-block" bold>
                      Preview da url*
                    </Text>
                    :
                    <Text size="14px" display="inline-block">
                      {slugPreview}
                    </Text>
                  </PreviewTextWrapper>
                  <Text size="12px" mTop="5px" color={theme.colors.blue900}>
                    *O preview da URL está sujeito a alterações
                  </Text>
                </>
              )}
            </WrapperInputs>
          </SectionOption>

          <SectionOption>
            <OptionTitle>Digite o valor final da venda ou o valor do desconto em %*</OptionTitle>
            <WrapperInputs>
              <Input
                placeholder="Ex: 1.200,00"
                onChange={handleValueChange}
                value={obj.value}
                width={isMobile ? '100%' : '300px'}
              />
              <WrapperIcon>
                <InputIcon />
              </WrapperIcon>
              <InputMasks
                mask="99%"
                placeholder="Ex: 22%"
                onChange={handlePercentChange}
                value={obj.percent}
                onClick={handleFocus}
                width={isMobile ? '100%' : '300px'}
              />
            </WrapperInputs>
            {errorPercent && <ErrorMessage>A porcentagem de desconto é no máximo 100%</ErrorMessage>}
            {errorMaxValue && (
              <ErrorMessage>O valor com desconto tem que ser menor que o valor total dos produtos</ErrorMessage>
            )}
          </SectionOption>

          <SectionOption>
            <OptionTitle>Defina o período de validade da campanha (opcional)</OptionTitle>
            <Text align="left" color={'#000B3C'}>
              Após a data de fim, o link da campanha ficará inválido
            </Text>
            <WrapperInputs>
              <SectionOption>
                <OptionTitle>Data de início</OptionTitle>
                <InputMasks
                  mask="99/99/9999"
                  placeholder="DD/MM/YYYY"
                  onChange={(element) => handleDates('startDate', element.target.value)}
                  value={dates.startDate || null}
                  width={isMobile ? '100%' : '300px'}
                />
              </SectionOption>
              <WrapperIcon />
              <SectionOption>
                <OptionTitle>Hora</OptionTitle>
                <InputMasks
                  mask="99:99"
                  placeholder="00:00"
                  onChange={(element) => handleDates('startHour', element.target.value)}
                  value={dates.startHour || null}
                  width={isMobile ? '100%' : '300px'}
                />
              </SectionOption>
            </WrapperInputs>
            {startDateErrorMessage && <TextErrorInput text={startDateErrorMessage} />}

            <WrapperInputs>
              <SectionOption>
                <OptionTitle>Data de fim</OptionTitle>
                <InputMasks
                  mask="99/99/9999"
                  placeholder="DD/MM/YYYY"
                  onChange={(element) => handleDates('endDate', element.target.value)}
                  value={dates.endDate || null}
                  width={isMobile ? '100%' : '300px'}
                />
              </SectionOption>
              <WrapperIcon />
              <SectionOption>
                <OptionTitle>Hora</OptionTitle>
                <InputMasks
                  mask="99:99"
                  placeholder="00:00"
                  onChange={(element) => handleDates('endHour', element.target.value)}
                  value={dates.endHour || null}
                  width={isMobile ? '100%' : '300px'}
                />
              </SectionOption>
            </WrapperInputs>
            {endDateErrorMessage && <TextErrorInput text={endDateErrorMessage} />}
          </SectionOption>
          {defaultPaymentMethods && !!defaultPaymentMethods.length && (
            <PaymentMethodSection paymentMethods={paymentMethods} setPaymentMethods={setPaymentMethods} />
          )}

          <SectionOption>
            <WrapperOutline>
              <ButtonOutline isDisable={false} onClick={handleCalcClick}>
                <Calculator isDisable={false} />
                Ver parcelas
              </ButtonOutline>
            </WrapperOutline>
          </SectionOption>
          <SectionOption>
            <OptionTitle>Vendedor</OptionTitle>
            <WrapperInputs>
              <Input
                id="sellerid"
                width="100%"
                placeholder="Email do vendedor"
                value={sellerId}
                onChange={handleSellerId}
                disabled
                onClick={() => handleModal(true, 'seller')}
              />
            </WrapperInputs>
          </SectionOption>
        </Content>

        <WrapperGetLink>
          <Button
            marginHorizontal
            disabled={!campaignName || errorPercent || errorMaxValue || startDateErrorMessage || endDateErrorMessage}
            text="Gerar link"
            onClick={handleSubmitCheckout}
          />
          <WrapperGetLinkTextLink>
            <TextLink text="Logout" color={theme.colors.pink900} onClick={() => handleLogoutModal(true)} />
          </WrapperGetLinkTextLink>
        </WrapperGetLink>
      </Container>

      {link && (
        <WarningAnimation>
          <Warning>
            <Container>
              <WrapperCardLink>
                <WarningBold> Olá! Chegou a hora de conhecer a Provi.</WarningBold>
                <AnchorElementToSmallScreens>
                  <Br />A Provi é uma fintech de crédito educacional com quem temos parceria para que você possa fazer o curso
                  com parcelas que cabem no seu bolso!
                  <Br />
                </AnchorElementToSmallScreens>
                {(canCreateNewCrids || partnerID === 729) && (
                  <>
                    <Br /> Clique no link abaixo para finalizar a sua compra via parcelamento no boleto.
                    <Br /> <Br />
                    <WarningBold> {linkUrl}</WarningBold>
                  </>
                )}
              </WrapperCardLink>
              <WrapperButton>
                {(canCreateNewCrids || partnerID === 729) && (
                  <CopyToClipboard text={linkUrl} onCopy={showToastCopy}>
                    <Button text="Copiar link" />
                  </CopyToClipboard>
                )}
              </WrapperButton>
              <WrapperTextLink>
                <TextLink
                  text="Listar campanhas"
                  color={theme.colors.pink900}
                  onClick={() => goToPage('campanha/listar-campanhas')}
                />
              </WrapperTextLink>
            </Container>
          </Warning>
        </WarningAnimation>
      )}
    </>
  )
}

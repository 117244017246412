import * as moment from 'moment'
import masks from '~/themes/masks'
import { handleGoToDetailsPage } from './handleGoToDetailsPage'
import { StyledMobileSpan } from './styles'

/**
 * @typedef CancellationActionButtonType
 * @type {object}
 * @property {string} btnText
 * @property {boolean} disableBool
 */

export const fallbackString = '---'

export const formatChargebackStatus = (string) => {
  const chargebackStatusesFormatter = {
    'Pendente de Contestação': '#FBBABC',
    'Contestação Devolvida': '#FFD482',
    'Documentos Enviados': '#ADDAFF',
    'Pendente de Análise': '#ADDAFF',
    'Em Recuperação': '#ADDAFF',
    'Encerrado Estornado': '#ECEAEA',
    'Encerrado Recuperado': '#CFEFB6',
    'Encerrado Coberto': '#ECEAEA',
    'Encerrado Expirado': '#ECEAEA',
  }
  return chargebackStatusesFormatter[string] || 'FFFFFF'
}

export const formatCancellationStatus = (string) => {
  const cancellationStatusesFormatter = {
    approved: { status: 'Pedido aprovado', statusColor: '#2647D7', textColor: '#FFFFFF' },
    analysis: {
      status: 'Pedido em análise',
      statusColor: '#F55D0C',
      textColor: '#FFFFFF',
    },
    waiting_cancellation: { status: 'Aguardando cancelamento', statusColor: '#DE0C4B', textColor: '#FFFFFF' },
    error: { status: 'Erro', statusColor: '#FF5776', textColor: '#FFFFFF' },
    done: { status: 'Cancelado', statusColor: '#B30142', textColor: '#FFFFFF' },
    waitingBankInfo: { status: 'Aguardando informações bancárias', statusColor: '#FABC01', textColor: '#414141' },
    waitingAccountValidation: { status: 'Validando dados bancários', statusColor: '#FDEF74', textColor: '#414141' },
    reverted: { status: 'Revertido', statusColor: '#A5ECAB', textColor: '#414141' },
    canceled: { status: 'Excluído', statusColor: '#CFCFCF', textColor: '#414141' },
    refunded: { status: 'Reembolsado', statusColor: '#7B27C5', textColor: '#FFFFFF' },
    waitingRefund: { status: 'Aguardando reembolso', statusColor: '#DDBFF2', textColor: '#414141' },

    fallback: { status: fallbackString, statusColor: '', textColor: '' },
  }
  return cancellationStatusesFormatter[string] || cancellationStatusesFormatter.fallback
}

/**
 * @description Evaluates the cancellation action button text and disable status
 * @param {string} valueString
 * @returns {CancellationActionButtonType}
 */
export const formatCancellationActionBtn = (valueString) => {
  const cancellationActionFormatter = {
    not_reverted: { btnText: 'Não revertido', disableBool: true },
    reverted: { btnText: 'Revertido', disableBool: true },
    expired: { btnText: 'Expirado', disableBool: true },
    waiting_response: { btnText: 'Responder', disableBool: false },
    fallback: { btnText: fallbackString, disableBool: true },
  }
  return cancellationActionFormatter[valueString] || cancellationActionFormatter.fallback
}

export const formatCancellationWarranty = (valueString) => {
  const cancellationWarrantyFormatter = {
    outOfDeadline: 'Fora do prazo',
    withinDeadline: 'Dentro do prazo',
    fallback: fallbackString,
  }
  return cancellationWarrantyFormatter[valueString] || cancellationWarrantyFormatter.fallback
}

export const formatCancellationTexts = (valueString) => {
  const cancellationTextsFormatter = {
    reversed: 'Revertido',
    expired: 'Expirado',
    waiting_response: 'Responder',
    outOfDeadline: 'Fora do prazo',
    withinDeadline: 'Dentro do prazo',
    approved: 'Pedido aprovado',
    analysis: 'Pedido em análise',
    waiting_cancellation: 'Aguardando cancelamento',
    error: 'Erro',
    done: 'Cancelado',
    waitingBankInfo: 'Aguardando informações bancárias',
    waitingAccountValidation: 'Validando dados bancários',
    reverted: 'Revertido',
    canceled: 'Excluído',
    refunded: 'Reembolsado',
    waitingRefund: 'Aguardando reembolso',
    fallback: fallbackString,
  }
  return cancellationTextsFormatter[valueString] || cancellationTextsFormatter.fallback
}

export const handleMobileToggle = (rowParams, defaultColumnParams) => {
  const { toggleRowExpanded } = rowParams
  const { isMobileProps } = defaultColumnParams

  return isMobileProps
    ? toggleRowExpanded()
    : () => {
        return
      }
}

export const parseFiltersFromUrlToObj = (params) => {
  return Object.keys(params).reduce((accumulatedObject, currentElement) => {
    return {
      ...accumulatedObject,
      [currentElement]: params[currentElement].trim().split(','),
    }
  }, {})
}

export const parseFiltersFromUrlToArrOfObj = (params) => {
  return Object.keys(params).reduce((accumulatedArr, currentElement) => {
    return [...accumulatedArr, { id: currentElement, value: params[currentElement].trim().split(',') }]
  }, [])
}

export const parseFiltersFromStateArr = (filterArrOfObjs, activeUser) => {
  return filterArrOfObjs.reduce((accumulatedObject, currentObject) => {
    return {
      ...accumulatedObject,
      u: activeUser,
      [currentObject.id]: currentObject.value.map((eachObject) => eachObject.value || eachObject),
    }
  }, {})
}

export const formatAvailableFilters = (availableFilters) => {
  return Object.keys(availableFilters).reduce((accumulatedObject, currentKey) => {
    return {
      ...accumulatedObject,
      [currentKey]: availableFilters[currentKey].map((eachElement) => ({
        value: eachElement,
        label: formatCancellationTexts(eachElement),
      })),
    }
  }, {})
}

export { masks, moment, handleGoToDetailsPage, StyledMobileSpan }

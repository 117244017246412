import { NotFound } from '~/screens/not-found'

import { Login } from '~/screens/login'
import { ResetPassword } from '~/screens/reset-password'
import { ProviLogin } from '~/screens/provi-login'

import { FinantialControlV2 } from '~/screens/financial-control-v2'
import { FinantialMovement } from '~/screens/finantial-movement'

//partners transactions
import { PartnerTransactions } from '~/screens/partner-transactions'

// checkout pages
import { Product } from '~/screens/checkout/product'
import { CheckoutPlan } from '~/screens/checkout/checkout-plan'
// import { CheckoutPlanBeta } from '~/screens/checkout/checkout-plan/beta'
import { CreateCourse } from '~/screens/checkout/create-course'
import { CheckoutList } from '~/screens/checkout/checkout-list'

// campaings
import { ListCampaings } from '~/screens/campaings/list-campaings'
import { CampaignProducts } from '~/screens/campaings/campaign-products'
import { CreateCampaign } from '~/screens/campaings/create-campaings'
// import { CreateCampaignBeta } from '~/screens/campaings/create-campaings/beta'
import { CreateCourseCampaign } from '~/screens/campaings/create-course'

// user-control
import { ListUsers } from '~/screens/user-control/list-users'
import { AddUsers } from '~/screens/user-control/add-users'
import { EditUsers } from '~/screens/user-control/edit-users'

// NFS Control
import { NFS } from '~/screens/nfs'

// Edit Basic Info
import { EditBasicInfo } from '~/screens/edit-basic-info'

// monitoring-dashboard
import { SalesFunnel } from '~/screens/dashboard/sales-funnel'
import { GeneralFlux } from '~/screens/dashboard/general'
// import { PendingSolicitations } from '~/screens/dashboard/pending-solicitations'
// import { TopSellingProducts } from '~/screens/dashboard/top-selling-products'

import { FinancialFlowDashboard } from '~/screens/dashboard/FinancialFlux'
import { NonPaymentsDashboard } from '~/screens/dashboard/NonPayments'

// Homepage
import { Home } from '~/screens/home'

// CourseClasses pages
import { List as CourseClassList } from '~/screens/course-classes/list'
import { Form as CourseClassForm } from '~/screens/course-classes/form'

// Profile
import { Profile } from '~/screens/profile'

// Requests
import { Chargebacks } from '~/screens/requests/chargebacks'
import { Cancellations } from '~/screens/requests/cancellations'

// Forgot Password
import { ForgotPassword } from '~/screens/forgot-password'
import { RedefinePassword } from '~/screens/redefine-password'

import { accessLevels } from '~/constants'

import { Details } from '~/screens/details'
import { Integrations } from '~/screens/integrations'
import { Courses } from '~/screens/courses'
import { Developer } from '~/screens/developer'

export const routes = [
  {
    path: '/404',
    component: NotFound,
    private: false,
    isFirstScreen: true,
    isHeader: false,
    isTemplate: false,
  },
  {
    path: '/',
    exact: true,
    private: false,
    templateIntro: true,
    component: Login,
  },
  {
    path: '/login',
    private: false,
    templateIntro: true,
    component: Login,
  },
  {
    path: '/redefinir',
    private: false,
    templateIntro: true,
    component: ResetPassword,
  },
  {
    path: '/home',
    private: true,
    permissions: [
      accessLevels.fiscal,
      accessLevels.junior,
      accessLevels.pleno,
      accessLevels.senior,
      accessLevels.admin,
      accessLevels.superadmin,
    ],
    component: Home,
  },
  {
    path: '/perfil/:name',
    private: true,
    permissions: [accessLevels.admin, accessLevels.superadmin],
    component: Profile,
  },
  {
    path: '/provi-login',
    private: false,
    component: ProviLogin,
  },
  // checkout
  {
    path: '/checkout/selecionar-produtos',
    private: true,
    isHeader: true,
    component: Product,
    permissions: [accessLevels.junior, accessLevels.pleno, accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
    activeOnly: true,
  },
  {
    path: '/checkout/criar-curso',
    private: true,
    isHeader: true,
    component: CreateCourse,
    permissions: [accessLevels.pleno, accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
    activeOnly: true,
  },
  {
    path: '/checkout/condicao-produtos',
    private: true,
    isHeader: true,
    component: CheckoutPlan,
    permissions: [accessLevels.junior, accessLevels.pleno, accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
    activeOnly: true,
  },
  // {
  //   path: '/checkout/condicao-produtos-beta',
  //   private: true,
  //   isHeader: true,
  //   component: CheckoutPlanBeta,
  //   permissions: [accessLevels.junior, accessLevels.pleno, accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
  //   activeOnly: true,
  // },
  {
    path: '/checkout/listar-checkouts',
    private: true,
    isHeader: false,
    component: CheckoutList,
    permissions: [accessLevels.junior, accessLevels.pleno, accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
    activeOnly: true,
  },
  {
    path: '/campanha/listar-campanhas',
    private: true,
    isHeader: true,
    component: ListCampaings,
    permissions: [accessLevels.junior, accessLevels.pleno, accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
    activeOnly: true,
  },
  {
    path: '/campanha/selecionar-produtos',
    private: true,
    isHeader: true,
    component: CampaignProducts,
    permissions: [accessLevels.pleno, accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
    activeOnly: true,
  },
  {
    path: '/campanha/criar-campanha',
    private: true,
    isHeader: true,
    component: CreateCampaign,
    permissions: [accessLevels.pleno, accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
    activeOnly: true,
  },
  // {
  //   path: '/campanha/criar-campanha-beta',
  //   private: true,
  //   isHeader: true,
  //   component: CreateCampaignBeta,
  //   permissions: [accessLevels.pleno, accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
  //   activeOnly: true,
  // },
  {
    path: '/campanha/criar-produto',
    private: true,
    isHeader: true,
    component: CreateCourseCampaign,
    permissions: [accessLevels.pleno, accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
    activeOnly: true,
  },
  {
    path: '/checkout/controle-recebimentos',
    private: true,
    isHeader: false,
    component: FinantialControlV2,
    permissions: [accessLevels.admin, accessLevels.superadmin],
  },
  {
    path: '/transferencias',
    private: true,
    isHeader: false,
    component: PartnerTransactions,
    permissions: [accessLevels.superadmin],
  },
  // user-control
  {
    path: '/usuario/listar-usuarios',
    private: true,
    isHeader: false,
    component: ListUsers,
    permissions: [accessLevels.admin, accessLevels.superadmin],
  },
  {
    path: '/usuario/criar-usuario',
    private: true,
    isHeader: false,
    component: AddUsers,
    permissions: [accessLevels.admin, accessLevels.superadmin],
  },
  {
    path: '/usuario/editar-usuario',
    private: true,
    isHeader: false,
    component: EditUsers,
    permissions: [accessLevels.admin, accessLevels.superadmin],
  },
  // {
  //   path: '/dashboard-acompanhamento',
  //   private: true,
  //   isHeader: true,
  //   component: MonitoringDash,
  //   permissions: [accessLevels.senior, accessLevels.admin, accessLevels.superadmin, accessLevels.fiscal],
  // },
  {
    path: '/dashboard/geral',
    private: true,
    isHeader: false,
    component: GeneralFlux,
    permissions: [accessLevels.senior, accessLevels.admin, accessLevels.superadmin, accessLevels.fiscal],
  },
  // {
  //   path: '/dashboard/solicitacoes-em-andamento',
  //   private: true,
  //   isHeader: true,
  //   component: PendingSolicitations,
  //   permissions: [accessLevels.senior, accessLevels.admin, accessLevels.superadmin, accessLevels.fiscal],
  // },
  // {
  //   path: '/dashboard/produtos-mais-vendidos',
  //   private: true,
  //   isHeader: true,
  //   component: TopSellingProducts,
  //   permissions: [accessLevels.senior, accessLevels.admin, accessLevels.superadmin, accessLevels.fiscal],
  // },
  {
    path: '/dashboard/funil-de-vendas',
    private: true,
    isHeader: false,
    component: SalesFunnel,
    permissions: [accessLevels.senior, accessLevels.admin, accessLevels.superadmin, accessLevels.fiscal],
  },
  {
    path: '/controle-nfs',
    private: true,
    component: NFS,
    permissions: [accessLevels.superadmin, accessLevels.fiscal],
  },
  {
    path: '/atualizar-informacoes-basicas',
    component: EditBasicInfo,
    private: true,
    templateIntro: true,
    permissions: [
      accessLevels.junior,
      accessLevels.pleno,
      accessLevels.senior,
      accessLevels.admin,
      accessLevels.superadmin,
      accessLevels.fiscal,
    ],
  },
  {
    path: '/turmas/listar-turmas',
    private: true,
    component: CourseClassList,
    permissions: [accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
  },
  {
    path: '/turmas/formulario-turma',
    private: true,
    component: CourseClassForm,
    permissions: [accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
  },
  {
    path: '/movimentacao-financeira',
    component: FinantialMovement,
    private: true,
    permissions: [accessLevels.admin, accessLevels.superadmin],
  },
  {
    path: '/solicitacoes/chargebacks',
    component: Chargebacks,
    private: true,
    permissions: [accessLevels.admin, accessLevels.superadmin],
  },
  {
    path: '/solicitacoes/cancelamentos',
    component: Cancellations,
    private: true,
    permissions: [accessLevels.admin, accessLevels.superadmin],
  },
  {
    path: '/redefinir-senha/:token',
    component: RedefinePassword,
    private: false,
    templateIntro: true,
  },
  {
    path: '/esqueci-senha',
    private: false,
    component: ForgotPassword,
    templateIntro: true,
  },
  {
    path: '/detalhes/:id',
    component: Details,
    private: true,
    permissions: [accessLevels.admin, accessLevels.superadmin],
  },
  {
    path: '/dashboard/fluxo-financeiro-beta',
    private: true,
    component: FinancialFlowDashboard,
    permissions: [accessLevels.senior, accessLevels.admin, accessLevels.superadmin, accessLevels.fiscal],
  },
  {
    path: '/dashboard/carteira',
    private: true,
    component: NonPaymentsDashboard,
    permissions: [accessLevels.senior, accessLevels.admin, accessLevels.superadmin, accessLevels.fiscal],
  },
  {
    path: '/integracoes',
    private: true,
    component: Integrations,
    permissions: [accessLevels.admin, accessLevels.superadmin],
  },
  {
    path: '/cursos',
    private: true,
    component: Courses,
    permissions: [accessLevels.junior, accessLevels.pleno, accessLevels.senior, accessLevels.admin, accessLevels.superadmin],
  },
  { path: '/desenvolvimento', private: true, component: Developer, permissions: [accessLevels.admin, accessLevels.superadmin] },
]

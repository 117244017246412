import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { BaseGrid, BaseFlexContainer } from '~/globalStyles'

export const CancellationsCardGrid = styled(BaseGrid)`
  padding: 1.75rem;
  max-width: 900px;
  background-color: #ffffff;
  gap: 2rem;
  border: 1px solid ${theme.colors.pink900};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  ${theme.breakpoints.down('md')} {
    padding: 1rem;
  }
`
export const SpaceBetweenContainer = styled(BaseFlexContainer)`
  gap: 1rem;
  justify-content: space-between;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }

  ${({ isCentered }) =>
    isCentered &&
    css`
      > * {
        &:last-child {
          place-self: center;

          button {
      height: 48px;
    }

    .MuiButtonBase-root {
      width: 174px;
    }

    .MuiButton-label {
      font-size: 16px;
      line-height: 24px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
    }
  }
        }
      }
    `}
`

export const InformationGrid = styled(BaseGrid)`
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`

export const FlexColumn = styled(BaseFlexContainer)`
  flex-direction: column;
`
export const ButtonsFlex = styled(BaseFlexContainer)`
  margin-top: 1rem;
  gap: 1rem;
`

export const ActionsGrid = styled(BaseGrid)`
  gap: 1rem;
`

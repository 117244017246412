/**
 * @description validate calculator values
 * @name validateCalculator
 * @param {Object} data
 * @param {Number} data.amountInCents
 * @param {Array} data.coursesIds
 */

export const validateCalculator = ({ amountInCents, coursesIds }) => {
  if (!amountInCents || !coursesIds.length) {
    throw new Error('Ocorreu um erro inesperado ao mostrar a calculadora, contate a Provi')
  }
}

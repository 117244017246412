import React from 'react'
import { Link } from 'react-router-dom'
import { ButtonV3, theme } from '@provi/provi-components'
import { LoadingSkeleton } from '~/components/v2/LoadingSkeleton'
import { toast } from 'react-toastify'
import { Collapse } from '@material-ui/core'
import { useToggle } from '~/hooks'
import { ArrowDown } from '~/assets/svgs/v2'
import { BaseFlexContainer, BaseMediumText } from '~/globalStyles'
import { SvgFlex, TokenCard, TwoColGrid } from '../styles'

export const ExplanationCollapse = () => {
  const [dropdownState, toggleDropdownState] = useToggle()

  return (
    <BaseFlexContainer flexDirection={'column'} onClick={toggleDropdownState} maxWidth={'600px'}>
      <SvgFlex state={dropdownState}>
        <BaseMediumText fontWeight={600} color={theme.colors.bluePrimary}>
          O que é isso?
        </BaseMediumText>
        <ArrowDown />
      </SvgFlex>
      <Collapse in={dropdownState}>
        <BaseMediumText>
          O Token é um identificador único necessário para acessar a API fornecida pela Provi. Para saber mais detalhes sobre a
          API e como utilizá-la, leia o nosso{' '}
          <Link to={{ pathname: 'https://parceiros.provi.com.br/o-que-%C3%A9-api' }} target="_blank" rel="noopener noreferrer">
            FAQ
          </Link>{' '}
          ou a{' '}
          <Link to={{ pathname: 'https://docs.provi.com.br' }} target="_blank" rel="noopener noreferrer">
            documentação oficial
          </Link>
          .
        </BaseMediumText>
      </Collapse>
    </BaseFlexContainer>
  )
}

export const TokenBox = ({ tokenProp, isLoadingToken, postAPItokenData }) => {
  if (isLoadingToken) return <LoadingSkeleton isLoading={isLoadingToken} containerHeight="150px" hasHeading hasParagraph />
  return (
    <>
      {tokenProp ? (
        <BaseFlexContainer flexDirection={'column'} gap={'0.5rem'} alignItems={'baseline'}>
          <BaseMediumText fontWeight={600}>Token</BaseMediumText>
          <TwoColGrid>
            <TokenCard title={tokenProp}>
              <BaseMediumText>{tokenProp}</BaseMediumText>
            </TokenCard>
            <ButtonV3
              text="Copiar token"
              onClick={() =>
                navigator.clipboard
                  .writeText(tokenProp)
                  .then(() => toast('Texto copiado!'))
                  .catch(() => toast('Houve um erro na hora de copiar o texto.'))
              }
              mobileWidth={'100%'}
              width={'140px'}
            />
          </TwoColGrid>
        </BaseFlexContainer>
      ) : (
        <ButtonV3 text="Gerar token" onClick={() => postAPItokenData(true)} mobileWidth={'100%'} />
      )}
    </>
  )
}
